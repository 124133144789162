import React, { Component } from 'react'
import { Link, navigate } from '@reach/router'
import { Layout, Avatar, Divider, Dropdown } from 'antd'
import { LOCAL_STORAGE } from 'constants.js'
import ketogainsTm from 'images/ketogains-tm.svg'
import HeaderNotification from './HeaderNotification'
import AdminIndicator from './AdminIndicator'
import GlobalContext from 'context/GlobalContext'

const { Header } = Layout

function CoachMenu(props) {
  const { isAdmin } = props
  const activeLinkProps = {
    style: { color: '#ff6b00' },
  }
  return (
    <ul>
      <li>
        <Link to="/personal" getProps={({ isCurrent }) => (isCurrent ? activeLinkProps : null)}>
          Personal Info
        </Link>
      </li>
      <li>
        <Link to="/welcome-letter">My Welcome Letter</Link>
      </li>
      <li>
        <a href={`${process.env.REACT_APP_AUTH_SERVER}/my-account/`} target="_blank" rel="noopener noreferrer">
          Change Password
        </a>
      </li>
      {isAdmin && (
        <li>
          <Link to="/admin" getProps={({ isCurrent }) => (isCurrent ? activeLinkProps : null)}>
            Admin
          </Link>
        </li>
      )}
      <li>
        <Link to="/logout" getProps={({ isCurrent }) => (isCurrent ? activeLinkProps : null)}>
          Logout
        </Link>
      </li>
    </ul>
  )
}

class KetogainsHeader extends Component {
  render() {
    const asCoach = localStorage.getItem(LOCAL_STORAGE.AS_COACH) === 'true' ? true : false
    const adminNickname =
      !!localStorage.getItem(LOCAL_STORAGE.ADMIN_NICKNAME) && localStorage.getItem(LOCAL_STORAGE.ADMIN_NICKNAME) !== ''
        ? localStorage.getItem(LOCAL_STORAGE.ADMIN_NICKNAME)
        : null

    return (
      <Header>
        {asCoach === false && adminNickname ? (
          <AdminIndicator />
        ) : (
          <>
            <img
              src={ketogainsTm}
              alt="Ketogains Coach"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/' + localStorage.getItem('COACH_ID'))}
            />
            <Divider type="vertical" />
            <div className="right">
              <GlobalContext.Consumer>
                {({ user, bootcampActive }) => (
                  <>
                    <span className="username">{`${user.nickname}`}</span>
                    <Dropdown
                      {...{
                        overlay: <CoachMenu />,
                        trigger: ['click'],
                        overlayClassName: 'profile-menu-overlay',
                      }}
                    >
                      <Avatar className="profile-picture" icon="user" src={user.picture} />
                    </Dropdown>
                    <Divider type="vertical" />
                    <HeaderNotification coachId={user.coach_id} userId={user.id} programId={bootcampActive.id} />
                  </>
                )}
              </GlobalContext.Consumer>
            </div>
          </>
        )}
      </Header>
    )
  }
}

export default KetogainsHeader
