import { getCookie } from 'tiny-cookie'
import { COOKIE } from 'constants.js'
import { GetStudentContactId } from 'queries/user'
import apolloClient from 'apolloClient'
import gql from 'graphql-tag'

export const setStudentContactTag = (traineeId, programId, tags) => {
  return new Promise((resolve, reject) => {
    apolloClient
      .query({
        query: GetStudentContactId,
        variables: {
          traineeId: traineeId,
        },
      })
      .then(({ data }) => {
        if (!!data.user && data.user.length > 0) {
          let contactId = data.user[0].contact_id
          fetch(`${process.env.REACT_APP_API_URL}infusion-soft/contact-tag`, {
            method: 'POST',
            headers: {
              Authorization: getCookie(COOKIE.USER_TOKEN),
            },
            body: JSON.stringify({
              action: 'ContactTag',
              infusionSoftObject: {
                Id: contactId,
                ProgramId: programId,
                Tags: tags,
              },
            }),
          })
            .then((response) => {
              return response.json()
            })
            .then((data) => {
              if (data.hasError) {
                let message = !!data.data && !!data.data.object ? data.data.object.message : 'Unknown'
                reject('InfusionSoft can not process the weekly review: ' + message)
              }
              resolve()
            })
            .catch((error) => {
              reject(`We can not notify to InfusionSoft ${error}`)
            })
        }
      })
  })
}

export const changeStudentLevel = (traineeId, programId) => {
  const uri = process.env.REACT_APP_API_URL
  const request = {
    method: 'POST',
    headers: {
      Authorization: getCookie(COOKIE.USER_TOKEN),
    },
    body: JSON.stringify({ program_id: programId }),
  }
  fetch(`${uri}students/${traineeId}/AssignCoachAndLevel`, request)
}

export const getUserData = gql`
  query GetUserData($id: String!, $email: String!, $today: date!) {
    user(where: { email: { _eq: $email } }) {
      id
    }
  }
`
