import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { getUserIds } from 'queries/user'
import MainForm from './MainForm'
import { Content } from './Personal.styled'
import KetogainsLayout from '../Layout'

class Personal extends Component {
  render() {
    return (
      <Query query={getUserIds}>
        {({ loading, error, data }) => {
          if (loading || error) return null
          const {
            User: { id, ex_user_id },
          } = data
          return (
            <KetogainsLayout>
              <Content>
                <MainForm {...{ id, ex_user_id }} />
              </Content>
            </KetogainsLayout>
          )
        }}
      </Query>
    )
  }
}

export default Personal
