import styled from '@emotion/styled'

export const MedicalReportContainer = styled.main`
  p {
    color: #383838;
    font-size: 9px;
    font-weight: 500;
    margin-bottom: 3px;

    &.label {
      color: #959595;
    }
  }

  .ant-tag {
    background-color: rgba(255, 107, 0, 0);
    border-radius: 3px;
    border: 1px solid #26ac00;
    font-size: 9px;
    line-height: 16px;
    color: #26ac00;

    &.danger {
      background-color: #e53d2e;
      border: 1px solid #e53d2e;
      color: #ffffff;
    }

    &.caution {
      background-color: #fff;
      color: #ff4613;
      border: #ff4613 solid 1px;
    }
  }
`
