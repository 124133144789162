import { Card } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import iconShownAssistance from 'images/icon-shown-assistance.svg'
import iconShownCompleted from 'images/icon-shown-completed.svg'
import iconShownMissing from 'images/icon-shown-missing.svg'
import iconShownPause from 'images/icon-shown-pause.svg'
import iconShownReady from 'images/icon-shown-ready.svg'
import iconShowToReview from 'images/icon_show_to_review.png'
import iconShowInProgress from 'images/icon_show_in_progress.png'
import iconShowSentToApprove from 'images/icon_show_sent_to_approve.png'
import iconShowToApprove from 'images/icon_show_to_approve.png'
import iconShowApproved from 'images/icon_show_approved.png'
import iconMealPlanner from 'images/icon_show_meal_plan.png'

const { Meta } = Card

export const StyledCard = styled(Card)`
  height: auto;
  border: thin solid #e6e6e6;
  border-radius: 2px;
  background-color: #ffffff;
  cursor: pointer;
  &:hover,
  &:active {
    border-color: #ff0000;
  }
  .ant-card-body {
    display: flex;
    align-items: center;
    padding: 10px 15px;
  }
  ${({ active }) => {
    if (active === 'active') {
      return css`
        && {
          border-color: #ff0000;
        }
      `
    }
  }}
`
export const StatusIcon = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #5d5d5d;
  &.completed {
    background-color: #059234;
    border-color: #059234;
  }
  &.ready {
    background-color: #e53d2e;
    border-color: #e53d2e;
  }
  &.pause {
    background-color: #5d5d5d;
    border-color: #5d5d5d;
  }
  &.missing {
    background-color: #ffffff;
  }
  &.assistance {
    background-color: #f7ab2f;
    border-color: #f7ab2f;
    background-image: ${({ bgUrl }) => `url(${bgUrl})`};
    background-repeat: no-repeat;
    background-size: 4px auto;
    background-position: center;
  }
  &.none {
    display: none;
  }
  ${({ custom }) => {
    if (custom === 'icon-shown') {
      return css`
        && {
          background: none;
          border: none;
          border-radius: 0;
          &.completed {
            background: url('${iconShownCompleted}') no-repeat center;
            background-size: cover;
          }
          &.ready {
            background: url('${iconShownReady}') no-repeat center;
            background-size: cover;
          }
          &.pause {
            background: url('${iconShownPause}') no-repeat center;
            background-size: cover;
          }
          &.missing {
            background: url('${iconShownMissing}') no-repeat center;
            background-size: cover;
          }
          &.assistance {
            background: url('${iconShownAssistance}') no-repeat center;
            background-size: cover;
          }
          &.review {
            background: url('${iconShowToReview}') no-repeat center;
            background-size: cover;
          }
          &.in_progress {
            background: url('${iconShowInProgress}') no-repeat center;
            background-size: cover;
          }
          &.sent {
            background: url('${iconShowSentToApprove}') no-repeat center;
            background-size: cover;
          }
          &.to_approve {
            background: url('${iconShowToApprove}') no-repeat center;
            background-size: cover;
          }
          &.approved {
            background: url('${iconShowApproved}') no-repeat center;
            background-size: cover;
          }
          &.meal_plan {
            background: url('${iconMealPlanner}') no-repeat center;
            background-size: cover;
          }
          &.none {
            display: none;
          }
        }
      `
    }
  }}
`
export const StyledMeta = styled(Meta)`
  width: 90%;
  display: flex;
  align-items: center;
  .ant-card-meta-title {
    color: #5d5d5d;
    font-size: 12px;
    font-weight: 500;
    overflow: visible;
    white-space: normal;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 2px;
  }
`
