import React from 'react'
import MealPlanUploader from './MealPlanUploader'

const MealPlan = ({ mealPlan, traineeId, programId, refetch, macros }) => {
  return (
    <div>
      <MealPlanUploader macros={macros} refetch={refetch} traineeId={traineeId} programId={programId} mealPlan={mealPlan} />
    </div>
  )
}
export default MealPlan
