import gql from 'graphql-tag'
export const updateMealPlanUrl = gql`
  mutation updateMealPlanUrl($traineeId: uuid!, $programId: uuid!, $url: String!, $status: String!, $approved_date: timestamp!) {
    update_meal_plan(
      where: { trainee_id: { _eq: $traineeId }, program_id: { _eq: $programId } }
      _set: { url: $url, status: $status, approved_date: $approved_date }
    ) {
      returning {
        url
        id
      }
    }
  }
`
