import { Card, Button } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const { Meta } = Card

export const Root = styled.div`
  flex-grow: 1;
  padding: 10px;
  background: #f8f9fa;
  color: #383838;
  @media only screen and (min-width: 1920px) {
    .wrapInfoChat {
      display: flex;
      justify-content: space-between;
    }
    .wrapInfo {
    }
    .wrapIntoInfo {
      flex-grow: 1;
    }
  }
`
export const StyledCard = styled(Card)`
  height: auto;
  width: 299px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  .ant-card-body {
    display: flex;
    padding: 15px;
  }
`
export const StyledCardRow = styled.div``
export const StyledMeta = styled(Meta)`
  width: 90%;
  .ant-card-meta-title {
    color: #ff6b00;
    font-size: 14px;
    font-weight: 500;
    overflow: visible;
    white-space: normal;
  }
  .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 2px;
  }
  .ant-card-meta-description {
    color: #5d5d5d;
    font-size: 11px;
    font-weight: 500;
  }
`
export const StyledButton = styled(Button)`
  height: auto;
  padding: 1px 7px;
  font-size: 9px;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #ff6b00;
  border-radius: 10px;
  border-color: #ff6b00;
  &:hover,
  &:active {
    border-color: #ff6b00;
    color: #ff6b00;
  }
  &:disabled {
    opacity: 0.3;
  }
  &:focus {
    background-color: #ff6b00;
    border-color: #ff6b00;
    color: #fff;
  }
  ${({ custom }) => {
    if (custom === 'large') {
      return css`
        && {
          border-radius: 20px;
          padding: 5px 10px;
          @media only screen and (min-width: 1920px) {
            position: absolute;
            right: 20px;
          }
        }
      `
    }
  }}
  ${({ custom }) => {
    if (custom === 'complete') {
      return css`
        && {
          height: 55px;
          width: 219px;
          margin: 5px;
          font-size: 12px;
          font-weight: 600;
          color: #059234;
          background-color: #ffffff;
          border: 1px solid #059234;
          border-radius: 28px;
          span {
            display: block;
            width: 100%;
          }
          svg {
            height: 17px;
            width: 21px;
            fill: #059234;
          }
          &:hover,
          &:active {
            color: #ffffff;
            background-color: #059234;
            svg {
              fill: #ffffff;
            }
            &:disabled {
              color: #059234;
              background-color: #ffffff;
              border: 1px solid #059234;
              svg {
                fill: #059234;
              }
            }
          }
        }
      `
    }
  }}
`
export const Footer = styled.div`
  padding: 20px;
  text-align: center;
`

export const BootcampInfoCard = styled.div`
  min-height: 64px;

  p {
    color: #383838;
    font-size: 12px;
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p.label {
    color: #959595;
    font-size: 10px;
    font-weight: 500;
  }

  p.name {
    font-weight: 600;
    margin-bottom: 0px;
  }

  p.email {
    font-size: 10px;
    color: #5d5d5d;
    margin-bottom: 0px;
  }

  p.total-bootcamps {
    color: #ff6b00;
    font-size: 10px;
    margin-bottom: 0px;
  }
`
