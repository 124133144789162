import gql from 'graphql-tag'

export const getLetters = gql`
  query getLetters($id: uuid!) {
    coach(where: { id: { _eq: $id }, _and: { is_active: { _eq: true } } }) {
      welcome_letter
      user_id
      id
    }
  }
`

export const updateWelcomeLetter = gql`
  mutation updateWelcomeLetter($IDcoach: uuid!, $Letter: String!) {
    update_coach(where: { id: { _eq: $IDcoach } }, _set: { welcome_letter: $Letter }) {
      returning {
        id
        welcome_letter
        user_id
      }
    }
  }
`
