import React, { Component } from 'react'
import { WeeklyReportContainer, DayNote, MacroTable, MacroTableTitle } from './WeeklyReport.styled'
import { Row, Col, Select, Badge, Empty, Popover, Tooltip, Icon } from 'antd'
import './style.scss'
import { ReactComponent as IconNote } from 'images/icon-note.svg'
import IconAdherenceIssueOff from 'images/icon-adherence-issue-off.svg'
import IconAdherenceIssueHappy from 'images/icon-adherence-issue-happy.svg'
import IconAdherenceIssueSad from 'images/icon-adherence-issue-sad.svg'
import IconAdherenceIssueNeutral from 'images/icon-adherence-issue-neutral.svg'
import DashboardContext from 'context/DashboardContext'
import moment from 'moment'
import DietDetailsModal from 'components/Modals/DietDetailsModal'

class WeeklyReport extends Component {
  state = {
    week: null,
    DailyModalShow: false,
  }

  getIconAdherenceIssue = (type, value) => {
    let icon = IconAdherenceIssueOff
    if (typeof value !== 'undefined') {
      switch (type) {
        case 'sleep':
        case 'hunger':
        case 'lethargy':
        case 'fatigue':
        case 'stress':
          if (value >= 4) icon = IconAdherenceIssueSad
          else if (value >= 2) icon = IconAdherenceIssueNeutral
          else icon = IconAdherenceIssueHappy
          break
        default:
          icon = IconAdherenceIssueOff
          break
      }
    }
    return (
      <Col span={12} className="recovery-exertion">
        <img src={icon} alt="" />
        <span style={{ textTransform: 'capitalize' }}>&nbsp;{type}</span>
      </Col>
    )
  }

  getIconPeriod = (value) => {
    let icon = <Icon type="close-circle" theme="filled" style={{ fontSize: '14px', color: '#e3e3e3' }} />

    if (typeof value === 'boolean') {
      if (value) icon = <Icon type="check-circle" theme="filled" style={{ fontSize: '14px', color: '#98e630' }} />
      else icon = <Icon type="close-circle" theme="filled" style={{ fontSize: '14px', color: '#f41f1f' }} />
    }
    return (
      <Col span={12} className="recovery-exertion">
        {icon}
        <span style={{ textTransform: 'capitalize' }}>&nbsp;Period</span>
      </Col>
    )
  }

  changeWeek = (e) => {
    this.setState({ week: e })
  }

  getDailyDataRow = (macros, dailies, type, dayType, macroTableType) => {
    let suggestedMacros = 0
    let ingestedMacros = 0
    let adherence = 0
    let label = ''
    let adherenceColor = '#FFBA0A'

    const days = dailies.filter((d) => d.day_type === dayType && d[type])
    const totalDays = days.length
    const dayMacros = macros.macro_tables ? macros.macro_tables.find((macroTable) => macroTable.type === macroTableType) || {} : {}

    switch (type) {
      case 'prot':
      default:
        label = 'Protein'
        suggestedMacros = dayMacros.protein_total_grams
        break
      case 'carbs':
        label = 'NET Carbs'
        suggestedMacros = dayMacros.carbs_total_grams
        break
      case 'fat':
        label = 'Fat'
        suggestedMacros = dayMacros.fat_total_grams
        break
    }

    if (!!days && days.length > 0) {
      ingestedMacros = parseFloat(days.map((day) => day[type] || 0).reduce((prev, next) => prev + next) / totalDays).toFixed(2)
    }

    if (!!suggestedMacros && !!ingestedMacros) {
      adherence = parseFloat(((ingestedMacros * 100) / suggestedMacros).toFixed(0))
      if (adherence >= 80 && adherence <= 100) adherenceColor = '#26AC00'
      else if (adherence >= 50 && adherence <= 79) adherenceColor = '#FFBA0A'
      else adherenceColor = '#F50926'
    }

    return (
      <tr>
        <td>
          <span className={`row-title ${type}`}>{label}</span>
        </td>
        <td>
          {suggestedMacros ? suggestedMacros : '-'} <small>g</small>
        </td>
        <td>
          {ingestedMacros} <small>g</small>
        </td>
        <td>
          {adherence > 0 && <Badge color={adherenceColor} text={`${adherence}%`} />}
          {adherence <= 0 && <span>-</span>}
        </td>
      </tr>
    )
  }

  showHideDailyModal = () => {
    this.setState({ DailyModalShow: !this.state.DailyModalShow })
  }

  render() {
    const { ...rest } = this.props

    return (
      <DashboardContext.Consumer>
        {({ student, weekMap, weeklies, dailies, macros }) => {
          let weekActiveDefault = ''
          if (weekMap.length > 0) {
            weekActiveDefault = weekMap[weekMap.length - 1].for_date
          }
          const weeklyActive = this.state.week || weekActiveDefault
          const weekly = weeklies.find((w) => w.for_date === weeklyActive) || {}
          const startWeek = moment(weeklyActive)
          const endWeek = moment(weeklyActive).add(6, 'day')
          const weekDays = dailies.filter((d) => moment(d.for_date).isSameOrAfter(startWeek) && moment(d.for_date).isSameOrBefore(endWeek))
          const weekDaysNotes = weekDays.filter((d) => !!d.notes)
          return (
            <WeeklyReportContainer {...rest}>
              <main>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <p className="subtitle" style={{ margin: '0px' }}>
                      Daily Data
                    </p>
                    <button style={{ cursor: 'pointer' }} className="more" onClick={this.showHideDailyModal}>
                      More
                    </button>
                    {/* <Icon type="ellipsis" style={{ color: '#ff6b00', cursor: 'pointer' }} onClick={this.showHideDailyModal} /> */}
                  </div>
                  {/* TODO: Refactor this with new UI */}
                  <div className="actions" style={{ width: '60%' }}>
                    <Select
                      defaultValue={weekActiveDefault}
                      onChange={this.changeWeek}
                      className="small"
                      dropdownClassName="small"
                      style={{ width: '100%' }}
                    >
                      {weekMap.map((w, index) => {
                        return (
                          <Select.Option key={`week${index + 1}`} value={w.for_date}>
                            <b>Week {index + 1}</b> ({w.start_date} - {w.end_date})
                          </Select.Option>
                        )
                      })}
                    </Select>
                  </div>
                  <DietDetailsModal
                    student={student}
                    handleClose={this.showHideDailyModal}
                    currentTrainee={student.trainee_id}
                    visible={this.state.DailyModalShow}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <MacroTableTitle className="rest-day">
                    <span>Rest Macros</span>
                  </MacroTableTitle>
                  <MacroTable style={{ marginBottom: '3px' }}>
                    <thead>
                      <tr>
                        <th />
                        <th>
                          <Tooltip title="First column shows the recommended macros for a Rest day.">Recommended Macros</Tooltip>
                        </th>
                        <th>
                          <Tooltip title="Shows an average of the macros entered on daily basis by the student.">Actual Macros</Tooltip>
                        </th>
                        <th>
                          <Tooltip title="Shows the adherence using the rule of 3.">Adherence</Tooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.getDailyDataRow(macros, weekDays, 'prot', 'base', 'BaseDay')}
                      {this.getDailyDataRow(macros, weekDays, 'carbs', 'base', 'BaseDay')}
                      {this.getDailyDataRow(macros, weekDays, 'fat', 'base', 'BaseDay')}
                    </tbody>
                  </MacroTable>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <MacroTableTitle className="training-day">
                    <span>Training Macros</span>
                  </MacroTableTitle>
                  <MacroTable>
                    <thead>
                      <tr>
                        <th />
                        <th>
                          <Tooltip title="First column shows the recommended macros for a Training day.">Recommended Macros</Tooltip>
                        </th>
                        <th>
                          <Tooltip title="Shows an average of the macros entered on daily basis by the student.">Actual Macros</Tooltip>
                        </th>
                        <th>
                          <Tooltip title="Shows the adherence using the rule of 3.">Adherence</Tooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.getDailyDataRow(macros, weekDays, 'prot', 'train', 'TrainingDay')}
                      {this.getDailyDataRow(macros, weekDays, 'carbs', 'train', 'TrainingDay')}
                      {this.getDailyDataRow(macros, weekDays, 'fat', 'train', 'TrainingDay')}
                    </tbody>
                  </MacroTable>
                </div>
                <hr />
                <Row>
                  <Col xs={12}>
                    <p className="subtitle">
                      Recovery & Exertion&nbsp;&nbsp;
                      <Tooltip title="Recovery & Exertion helper">
                        <Icon type="question-circle" />
                      </Tooltip>
                    </p>
                    <Row gutter={[2, 8]}>
                      {this.getIconAdherenceIssue('stress', weekly.stress)}
                      {this.getIconAdherenceIssue('sleep', weekly.sleep_issues)}
                      {this.getIconAdherenceIssue('hunger', weekly.hunger_issues)}
                      {this.getIconAdherenceIssue('lethargy', weekly.lethargy)}
                      {this.getIconAdherenceIssue('fatigue', weekly.fatigue)}
                      {student.gender === 'female' ? this.getIconPeriod(weekly.period) : null}
                    </Row>
                  </Col>
                  <Col xs={1} style={{ borderRight: '1px solid #ececec', height: '100px' }} />
                  <Col xs={10} offset={1}>
                    <p className="subtitle">Student Notes</p>
                    {!!weekDaysNotes && weekDaysNotes.length > 0 && (
                      <Row gutter={[4, 4]}>
                        {weekDaysNotes.map((day) => {
                          return (
                            <Col xl={6} lg={4} md={6} xs={4} key={day.for_date}>
                              <Popover title="Note" content={day.notes} overlayClassName="day-note-popover">
                                <DayNote>
                                  <p>
                                    <IconNote />
                                  </p>
                                  <p>{moment(day.for_date).format('ddd')}</p>
                                </DayNote>
                              </Popover>
                            </Col>
                          )
                        })}
                      </Row>
                    )}
                    {(!!!weekDaysNotes || (!!weekDaysNotes && weekDaysNotes.length <= 0)) && (
                      <Empty description="No daily notes" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </Col>
                </Row>
              </main>
            </WeeklyReportContainer>
          )
        }}
      </DashboardContext.Consumer>
    )
  }
}

export default WeeklyReport
