import gql from 'graphql-tag'

export const getUserIds = gql`
  query {
    User @client {
      id
      ex_user_id
      coach_id
    }
  }
`

export const getUserInfo = gql`
  query {
    User @client {
      picture
      nickname
      roles
    }
  }
`

export const getUserData = gql`
  query {
    User @client {
      id
      ex_user_id
      picture
      nickname
    }
  }
`

export const getUserStore = gql`
  query {
    User @client {
      id
      coach_id
      ex_user_id
      nickname
      picture
      __typename
    }
  }
`

export const getUserBootcampData = gql`
  query {
    User @client {
      id
      ex_user_id
      coach_id
      picture
      nickname
      roles
    }
    Bootcamp @client {
      id
      close_date
      start_date
      end_date
      description
    }
  }
`

export const getAdminData = gql`
  query {
    Admin @client {
      id
      nickname
      picture
    }
    User @client {
      id
      as_coach
      coach_id
      nickname
      picture
    }
    Bootcamp @client {
      id
      close_date
      start_date
      end_date
      description
    }
  }
`

export const GetStudentContactId = gql`
  query getStudentContactId($traineeId: uuid!) {
    user(where: { trainee: { id: { _eq: $traineeId } } }) {
      contact_id
    }
  }
`
