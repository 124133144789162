import React, { useEffect, useState } from 'react'
import KetogainsLayout from '../../components/Layout'
import { Button, notification } from 'antd'
import { Query } from 'react-apollo'
import Messages from './Message/Messages'
import TextEditor from './TextEditor/index'
import { Root, Wrap, Content, SubTitle } from './Chat.styled'
import { getLetters, updateWelcomeLetter } from './data'
import GlobalContext from 'context/GlobalContext'
import apolloClient from 'apolloClient'

const Letter = ({ isNew, refetch }) => {
  const [edit, setEdit] = useState(false)

  useEffect(
    () => {
      setEdit(isNew)
    },
    [isNew],
  )

  const handleEdit = (isEdit, refetch) => {
    setEdit(isEdit)
    if (!isEdit) refetch()
  }

  const saveChanges = async (refetch, IDcoach) => {
    const Letter = localStorage.getItem(`LETTER`)
    const { errors } = await apolloClient.mutate({
      mutation: updateWelcomeLetter,
      variables: {
        IDcoach,
        Letter,
      },
      update: () => refetch(),
    })
    if (!errors) {
      notification.success({ message: 'Updated successfully!' })
      handleEdit(false, refetch)
    } else notification.error({ message: 'An error has occurred!' })
  }
  return (
    <GlobalContext.Consumer>
      {({ user }) => {
        return (
          <Query query={getLetters} fetchPolicy="cache-and-network" variables={{ id: user.coach_id }}>
            {({ loading, error, data, refetch }) => {
              if (error) {
                console.error(error)
                return null
              }
              let welcomeLetter = loading ? [] : data.coach[0]
              return (
                <KetogainsLayout>
                  <Root>
                    <Wrap>
                      <Content>
                        <SubTitle>My Welcome letter</SubTitle>
                        {!edit && (
                          <Messages
                            {...{
                              welcomeLetter,
                              userID: user.coach_id,
                              loading,
                              refetch,
                            }}
                          />
                        )}
                        {edit && (
                          <TextEditor
                            {...{
                              welcomeLetter,
                              userID: user.coach_id,
                              loading,
                            }}
                          />
                        )}
                        <br />
                        <div style={{ textAlign: 'center', alignItems: 'center' }}>
                          {!edit && false && (
                            <Button type="primary" onClick={() => handleEdit(true)}>
                              Edit My Welcome letter
                            </Button>
                          )}
                          {edit && (
                            <>
                              <Button onClick={() => handleEdit(false, refetch)}>Cancel</Button>
                              <Button type="primary" onClick={() => saveChanges(refetch, user.coach_id)}>
                                Save {!isNew ? 'Changes' : ''}
                              </Button>
                            </>
                          )}
                        </div>
                      </Content>
                    </Wrap>
                  </Root>
                </KetogainsLayout>
              )
            }}
          </Query>
        )
      }}
    </GlobalContext.Consumer>
  )
}

export default Letter
