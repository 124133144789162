import React, { Component, Fragment } from 'react'
import { Query } from 'react-apollo'
import { Modal } from 'antd'
import gql from 'graphql-tag'
import moment from 'moment'
import { REPLACEMENT, MASK_STYLE } from 'constants.js'
import { Table } from 'styled'
import { ModalTableRow, ModalTableHead, ModalTableCell, Unit } from './DietDetailsModal.styled'
import GlobalContext from 'context/GlobalContext'

const getDetails = gql`
  query($id: uuid!, $startDate: String!, $finishDate: String!) {
    macro_entry(
      where: { trainee_id: { _eq: $id }, _and: [{ for_date: { _gte: $startDate } }, { for_date: { _lte: $finishDate } }] }
      order_by: { for_date: asc }
    ) {
      fat
      prot
      carbs
      weight
      bf_pct
      day_type
      metric
      date: for_date
    }
  }
`

const getWeekDataFormat = (start_date, finish_date, entries) => {
  const weeks = []
  const startDate = moment(start_date)
  const finishDate = moment(finish_date)
  const totalWeeks = finishDate.diff(startDate, 'week') + 1

  for (let weekIndex = 0; weekIndex < totalWeeks; weekIndex++) {
    let week = {
      id: weekIndex + 1,
      days: [],
    }
    const startWeek = moment(startDate)
      .add(weekIndex, 'week')
      .startOf('isoWeek')
    const endWeek = moment(startDate)
      .add(weekIndex, 'week')
      .endOf('isoWeek')

    while (startWeek.isSameOrBefore(endWeek, 'day')) {
      let day = {
        date: startWeek.format('YYYYMMDD'),
      }

      const macroDay = entries.find((e) => e.date === day.date)
      if (macroDay) {
        day = { ...day, ...macroDay }
      }

      day.isComplete = day.prot > 0 && day.carbs > 0 && day.fat > 0 && day.weight > 0

      if (day.day_type === 'train') day.day_type = 'training'

      week.days.push(day)
      startWeek.add(1, 'days')
    }

    const daysCompleted = week.days.filter((d) => d.isComplete)
    const daysWithWeight = week.days.filter((d) => d.weight)
    week.average = {
      prot: parseFloat(
        (daysCompleted.length > 0 ? daysCompleted.reduce((total, next) => total + next.prot, 0) / daysCompleted.length : 0).toFixed(2),
      ),
      carbs: parseFloat(
        (daysCompleted.length > 0 ? daysCompleted.reduce((total, next) => total + next.carbs, 0) / daysCompleted.length : 0).toFixed(2),
      ),
      fat: parseFloat((daysCompleted.length > 0 ? daysCompleted.reduce((total, next) => total + next.fat, 0) / daysCompleted.length : 0).toFixed(2)),
    }

    week.average.weight = parseFloat(
      (daysWithWeight.length > 0 ? daysWithWeight.reduce((total, next) => total + next.weight, 0) / daysWithWeight.length : 0).toFixed(2),
    )

    weeks.push(week)
  }

  return weeks
}

class DietDetailsModal extends Component {
  render() {
    const { currentTrainee, handleClose, visible, student } = this.props
    const coachUnits = localStorage.getItem('COACH_UNITS')
    let units = coachUnits === 'lbs/in' ? 'Weight (Lbs)' : 'Weight (Kg)'

    const recalculate_weight = (weight) => {
      if (coachUnits !== 'lbs/in' && student.units !== 'kg/cm') {
        weight = (weight / 2.205).toFixed(2)
        units = 'Weight (Kg)'
      } else if (coachUnits === 'lbs/in' && student.units === 'kg/cm') {
        weight = (weight * 2.205).toFixed(2)
        units = 'Weight (Lbs)'
      }
      return weight
    }

    return (
      <GlobalContext.Consumer>
        {({ bootcampActive }) => {
          const startDate = moment(bootcampActive.start_date).format('YYYYMMDD')
          const finishDate = moment(bootcampActive.end_date).format('YYYYMMDD')
          return (
            <Query skip={!visible} query={getDetails} fetchPolicy="cache-and-network" variables={{ id: currentTrainee, startDate, finishDate }}>
              {({ loading, error, data }) => {
                if (error) {
                  console.log(error)
                  return null
                }
                if (loading) {
                  return null
                }
                if (!data || !data.macro_entry) return null
                const entries = data.macro_entry || {}
                const weeksFormat = getWeekDataFormat(startDate, finishDate, entries)
                return (
                  <Modal
                    {...{
                      maskStyle: MASK_STYLE,
                      centered: true,
                      title: 'Dietary Adherence',
                      wrapClassName: 'details-modal',
                      width: '70%',
                      footer: null,
                      visible,
                      onOk: handleClose,
                      onCancel: handleClose,
                    }}
                  >
                    <Unit>gr.</Unit>
                    <Table custom="left">
                      {weeksFormat.map((week, index) => {
                        const weekOdd = index % 2 === 0 ? 'odd' : ''
                        return (
                          <Fragment key={week.id}>
                            {index === 0 && (
                              <ModalTableRow>
                                <ModalTableHead />
                                {week.days.map(({ date }) => (
                                  <ModalTableHead key={date}>{moment(date).format('ddd')}</ModalTableHead>
                                ))}
                                <ModalTableHead>weekly average</ModalTableHead>
                              </ModalTableRow>
                            )}
                            <ModalTableRow custom={weekOdd}>
                              <ModalTableCell custom="week-info">{`Week ${index + 1}`}</ModalTableCell>
                              {week.days.map(({ day_type, date }) => (
                                <ModalTableCell key={'d-' + date} custom="week-info">
                                  {day_type || REPLACEMENT}
                                </ModalTableCell>
                              ))}
                              <ModalTableCell custom="week-info" />
                            </ModalTableRow>
                            <ModalTableRow custom={weekOdd}>
                              <ModalTableCell>Protein</ModalTableCell>
                              {week.days.map(({ prot, date }) => (
                                <ModalTableCell key={'p-' + date}>{prot || REPLACEMENT}</ModalTableCell>
                              ))}
                              <ModalTableCell>{week.average.prot || REPLACEMENT}</ModalTableCell>
                            </ModalTableRow>
                            <ModalTableRow custom={weekOdd}>
                              <ModalTableCell>Carbs</ModalTableCell>
                              {week.days.map(({ carbs, date }) => (
                                <ModalTableCell key={'c-' + date}>{carbs || REPLACEMENT}</ModalTableCell>
                              ))}
                              <ModalTableCell>{week.average.carbs || REPLACEMENT}</ModalTableCell>
                            </ModalTableRow>
                            <ModalTableRow custom={weekOdd}>
                              <ModalTableCell>Fat</ModalTableCell>
                              {week.days.map(({ fat, date }) => (
                                <ModalTableCell key={'f-' + date}>{fat || REPLACEMENT}</ModalTableCell>
                              ))}
                              <ModalTableCell>{week.average.fat || REPLACEMENT}</ModalTableCell>
                            </ModalTableRow>
                            <ModalTableRow custom={weekOdd}>
                              <ModalTableCell>{`${units}`}</ModalTableCell>
                              {week.days.map(({ weight, date }) => (
                                <ModalTableCell key={'w-' + date}>{weight ? recalculate_weight(weight) : REPLACEMENT}</ModalTableCell>
                              ))}
                              <ModalTableCell>{week.average.weight ? recalculate_weight(week.average.weight) : REPLACEMENT}</ModalTableCell>
                            </ModalTableRow>
                          </Fragment>
                        )
                      })}
                    </Table>
                  </Modal>
                )
              }}
            </Query>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default DietDetailsModal
