import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { Form, message } from 'antd'
import { loginCredentials } from 'auth'
import Logo from 'components/Logo'
import backgroundImage from 'images/sign-in-bg.png'
import { StyledButton, StyledForm, StyledInput } from 'styled'
import { AppVersion } from 'constants.js'
import { Root, H1, Main, Header, Content, Footer, StyledLink, Version } from './Login.styled'

const FormItem = Form.Item

class Login extends Component {
  state = {
    loading: false,
  }

  loginWithCredentials = (event) => {
    event.preventDefault()
    const {
      form: { validateFieldsAndScroll },
    } = this.props
    return new Promise((resolve, reject) => {
      validateFieldsAndScroll((error, { username, password }) => {
        if (error) {
          console.error(error)
          reject()
          return
        }
        this.setState({ loading: true })
        loginCredentials({ username, password })
          .then(() => {
            navigate(`/${localStorage.getItem('COACH_ID')}`)
            this.setState({ loading: false })
            resolve()
          })
          .catch((error) => {
            message.error(error)
            this.setState({ loading: false })
            resolve()
          })
      })
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Root bgUrl={backgroundImage}>
        <Content>
          <Header>
            <Logo />
          </Header>
          <Main>
            <H1>Sign In</H1>
            <StyledForm hideRequiredMark={true} onSubmit={this.loginWithCredentials}>
              <FormItem label="Email">
                {getFieldDecorator('username', {
                  rules: [
                    {
                      type: 'email',
                      message: 'The input is not valid email!',
                    },
                    { required: true, message: 'Please input your email!' },
                  ],
                })(<StyledInput type="email" />)}
              </FormItem>
              <FormItem label="Password">
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your password!' }],
                })(<StyledInput type="password" />)}
              </FormItem>
              <FormItem>
                <StyledButton disabled={this.state.loading} loading={this.state.loading} htmlType="submit">
                  Sign In
                </StyledButton>
              </FormItem>
              <Version>{AppVersion}</Version>
            </StyledForm>
          </Main>
        </Content>
        <Footer>
          <StyledLink href={`${process.env.REACT_APP_AUTH_SERVER}/wp-login.php?action=lostpassword`} target="_blank">
            Forgot your password?
          </StyledLink>
        </Footer>
      </Root>
    )
  }
}

export default Form.create()(Login)
