import React, { Component } from 'react'
import { Row, Col, Tabs } from 'antd'
import ClientChat from 'components/ClientChat'
import BootcampOverview from './BootcampOverview'
import ProgressPhotos from './ProgressPhotos'
import WeeklyReport from './WeeklyReport'
import MedicalReport from 'components/UserDashboard/MedicalReport'

const { TabPane } = Tabs

class UserProgress extends Component {
  render() {
    const { trainee_id, program_id, showMessage } = this.props
    return (
      <Row gutter={[8, 8]}>
        <Col xl={17}>
          <Row gutter={[8, 8]}>
            <Col md={16}>
              <ProgressPhotos style={{ minHeight: '395px', maxHeight: '395px' }} />
            </Col>
            <Col md={8} className="user-progress-tab">
              <div className="keto-card">
                <Tabs>
                  <TabPane tab="Weekly Report" key="1">
                    <WeeklyReport style={{ minHeight: '324px', maxHeight: '324px' }} />
                  </TabPane>
                  <TabPane tab="Medical Report" key="2">
                    <MedicalReport style={{ minHeight: '324px', maxHeight: '324px' }} ketoCard={false} />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
            <Col md={24}>
              <BootcampOverview style={{ minHeight: '395px', maxHeight: '395px' }} />
            </Col>
          </Row>
        </Col>
        <Col xl={7}>{!!!showMessage && <ClientChat programId={program_id} currentTrainee={trainee_id} />}</Col>
      </Row>
    )
  }
}

export default UserProgress
