import React, { PureComponent } from 'react'
import './index.scss'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import moment from 'moment'
import { LOCAL_STORAGE } from 'constants.js'
import { Cascader, Icon, Badge, Input, Drawer, Slider, Tag, DatePicker, Empty, Tooltip } from 'antd'
import UserCard from './UserCard'
import { Collapse, StyledLink, UserBoard, ShowToggler, UserListWrap } from './UserList.styled'
import { getOptions } from './FilterOptions'
import iconShownCompleted from 'images/icon-shown-completed.svg'
import iconShownMissing from 'images/icon-shown-missing.svg'
import iconShownReady from 'images/icon-shown-ready.svg'
import iconShowToReview from 'images/icon_show_to_review.png'
import iconShowInProgress from 'images/icon_show_in_progress.png'
import iconShowSentToApprove from 'images/icon_show_sent_to_approve.png'
import iconShowToApprove from 'images/icon_show_to_approve.png'
import iconShowApproved from 'images/icon_show_approved.png'
import iconMealPlanner from 'images/icon_show_meal_plan.png'

const { Search } = Input

const { CheckableTag } = Tag

const { RangePicker } = DatePicker

const allUsersIcon = <Icon type="user" className="all-users-icon" />

export const getUserList = gql`
  query getUserList($coachID: uuid!, $actualWeekStart: String!, $startDate: date!, $endDate: date!, $programId: uuid!) {
    coach: coach(where: { id: { _eq: $coachID } }) {
      id
      is_master
      assigned(
        where: { program: { start_date: { _gte: $startDate } }, _and: [{ program: { end_date: { _lte: $endDate } } }] }
        order_by: { sign_date: asc_nulls_last }
      ) {
        trainee {
          id
          user {
            first_name
            last_name
            picture
          }
          selected {
            extras
          }
          measurement(limit: 1, where: { for_date: { _eq: $actualWeekStart } }) {
            id
            status
          }
          macros(where: { program_id: { _eq: $programId } }) {
            status
          }
        }
        program {
          description
          type
        }
      }
    }
    assigned: macro(where: { coach_approver: { _eq: $coachID }, _and: { program_id: { _eq: $programId } } }) {
      trainee {
        id
        user {
          first_name
          last_name
          picture
        }
        selected {
          extras
        }
        measurement(limit: 1, where: { for_date: { _eq: $actualWeekStart } }) {
          id
          status
        }
        macros(where: { program_id: { _eq: $programId } }) {
          status
        }
      }
      program {
        description
        type
      }
    }
  }
`

const CategoryFilter = ({ filter, filterIcon, category, badge, options, handleFilterChange, loading, filterCategory, filterInCategory }) => {
  return (
    <div className="filter-container">
      <Cascader
        className="filter-cascader"
        size="small"
        options={options}
        placeholder="Please select"
        onChange={(e) => handleFilterChange(e[0], e[1])}
        value={[filterCategory, filterInCategory]}
        disabled={loading}
      >
        <div className="body-cascader">
          <div className="text-container">
            <div className="category-container">
              <Icon type="idcard" theme="filled" />
              {category}:
            </div>
            <div className="selected-category-container">
              <span>
                <span className="selected-filter-icon">{filter === 'All Users' ? filterIcon : <img src={filterIcon} alt="" />}</span>
                <span className="current-filter">{filter}</span>
              </span>
              <span className="badge-container">{badge}</span>
            </div>
          </div>
          <span className="arrow-container">
            <Icon type="down" />
          </span>
        </div>
      </Cascader>
    </div>
  )
}

class UserList extends PureComponent {
  state = {
    category: 'Progress',
    filter: 'All Users',
    filterIcon: allUsersIcon,
    trainees: [],
    prevFilteredTrainees: [],
    badge: null,
    search: false,
    filterText: '',
    textFilteredTrainees: [],
    sorted: false,
    AZFilter: 'off',
    AZFilteredTrainees: [],
    MaxMinsorted: false,
    MaxMinFilter: 'off',
    MaxMinFilteredTrainees: [],
    drawerVisible: false,
    minAge: 18,
    maxAge: 80,
    femaleTag: false,
    maleTag: false,
    level1Tag: false,
    level2Tag: false,
    level3Tag: false,
    enrollmentStartDate: '',
    enrollmentEndDate: '',
    options: [],
    filterCategory: '',
    filterInCategory: '',
  }

  handleChange = (value) => {
    this.setState({ filter: value })
  }

  showDrawer = () => {
    this.setState({
      drawerVisible: true,
    })
  }

  closeDrawer = () => {
    this.setState({
      drawerVisible: false,
    })
  }

  handleAZFilter = async () => {
    let { AZFilter, trainees, search, textFilteredTrainees } = await this.state
    let AZFilteredTrainees = search ? [...textFilteredTrainees] : [...trainees]
    let sorted = false

    switch (AZFilter) {
      case 'off':
        sorted = true
        AZFilter = 'ascending'
        AZFilteredTrainees = AZFilteredTrainees.sort(function(a, b) {
          if (a.first_name > b.first_name) {
            return 1
          }
          if (a.first_name < b.first_name) {
            return -1
          }
          return 0
        })
        break
      case 'ascending':
        sorted = true
        AZFilter = 'descending'
        AZFilteredTrainees = AZFilteredTrainees.sort(function(a, b) {
          if (a.first_name > b.first_name) {
            return -1
          }
          if (a.first_name < b.first_name) {
            return 1
          }
          return 0
        })
        break
      case 'descending':
        AZFilter = 'off'
        break
      default:
        break
    }

    await this.setState({
      AZFilter,
      AZFilteredTrainees,
      sorted,
    })
  }
  handleMaxMinFilter = async () => {
    let { MaxMinFilter, trainees, search, textFilteredTrainees } = await this.state
    let MaxMinFilteredTrainees = search ? [...textFilteredTrainees] : [...trainees]
    let MaxMinsorted = false

    switch (MaxMinFilter) {
      case 'off':
        MaxMinsorted = true
        MaxMinFilter = 'ascending'
        MaxMinFilteredTrainees = MaxMinFilteredTrainees.sort(function(a, b) {
          if (a.trainee_program_coach_aggregate.aggregate.count > b.trainee_program_coach_aggregate.aggregate.count) {
            return 1
          }
          if (a.trainee_program_coach_aggregate.aggregate.count < b.trainee_program_coach_aggregate.aggregate.count) {
            return -1
          }
          return 0
        })
        break
      case 'ascending':
        MaxMinsorted = true
        MaxMinFilter = 'descending'
        MaxMinFilteredTrainees = MaxMinFilteredTrainees.sort(function(a, b) {
          if (a.trainee_program_coach_aggregate.aggregate.count > b.trainee_program_coach_aggregate.aggregate.count) {
            return -1
          }
          if (a.trainee_program_coach_aggregate.aggregate.count < b.trainee_program_coach_aggregate.aggregate.count) {
            return 1
          }
          return 0
        })
        break
      case 'descending':
        MaxMinFilter = 'off'
        break
      default:
        break
    }

    await this.setState({
      MaxMinFilter,
      MaxMinFilteredTrainees,
      MaxMinsorted,
    })
  }

  handleAZFilterUpdate = async () => {
    let { AZFilter, trainees, search, textFilteredTrainees } = await this.state
    let AZFilteredTrainees = search ? [...textFilteredTrainees] : [...trainees]
    let sorted = false

    switch (AZFilter) {
      case 'ascending':
        sorted = true
        AZFilteredTrainees = AZFilteredTrainees.sort(function(a, b) {
          if (a.first_name > b.first_name) {
            return 1
          }
          if (a.first_name < b.first_name) {
            return -1
          }
          return 0
        })
        break
      case 'descending':
        sorted = true
        AZFilteredTrainees = AZFilteredTrainees.sort(function(a, b) {
          if (a.first_name > b.first_name) {
            return -1
          }
          if (a.first_name < b.first_name) {
            return 1
          }
          return 0
        })
        break
      case 'off':
        break
      default:
        break
    }

    await this.setState({
      AZFilteredTrainees,
      sorted,
    })
  }

  handleDateFilter = async () => {
    let {
      level1Tag,
      level2Tag,
      level3Tag,
      search,
      prevFilteredTrainees,
      textFilteredTrainees,
      trainees,
      maleTag,
      femaleTag,
      enrollmentStartDate,
      enrollmentEndDate,
    } = this.state

    let prevList = maleTag || femaleTag || level1Tag || level2Tag || level3Tag ? trainees : search ? textFilteredTrainees : prevFilteredTrainees
    let list = []

    if (enrollmentStartDate === '' && enrollmentEndDate === '') {
      list = prevList
    } else {
      prevList.forEach(async (trainee) => {
        if (!!trainee.sign_date) {
          if (moment(trainee.sign_date).isBetween(enrollmentStartDate, enrollmentEndDate, null, '[]')) {
            await list.push(trainee)
          }
        }
      })
    }

    if (search) {
      await this.setState({
        textFilteredTrainees: list,
      })
    } else {
      await this.setState({
        trainees: list,
      })
    }
  }

  handleLevelFilter = async () => {
    let { level1Tag, level2Tag, level3Tag, search, prevFilteredTrainees, textFilteredTrainees, trainees, maleTag, femaleTag } = this.state

    let prevList = maleTag || femaleTag ? trainees : search ? textFilteredTrainees : prevFilteredTrainees
    let list = []

    if (!level1Tag && !level2Tag && !level3Tag) {
      list = prevList
    } else {
      if (level1Tag) {
        await prevList.forEach(async (trainee) => {
          if (trainee && !!trainee.level && trainee.level === 1) {
            await list.push(trainee)
          }
        })
      }
      if (level2Tag) {
        prevList.forEach(async (trainee) => {
          if (trainee && !!trainee.level && trainee.level === 2) {
            await list.push(trainee)
          }
        })
      }
      if (level3Tag) {
        prevList.forEach(async (trainee) => {
          if (trainee && !!trainee.level && trainee.level === 3) {
            await list.push(trainee)
          }
        })
      }
    }

    if (search) {
      await this.setState({
        textFilteredTrainees: list,
      })
    } else {
      await this.setState({
        trainees: list,
      })
    }
  }

  handleGenderFilter = async () => {
    let { femaleTag, maleTag, search, prevFilteredTrainees, textFilteredTrainees } = this.state
    let list = []

    if (!femaleTag && !maleTag) {
      list = search ? textFilteredTrainees : prevFilteredTrainees
    } else {
      if (femaleTag) {
        if (search) {
          list = await textFilteredTrainees.filter((trainee) => trainee.gender === 'female')
        } else {
          list = await prevFilteredTrainees.filter((trainee) => trainee.gender === 'female')
        }
      } else {
        if (search) {
          list = await textFilteredTrainees.filter((trainee) => trainee.gender === 'male')
        } else {
          list = await prevFilteredTrainees.filter((trainee) => trainee.gender === 'male')
        }
      }
    }

    if (search) {
      await this.setState({
        textFilteredTrainees: list,
      })
    } else {
      await this.setState({
        trainees: list,
      })
    }
  }

  handleAgeFilter = async () => {
    let { minAge, maxAge, trainees, search, textFilteredTrainees } = this.state
    let prevTrainees = (await search) ? textFilteredTrainees : trainees
    let list = []

    prevTrainees.forEach(async (trainee) => {
      let birthday = await moment(trainee.birthday).format('YYYY-MM-DD')
      let age = await moment().diff(moment(birthday, 'YYYYMMDD'), 'years')
      if (age >= minAge && age <= maxAge) {
        await list.push(trainee)
      }
    })

    if (search) {
      await this.setState({
        textFilteredTrainees: list,
      })
    } else {
      await this.setState({
        trainees: list,
      })
    }
  }

  handleApplyFilter = async () => {
    await this.handleGenderFilter()
    await this.handleLevelFilter()
    await this.handleDateFilter()
    await this.handleAgeFilter()
    await this.handleAZFilterUpdate()
  }

  handleSearch = async () => {
    let { trainees, filterText } = this.state
    let textFilteredTrainees = []
    let search = false

    await this.handleApplyFilter()

    if (filterText !== '' && filterText.length >= 1) {
      search = await true
      textFilteredTrainees = await trainees.filter((trainee) =>
        `${trainee.first_name.toLowerCase()} ${trainee.last_name.toLowerCase()}`.includes(filterText.toLowerCase()),
      )
    } else {
      await this.handleApplyFilter()
    }

    await this.setState({
      textFilteredTrainees,
      filterText,
      search,
    })
  }

  handleResetFilters = async () => {
    let { prevFilteredTrainees } = this.state

    await this.setState({
      minAge: 18,
      maxAge: 80,
      femaleTag: false,
      maleTag: false,
      level1Tag: false,
      level2Tag: false,
      level3Tag: false,
      filterText: '',
      trainees: prevFilteredTrainees,
      enrollmentStartDate: '',
      enrollmentEndDate: '',
    })

    await this.handleSearch()

    await this.handleAZFilterUpdate()
  }

  handleAllUsers = async () => {
    await this.setState({
      category: 'General',
      filter: 'All Users',
      filterIcon: allUsersIcon,
      trainees: this.props.trainees,
      prevFilteredTrainees: this.props.trainees,
      badge: null,
      filterText: '',
      search: false,
    })
    await this.handleApplyFilter()
  }

  handleProgressFilter = async (status) => {
    let trainees = await this.props.trainees
    let filter = ''
    let filterIcon = ''
    let badge = null
    switch (status) {
      case 'all-users':
        filter = await 'All Users'
        filterIcon = allUsersIcon
        break
      case 'weekly-completed':
        filter = await 'Weekly Review Completed'
        filterIcon = iconShownCompleted
        trainees = await trainees.filter(
          (trainee) => trainee.measurements.length >= 1 && !!trainee.measurements[0] && trainee.measurements[0].status === 1,
        )
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#059234' }} />
        break
      case 'weekly-ready':
        filter = await 'Ready for Weekly Review'
        filterIcon = iconShownReady
        trainees = await trainees.filter(
          (trainee) => trainee.measurements.length >= 1 && !!trainee.measurements[0] && trainee.measurements[0].status === 2,
        )
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#E53D2E' }} />
        break
      case 'missing-info':
        filter = await 'Missing info'
        filterIcon = iconShownMissing
        trainees = await trainees.filter((trainee) => trainee.measurements.length <= 0)
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#FF6B00' }} />
        break
      default:
        break
    }

    await this.setState({
      category: 'Progress',
      filter,
      filterIcon,
      trainees,
      prevFilteredTrainees: trainees,
      badge,
      filterText: '',
      search: false,
    })
    await this.handleApplyFilter()
  }

  handleMacrosFilter = async (status) => {
    let trainees = (await this.props.coachIsMaster) ? this.props.macrosTrainees : this.props.trainees
    let coachId = this.props.coachID
    let filter = ''
    let filterIcon = ''
    let badge = null
    switch (status) {
      case 'all-users':
        filter = await 'All Users'
        filterIcon = allUsersIcon
        trainees = trainees.filter((trainee) => !!trainee.macro_status)
        break
      case 'to-review':
        filter = await 'To Review'
        filterIcon = iconShowToReview
        trainees = await trainees.filter(
          (trainee) => !!trainee && !!trainee.macro_status && trainee.macro_status === 'PendingToApprove' && trainee.coach_approver === coachId,
        )
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#E83B24' }} />
        break
      case 'in-progress':
        filter = await 'In Progress'
        filterIcon = iconShowInProgress
        trainees = await trainees.filter(
          (trainee) => !!trainee && !!trainee.macro_status && trainee.macro_status === 'InProgress' && trainee.coach_approver === coachId,
        )
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#FFAC00' }} />
        break
      case 'sent-to-approve':
        filter = await 'Sent to Approve'
        filterIcon = iconShowSentToApprove
        trainees = await trainees.filter(
          (trainee) => !!trainee && !!trainee.macro_status && trainee.macro_status === 'PendingToApprove' && trainee.coach_approver === coachId,
        )
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#FF6C00' }} />
        break
      case 'to-approve':
        filter = await 'To Approve'
        filterIcon = iconShowToApprove
        trainees = await trainees.filter(
          (trainee) => !!trainee && !!trainee.macro_status && trainee.macro_status === 'PendingToApprove' && trainee.coach_approver === coachId,
        )
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#1887FF' }} />
        break
      case 'to-approve-others':
        filter = await 'To Approve (All Masters)'
        filterIcon = iconShowToApprove
        trainees = await this.props.otherMacrosTrainees.filter(
          (trainee) => !!trainee && !!trainee.macro_status && trainee.macro_status === 'PendingToApprove',
        )
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} overflowCount={999} style={{ backgroundColor: '#1887FF' }} />
        break
      case 'approved':
        filter = await 'Approved'
        filterIcon = iconShowApproved
        trainees = await trainees.filter((trainee) => !!trainee && !!trainee.macro_status && trainee.macro_status === 'Approved')
        let traineesCoach = await trainees.filter(
          (trainee) => !!trainee && !!trainee.macro_status && trainee.macro_status === 'Approved' && trainee.coach_approver === coachId,
        )
        badge = (
          <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', fontSize: 10 }}>
            {traineesCoach.length >= 1 && (
              <>
                You
                <Badge
                  count={traineesCoach && traineesCoach.length >= 1 ? traineesCoach.length : 0}
                  overflowCount={999}
                  style={{ backgroundColor: '#1AAE00', marginLeft: 3, marginRight: 3 }}
                />
              </>
            )}
            {trainees.length >= 1 && (
              <>
                All
                <Badge
                  count={trainees && trainees.length >= 1 ? trainees.length : 0}
                  overflowCount={999}
                  style={{ backgroundColor: '#1AAE00', marginLeft: 3 }}
                />
              </>
            )}
          </div>
        )
        break
      default:
        break
    }

    await this.setState({
      category: 'Macros',
      filter,
      filterIcon,
      trainees,
      prevFilteredTrainees: trainees,
      badge,
      filterText: '',
      search: false,
    })
    await this.handleApplyFilter()
  }

  handleMacrosReviewFilter = async (status) => {
    let trainees = this.props.macrosTrainees
    let filter = ''
    let filterIcon = ''
    let badge = null
    switch (status) {
      case 'to-review':
        filter = await 'To Review'
        filterIcon = iconShowToReview
        trainees = trainees.filter((trainee) => trainee.macro_status && trainee.macro_status === 'PendingToReview' && trainee.is_active)
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#E83B24' }} />
        break
      case 'in-progress':
        filter = await 'In Progress'
        filterIcon = iconShowInProgress
        trainees = trainees.filter((trainee) => trainee.macro_status && trainee.macro_status === 'InProgress' && trainee.is_active)
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#FFAC00' }} />
        break
      case 'approved':
        filter = await 'Approved'
        filterIcon = iconShowApproved
        trainees = trainees.filter((trainee) => trainee.macro_status && trainee.macro_status === 'Approved' && trainee.is_active)
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#1AAE00' }} />
        break
      default:
        break
    }

    await this.setState({
      category: 'Macros Review',
      filter,
      filterIcon,
      trainees,
      prevFilteredTrainees: trainees,
      badge,
      filterText: '',
      search: false,
    })
    await this.handleApplyFilter()
  }

  handleMealFilter = async (status, programType) => {
    let filter = ''
    let filterIcon = ''
    let badge = null
    let trainees = await this.props.mealPlanTrainees

    switch (status) {
      case 'pending':
        filter = await 'Pending Meal Plan'
        filterIcon = iconShowInProgress
        if (programType !== 'meal_plan') {
          trainees = await trainees.filter((trainee) => !!trainee && !!trainee.extras && trainee.extras.meal_plan > 0)
        }

        trainees = trainees.filter((trainee) => trainee.status !== 'MealPlanCompleted')
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#FFAC00' }} />
        break
      case 'approved':
      default:
        filter = await 'Approved Meal Plan'
        filterIcon = iconMealPlanner
        if (programType !== 'meal_plan') {
          trainees = await trainees.filter((trainee) => !!trainee && !!trainee.extras && trainee.extras.meal_plan > 0)
        }

        trainees = trainees.filter((trainee) => trainee.status === 'MealPlanCompleted')
        badge = <Badge count={trainees && trainees.length >= 1 ? trainees.length : 0} style={{ backgroundColor: '#9C71D0' }} />
        break
    }

    await this.setState({
      category: 'Meal Plan',
      filter,
      filterIcon,
      trainees,
      prevFilteredTrainees: trainees,
      badge,
      filterText: '',
      search: false,
    })
    await this.handleApplyFilter()
  }

  handleFilterChange = (category, value) => {
    localStorage.setItem(LOCAL_STORAGE.FILTER_CATEGORY, category)
    localStorage.setItem(LOCAL_STORAGE.FILTER_VALUE, value)

    const programType = this.props.programType

    this.setState({
      filterCategory: category,
      filterInCategory: value,
    })

    switch (category) {
      case 'all-users':
        this.handleAllUsers()
        break
      case 'progress':
        this.handleProgressFilter(value)
        break
      case 'macros':
        this.handleMacrosFilter(value)
        break
      case 'meal-plan':
        this.handleMealFilter(value, programType)
        break
      case 'macros-review':
        this.handleMacrosReviewFilter(value)
        break
      default:
        break
    }
  }

  handleEnrollmentDates = async (dates) => {
    let enrollmentStartDate = ''
    let enrollmentEndDate = ''

    if (dates.length >= 1) {
      enrollmentStartDate = await dates[0]
      enrollmentEndDate = await dates[1]
    }

    await this.setState({
      enrollmentStartDate,
      enrollmentEndDate,
    })
  }

  handleToggleLevelTags = async (tag) => {
    let { level1Tag, level2Tag, level3Tag } = this.state

    let level1 = false
    let level2 = false
    let level3 = false

    if (tag === 'level1') {
      if (!level1Tag) {
        level1 = await true
        if (level2Tag && level3Tag) {
          level2 = await true
        } else {
          level2 = level2Tag
          level3 = level3Tag
        }
      } else {
        level2 = level2Tag
        level3 = level3Tag
      }
    } else if (tag === 'level2') {
      if (!level2Tag) {
        level2 = await true
        if (level1Tag && level3Tag) {
          level1 = await true
        } else {
          level1 = level1Tag
          level3 = level3Tag
        }
      } else {
        level1 = level1Tag
        level3 = level3Tag
      }
    } else {
      if (!level3Tag) {
        level3 = await true
        if (level1Tag && level2Tag) {
          level1 = await true
        } else {
          level1 = level1Tag
          level2 = level2Tag
        }
      } else {
        level1 = level1Tag
        level2 = level2Tag
      }
    }

    await this.setState({
      level1Tag: level1,
      level2Tag: level2,
      level3Tag: level3,
    })
  }

  handleToggleGenderTags = async (tag) => {
    let { maleTag, femaleTag } = this.state

    let male = false
    let female = false

    if (tag === 'male') {
      if (!maleTag) {
        male = await true
      }
    } else {
      if (!femaleTag) {
        female = await true
      }
    }

    this.setState({
      maleTag: male,
      femaleTag: female,
    })
  }

  componentDidMount() {
    let category = localStorage.getItem(LOCAL_STORAGE.FILTER_CATEGORY)
    let opt = []
    let value = localStorage.getItem(LOCAL_STORAGE.FILTER_VALUE)

    let programType = this.props.programType
    if (!!category && !!value) {
      this.handleFilterChange(category, value)
    }

    if (programType === undefined) {
      programType = 'bootcamp'
    }

    opt = getOptions(this.props.coachIsMaster, this.props.coachIsMealPlanner, programType)
    //opt = getOptions(this.props.coachIsMaster, this.props.coachIsMealPlanner, this.props.trainees[0].type) // envia el tipo de usuario a FilterOptions
    this.setState({
      trainees: this.props.trainees,
      prevFilteredTrainees: this.props.trainees,
      options: opt,
    })
  }

  render() {
    const { currentTrainee, coachID, loading } = this.props
    const {
      search,
      filter,
      filterIcon,
      filterText,
      category,
      badge,
      sorted,
      AZFilter,
      AZFilteredTrainees,
      MaxMinsorted,
      MaxMinFilter,
      MaxMinFilteredTrainees,
      drawerVisible,
      minAge,
      maxAge,
      femaleTag,
      maleTag,
      level1Tag,
      level2Tag,
      level3Tag,
      enrollmentStartDate,
      enrollmentEndDate,
      options,
      filterCategory,
      filterInCategory,
    } = this.state

    let trainees = search ? this.state.textFilteredTrainees : this.state.trainees

    if (sorted) {
      trainees = AZFilteredTrainees
    } else if (MaxMinsorted) {
      trainees = MaxMinFilteredTrainees
    } else {
      trainees = search ? this.state.textFilteredTrainees : this.state.trainees
    }

    return (
      <UserBoard>
        <Drawer
          title={
            <div>
              <span style={{ marginRight: '5px' }}>
                <Icon type="filter" style={{ color: '#FF6B00' }} />
              </span>
              <span>Macros Filter</span>
            </div>
          }
          placement="left"
          closable={true}
          onClose={this.closeDrawer}
          visible={drawerVisible}
          getContainer={false}
          style={{ position: 'absolute' }}
        >
          <div className="drawer-body">
            <div className="age-filter-container">
              <div className="age-title-filter-container">
                <p>Age</p>
              </div>
              <div className="age-slider-container">
                <Slider
                  range
                  defaultValue={[18, 80]}
                  value={[minAge, maxAge]}
                  onChange={(value) =>
                    this.setState({
                      minAge: value[0],
                      maxAge: value[1],
                    })
                  }
                  min={18}
                  max={80}
                />
              </div>
            </div>
            <div className="gender-filter-container">
              <div className="gender-title-filter-container">
                <p>Gender</p>
              </div>
              <div className="gender-tags-container">
                <CheckableTag checked={femaleTag} onChange={() => this.handleToggleGenderTags('female')}>
                  Female
                </CheckableTag>
                <CheckableTag checked={maleTag} onChange={() => this.handleToggleGenderTags('male')}>
                  Male
                </CheckableTag>
              </div>
            </div>
            <div className="level-filter-container">
              <div className="level-title-filter-container">
                <p>Level</p>
              </div>
              <div className="level-tags-container">
                <CheckableTag checked={level1Tag} onChange={() => this.handleToggleLevelTags('level1')}>
                  Level 1
                </CheckableTag>
                <CheckableTag checked={level2Tag} onChange={() => this.handleToggleLevelTags('level2')}>
                  Level 2
                </CheckableTag>
                <CheckableTag checked={level3Tag} onChange={() => this.handleToggleLevelTags('level3')}>
                  Level 3
                </CheckableTag>
              </div>
            </div>
            <div className="enrollment-date-filter-container">
              <div className="enrollment-date-title-filter-container">
                <p>
                  <b>Enrollment</b> (Completion Date)
                </p>
              </div>
              <div className="enrollment-date-picker-container">
                <RangePicker
                  className="enrollment-date-picker"
                  showTime={{ format: 'HH:mm' }}
                  value={[enrollmentStartDate, enrollmentEndDate]}
                  format="YYYY-MM-DD HH:mm"
                  placeholder={['Start Date (Time)', 'End Date (Time)']}
                  onChange={(dates) => this.handleEnrollmentDates(dates)}
                />
              </div>
            </div>
            <div className="buttons-container">
              <button
                className="apply-filters-button"
                onClick={() => {
                  this.handleApplyFilter()
                  this.setState({
                    drawerVisible: false,
                  })
                }}
              >
                Apply
              </button>
              <button className="reset-filters-button" onClick={() => this.handleResetFilters()}>
                Reset
              </button>
            </div>
          </div>
        </Drawer>
        <Collapse>
          {/* <StyledLink to={''}>
          <Icon type="double-left" /> Collapse
        </StyledLink> */}
          <span className="extra-filter-container">
            <span style={{ color: '#FF6B00' }}>Students</span>
            {!loading ? (
              <span className="filter-icon-container" onClick={() => this.showDrawer()}>
                <Icon type="filter" />
              </span>
            ) : (
              ''
            )}
          </span>
        </Collapse>
        <span style={{ fontSize: '10px' }}>Category:</span>
        <ShowToggler>
          <CategoryFilter
            {...{
              filter,
              filterIcon,
              category,
              badge,
              options,
              handleFilterChange: this.handleFilterChange,
              loading,
              filterCategory,
              filterInCategory,
            }}
          />
        </ShowToggler>
        <div className="search-AZ-container">
          <span className="search-container" style={{ width: '75%' }}>
            <Search
              placeholder="Search by First or Last Name"
              value={filterText}
              onPressEnter={() => this.handleSearch()}
              onChange={(e) => this.setState({ filterText: e.target.value })}
              onSearch={() => this.handleSearch()}
              disabled={loading}
            />
          </span>
          {!loading ? (
            <span className={`AZ-container ${AZFilter}`} onClick={() => this.handleAZFilter()}>
              {AZFilter === 'descending' ? <Icon type="sort-descending" /> : <Icon type="sort-ascending" />}
            </span>
          ) : (
            ''
          )}
          {!loading ? (
            <Tooltip title="Order by total of bootcamps">
              <span className={`AZ-container ${MaxMinFilter}`} onClick={() => this.handleMaxMinFilter()}>
                {MaxMinFilter === 'descending' ? <Icon type="up" /> : <Icon type="down" />}
              </span>
            </Tooltip>
          ) : (
            ''
          )}
        </div>
        <UserListWrap>
          {!loading &&
            trainees.map(({ trainee_id, first_name, last_name, picture }) => {
              return (
                <StyledLink key={trainee_id} to={`/${coachID}/${trainee_id}`}>
                  <UserCard
                    uuid={trainee_id}
                    name={`${first_name} ${last_name}`}
                    picture={picture}
                    nickname={`${first_name} ${last_name}`}
                    active={trainee_id === currentTrainee ? 'active' : ''}
                  />
                </StyledLink>
              )
            })}
          {!!trainees && trainees.length <= 0 && <Empty title="No users found" />}
        </UserListWrap>
      </UserBoard>
    )
  }
}

// Specifies the default values for props:
UserList.defaultProps = {
  currentTrainee: null,
}

UserList.propTypes = {
  currentTrainee: PropTypes.string,
}

export default UserList
