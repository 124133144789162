import React, { useState } from 'react'
import moment from 'moment'
import { getWeekRange } from 'services'
import { Button, Icon } from 'antd'
import { ModalStyled } from './PhotoDetailModal.styled'

function PhotoDetailModal({ url, date, visible, handleClose, index }) {
  const [rotateDegrees, setrotateDegrees] = useState(0)

  const [zoomScale, setZoomScale] = useState(1)

  const [loading, setLoading] = useState(false)

  let rotateSfx = `-/preview/-/rotate/${rotateDegrees}/`

  const rotateImg = async (direction) => {
    await setLoading(true)

    direction === 'left'
      ? await setrotateDegrees(rotateDegrees + 90 === 360 ? 0 : rotateDegrees + 90)
      : await setrotateDegrees(rotateDegrees - 90 < 0 ? 360 - 90 : rotateDegrees - 90)

    await setLoading(false)
  }

  const handleZoom = (zoom) => {
    zoom === 'zoom-in' ? setZoomScale(zoomScale + 0.25) : setZoomScale(zoomScale - 0.25)
  }

  const closeModal = () => {
    setrotateDegrees(0)
    setZoomScale(1)
  }

  let sfx = rotateDegrees === 0 ? '' : rotateSfx

  return (
    <ModalStyled
      className="modal-picture-zoomable"
      title={
        <div>
          <span className="month-date">{moment(date).format('MMMM YYYY')}</span>
          <span className="week-date">
            <Icon type="calendar" />
            <span>{getWeekRange(date)}</span>
          </span>
        </div>
      }
      visible={visible}
      centered={true}
      width={560}
      onCancel={handleClose}
      onOk={handleClose}
      afterClose={() => closeModal()}
      footer={[
        <Button className="button-zoom-in" key={'zoom-in'} disabled={zoomScale === 1.5 ? true : false} onClick={() => handleZoom('zoom-in')}>
          <Icon type="zoom-in" />
        </Button>,
        <Button
          className="button-zoom-out button-left-bordered"
          key={'zoom-out'}
          disabled={zoomScale === 0.5 ? true : false}
          onClick={() => handleZoom('zoom-out')}
        >
          <Icon type="zoom-out" />
        </Button>,
        <Button className="button-rotate-left button-left-bordered" key={'rotate-left'} onClick={() => rotateImg('left')}>
          <Icon type="undo" rotate={90} />
        </Button>,
        <Button className="button-rotate-right button-left-bordered" key={'rotate-right'} onClick={() => rotateImg('right')}>
          <Icon type="redo" rotate={-90} />
        </Button>,
      ]}
    >
      <div className="img-container">
        {loading ? (
          <Icon type="loading" />
        ) : (
          <img
            src={url + sfx}
            alt=""
            style={{
              transform: `scale(${zoomScale})`,
            }}
          />
        )}
      </div>
    </ModalStyled>
  )
}

export default PhotoDetailModal
