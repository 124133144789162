import React, { Component, Fragment } from 'react'
import get from 'lodash/get'
import showdown from 'showdown'
import Waypoint from 'react-intersection-visible'
import { Root, MessageList, Message, Answer, ReadBlock } from './Messages.styled'

const converter = new showdown.Converter()

const createMarkup = (body) => {
  return { __html: body }
}

class Messages extends Component {
  initMessage = []

  rootElement = React.createRef()

  componentDidUpdate() {
    if (!!get(this.rootElement, 'current')) {
      const newMessage = this.rootElement.current
      newMessage.scrollTop = newMessage.scrollHeight
    }
  }

  scrollToUnread = (element) => {
    if (!!get(this.rootElement, 'current') && !!get(element, 'offsetTop')) {
      const newMessage = this.rootElement.current
      newMessage.scrollTop = newMessage.scrollHeight
    }
  }

  render() {
    const { welcomeLetter, loading } = this.props
    return (
      <Root ref={this.rootElement}>
        <MessageList>
          {!loading && (
            <Fragment key={welcomeLetter.id}>
              <Waypoint>
                <ReadBlock />
              </Waypoint>
              <Message>
                <Answer dangerouslySetInnerHTML={createMarkup(converter.makeHtml(welcomeLetter.welcome_letter))} />
              </Message>
            </Fragment>
          )}
        </MessageList>
      </Root>
    )
  }
}

export default Messages
