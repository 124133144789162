import { Table } from 'antd'
import styled from '@emotion/styled'

export const BenchmarkCard = styled.div`
  border-radius: 4px;
  width: 100%;
  box-shadow: 1px 1px 6px 0 rgba(146, 146, 146, 0.5);
  color: #ffffff;

  p.title {
    border-radius: 4px 4px 0px 0px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    text-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    padding: 4px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div.content {
    border-radius: 0px 0px 4px 4px;
    font-weight: 500;
    font-size: 12px;
    padding: 4px 7px;
    display: flex;
    flex-direction; row;
    align-items: center;

    


    .left, .right {
      width: 45%;
      
      small {
       font-size: 8px;

       &.measure {
         display: flex;
         flex-flow: column nowrap;
         width: 10px;
         justify-content: flex-start;
         align-items: center;
         align-content: center;
         float: left;
         transform: translateY(25%);
        }
      }      
    }

    .center{
      display: flex;
      width: 10%;
      justify-content: center;
      align-items: center;
      text-align: center;
    
      &:before,
      &:after  {
        content: '';
        border-top: 1px solid #ffffff;
        flex: 1 0 400%;
        opacity: 0.5;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    
    
  }
  
  

  &.orange {
    p.title,
    .content {
      background: linear-gradient(139.65deg, #ffb900 0%, #ff8300 100%);
    }
  }

  &.purple {
    p.title,
    .content {
      background: linear-gradient(139.65deg, #bd00ff 0%, #8000ff 100%);
    }
  }

  &.blue {
    p.title,
    .content {
      background: linear-gradient(139.65deg, #00bcff 0%, #0083ff 100%);
    }
  }
`

export const BootcampOverviewGraph = styled.div`
  text,
  .recharts-legend-item-text {
    font-size: 9px;
  }
`

export const BootcampOverviewGraphTooltip = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;

  div.title {
    background-color: #ff6b00;
    color: #ffffff;
    font-size: 10px;
    padding: 2px 5px;
    border-radius: 5px 5px 0 0;
  }

  table {
    width: 100%;
    font-size: 12px;
    thead {
      th {
        background-color: #763434;
        color: #ffffff;
        font-weight: 600;
      }
    }

    tbody {
      tr:nth-of-type(even) {
        background-color: #eeeeee;
      }

      td {
        text-align: center;
        color: #959595;
        margin: 0 2px;

        b {
          font-weight: 500;
          color: #383838;
        }

        span {
          border-radius: 3px;
          padding: 1px 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          display: inline-block;
          vertical-align: middle;

          &.orange {
            background-color: #ff853b;
            color: #ffffff;
          }

          &.blue {
            background-color: #0fc6bf;
            color: #ffffff;
          }
        }
        &.fist-on-row {
          font-size: 13px;
          font-weight: 500;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0 auto;
          span.left-line {
            width: 1px;
            padding: 10px 1px;
          }
        }
        &.data-td {
          width: 30%;
        }
      }

      tr.main-row {
        border-radius: 5px;
        td {
          color: #ffffff;

          b {
            color: #ffffff !important;
          }

          span {
            background-color: transparent !important;
          }
        }

        &.orange {
          td {
            background-color: #ff6b00 !important;
          }
        }

        &.blue {
          td {
            background-color: #02aba5 !important;
          }
        }
      }
    }
  }
`

export const BootcampOverviewTable = styled(Table)`
  thead {
    th {
      font-size: 8px;
      font-weight: 600;
      padding: 3px 0px;
      text-align: center;
      color: #ffffff;
      text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);

      &.weight {
        background-color: #22a494;
        border-radius: 4px 0px 0px 4px;
      }

      &.weight-details {
        background-color: #6bbbb1;
      }

      &.total-body-length {
        background-color: #d55420;
      }

      &.total-body-length-details {
        background-color: #ff6b00;
      }
    }
  }

  tbody {
    td {
      padding: 3px 0px;
      font-size: 9px;
      font-weight: 500;
      text-align: center;

      &.row-title {
        color: #5c7ce2;
      }
    }
  }
`
