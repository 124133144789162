export const getProteins = (macros) => {
  const {
    protein_base_grams,
    protein_total_grams,
    protein_kcal_gram,
    protein_daily_kcals,
    protein_percent_calories,
    protein_pre_workout_grams,
  } = macros

  return {
    baseMacro: protein_base_grams || 0,
    totalGrams: protein_total_grams || 0,
    kcalG: protein_kcal_gram || 0,
    dailyKcals: protein_daily_kcals || 0,
    totalKcals: protein_percent_calories || 0,
    preWorkout: protein_pre_workout_grams || 0,
  }
}

export const getCarbs = (macros) => {
  const { carbs_base_grams, carbs_total_grams, carbs_kcal_gram, carbs_daily_kcals, carbs_percent_calories, carbs_pre_workout_grams } = macros

  return {
    baseMacro: carbs_base_grams || 0,
    totalGrams: carbs_total_grams || 0,
    kcalG: carbs_kcal_gram || 0,
    dailyKcals: carbs_daily_kcals || 0,
    totalKcals: carbs_percent_calories || 0,
    preWorkout: carbs_pre_workout_grams || 0,
  }
}

export const getFat = (macros) => {
  const { fat_base_grams, fat_total_grams, fat_kcal_gram, fat_daily_kcals, fat_percent_calories, fat_pre_workout_grams } = macros

  return {
    baseMacro: fat_base_grams || 0,
    totalGrams: fat_total_grams || 0,
    kcalG: fat_kcal_gram || 0,
    dailyKcals: fat_daily_kcals || 0,
    totalKcals: fat_percent_calories || 0,
    preWorkout: fat_pre_workout_grams || 0,
  }
}

export const getTotal = (macros) => {
  const { total_daily_kcals, total_percent_calories } = macros

  return {
    dailyKcals: total_daily_kcals || 0,
    totalKcals: total_percent_calories || 0,
  }
}
