import { Button } from 'antd'
import styled from '@emotion/styled'

export const WeeklyButton = styled(Button)`
  color: #ffffff;
  border-radius: 17.5px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin-right: 5px;
  border: none;

  @media (max-width: 575.9px) {
    width: 100%;
    margin-bottom: 5px;
  }

  &:last-of-type {
    margin-right: 0px;
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: #ffffff;
    border: none;
  }

  &.complete {
    background-color: #26ac00;
  }

  &[disabled] {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
`
