import { Modal } from 'antd'
import styled from '@emotion/styled'

export const ModalStyled = styled(Modal)`
  &.modal-picture-zoomable {
    max-height: 95vh;
    padding-bottom: 0 !important;
    overflow: hidden;

    .ant-modal-content {
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
      border-radius: 0;
      margin: 0 120px;

      .ant-modal-close {
        span {
          height: 30px;
          width: 30px;
          line-height: 30px;
        }
      }

      .ant-modal-header {
        padding: 5px 24px;
        border-bottom: none;
        border-radius: 6px;
        max-height: 30px;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);

        .ant-modal-title {
          display: flex;
          align-items: center;

          .month-date {
            color: #000;
            font-size: 15px;
            font-weight: 600;
            margin-right: 10px;
          }

          .week-date {
            font-size: 12px;
            padding-left: 10px;
            border-left: 1px solid #ff6b00 !important;
            color: #ff6b00;

            span {
              margin-left: 5px;
            }
          }
        }
      }

      .ant-modal-body {
        padding: 12px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .img-container {
          display: flex;
          justify-content: center;
          border-radius: 8px;
          overflow: hidden;
          min-height: 130px;
          max-height: calc(100vh - 180px);

          &:before {
            position: absolute;
            top: 50%;
            display: block;
            content: 'loading...';
            width: 100px;
            height: 20px;
            color: #fff;
          }

          img {
            max-width: 100%;
            object-fit: scale-down;
          }
        }
      }

      .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        background-color: #fff;

        button {
          border-radius: 0;
          background-color: rgba(0, 0, 0, 0);
          width: 25%;
          margin: 0;
          border: none;
          font-size: 20px;
          color: #ff6b00;

          &:hover {
            cursor: pointer;
          }
        }

        .ant-btn[disabled] {
          color: rgba(0, 0, 0, 0.25);

          &:hover {
            cursor: not-allowed;
          }
        }

        .button-left-bordered {
          border-left: 1px solid #ececec;
        }
      }
    }

    @media only screen and (max-width: 575.9px) {
      .ant-modal-content {
        margin: 0 10px;

        .ant-modal-body {
          min-width: auto !important;
          margin: 0 !important;
          min-height: calc(95vh - 100px);

          .img-container {
            width: 100% !important;
          }
        }
      }
    }
  }
`
