import React from 'react'
import { Icon, Result } from 'antd'
import { MainContainer } from './Impersonate.styled.js'
import useImpersonate from 'hooks/useImpersonate'

export default (props) => {
  const { status, title, subTitle } = useImpersonate(props)

  return (
    <MainContainer>
      <Result
        status={status}
        icon={status === '' ? <Icon type="loading" /> : null}
        title={title}
        subTitle={<div dangerouslySetInnerHTML={{ __html: subTitle }} />}
      />
    </MainContainer>
  )
}
