import React from 'react'
import { Menu, Dropdown, Button, Icon } from 'antd'
export const DataInfoCard = ({ upperText, bottomText, dropdown, dropdownItem, options, handleChange, className, macrosSelected }) => {
  const dropdownMenu = () => {
    return (
      <Menu
        onClick={(item) => {
          handleChange(
            dropdownItem.name,
            dropdownItem.name === 'use_lean_gains'
              ? item.item.props.children === 'Yes'
                ? true
                : false
              : dropdownItem.name === 'deficit_override'
              ? item.item.props.children === ''
                ? null
                : parseFloat(item.item.props.children.slice(0, item.item.props.children.length - 1)) / 100
              : item.item.props.children,
          )
        }}
      >
        {options ? options.map((option, index) => <Menu.Item key={index}>{option}</Menu.Item>) : ''}
      </Menu>
    )
  }
  return (
    <div className={className ? `${className} "info-card-container"` : 'info-card-container'}>
      <div className="info-upper-text" dangerouslySetInnerHTML={{ __html: upperText }} />
      {dropdown ? (
        <div className="info-bottom-dropdown">
          <Dropdown disabled={macrosSelected === 'last' ? true : false} overlay={dropdownMenu} trigger={['click']}>
            <Button className="info-dropdown-button">
              <span className="dropdown-inner-text">
                {upperText === 'Use LeanGains' ? (!dropdownItem.innerText ? 'No' : dropdownItem.innerText) : dropdownItem.innerText}
              </span>
              <Icon type="down" />
            </Button>
          </Dropdown>
          {dropdownItem.outterText ? <span className="dropdown-outter-text">{dropdownItem.outterText}</span> : ''}
        </div>
      ) : (
        <div className="info-bottom-text">{bottomText}</div>
      )}
    </div>
  )
}
