import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { ContainerTextEditor, GiftSelector, GifButton } from './TextEditor.styled'
import ReactQuill, { Quill } from 'react-quill'
import quillEmoji from 'quill-emoji'
import { Popover, Icon } from 'antd'
import { Widget } from '@uploadcare/react-widget'
import Picker from 'react-giphy-component'
import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'
import './index.scss'
Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
  },
  true,
)

const TextEditor = ({ send }, ref) => {
  const [loading, setLoading] = useState(true)
  const [editorHtml, setEditorHtml] = useState('')
  const [attachments, setAttachments] = useState([])
  const [gifVisible, setGifVisible] = useState(false)
  const quillRef = useRef(null)

  useEffect(() => {
    if (quillRef) {
      if (quillRef.current) {
        if (quillRef.current.editingArea) {
          let editorChild = quillRef.current.editingArea.getElementsByClassName('ql-editor')
          if (editorChild && editorChild.length > 0) {
            editorChild[0].removeAttribute('data-gramm')
          }
        }
      }
    }

    const message = localStorage.getItem(`MESSAGE`)
    const attachments = localStorage.getItem(`ATTACHMENTS`)

    setEditorHtml(!!message ? message : '')
    setAttachments(!!attachments ? JSON.parse(attachments) : [])

    setLoading(false)
  }, [])

  useImperativeHandle(ref, () => ({
    handleSendMessage() {
      if ((!!!editorHtml || editorHtml.replace(/<(.|\n)*?>/g, '').trim().length === 0) && attachments.length === 0) {
        alert('Write something first!')
      } else {
        let message = editorHtml
        message += '<div class="attachments">'
        attachments.forEach((attach) => {
          const sizeReadable = fileSizeReadable(attach.size)
          if (attach.isImage) {
            message += '<div class="attachment-item image">'
            message += `<img width="250" src="${attach.cdnUrl}" />`
            message += `<p><a href="${attach.cdnUrl}" target="_blank">${attach.name}</a></p>`
            message += `<p>${sizeReadable}</p>`
            message += '</div>'
          } else {
            message += '<div class="attachment-item file">'
            message += '<div class="preview">'
            message += `<img src="/icons/icon-file.png" alt="" />`
            message += '</div>'
            message += '<div class="detail">'
            message += `<p><a href="${attach.cdnUrl}" target="_blank">${attach.name}</a></p>`
            message += `<p>${sizeReadable}</p>`
            message += '</div>'
            message += '</div>'
          }
        })
        message += '</div>'
        send(message, () => {
          localStorage.removeItem(`ATTACHMENTS`)
          localStorage.removeItem(`MESSAGE`)
          setEditorHtml('')
          setAttachments([])
        })
      }
    },
  }))

  const handleChange = (html) => {
    setEditorHtml(html)
    localStorage.setItem(`MESSAGE`, html)
    if (quillRef) {
      if (quillRef.current) {
        if (quillRef.current.editingArea) {
          let editorChild = quillRef.current.editingArea.getElementsByClassName('ql-editor')
          if (editorChild && editorChild.length > 0) {
            editorChild[0].removeAttribute('data-gramm')
          }
        }
      }
    }
  }

  const handleFileSelected = (file) => {
    if (file) {
      file.done((fileInfo) => {
        const attachments = [fileInfo]
        localStorage.setItem(`ATTACHMENTS`, JSON.stringify(attachments))
        setAttachments(attachments)
      })
    }
  }

  const gifContainer = (onSelectGif) => (
    <GiftSelector>
      <Picker
        {...{
          apiKey: process.env.REACT_APP_GIPHY_KEY,
          modal: true,
          placeholder: 'Search GIPHY',
          imagePlaceholderColor: '#FF6B00',
          loader: <Icon type="loading" />,
          onSelected: onSelectGif,
          children: <Icon className="search-icon" type="search" />,
        }}
      />
    </GiftSelector>
  )

  const handleGifSelect = (gif) => {
    let gifVersion = gif.downsized
    if (!!!gifVersion.url) {
      gifVersion = gif.downsized_large
    }
    let width = gifVersion.width
    if (width > 250) width = 250

    const editor = document.getElementsByClassName('ql-editor')
    let value = ''
    value += editorHtml
    value += `<p><img width="${width}" src="${gifVersion.url}" /></p>`
    editor[0].innerHTML = value
    setEditorHtml(value)
    localStorage.setItem(`MESSAGE`, value)
  }

  const fileSizeReadable = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
  }

  const deleteAttachment = (uuid) => {
    const newAttachments = attachments.filter((a) => a.uuid !== uuid)
    localStorage.setItem(`ATTACHMENTS`, JSON.stringify(newAttachments))
    setAttachments(newAttachments)
  }

  return (
    <ContainerTextEditor>
      {!loading && (
        <>
          <div className="wrap-editor">
            <ReactQuill
              onChange={handleChange}
              modules={TextEditor.modules}
              formats={TextEditor.formats}
              defaultValue={editorHtml}
              ref={quillRef}
              dangerouslySetInnerHTML={{ __html: editorHtml }}
            />
            {attachments && attachments.length > 0 && (
              <div className="attachments">
                {attachments.map((item) => {
                  const sizeReadable = fileSizeReadable(item.size)
                  return (
                    <div className="attachment-item" key={item.uuid}>
                      <div className={`preview ${item.isImage ? 'image' : 'icon'}`}>
                        {item.isImage && <img src={item.cdnUrl} alt="" />}
                        {!item.isImage && <Icon type="file" theme="filled" />}
                      </div>
                      <div className="detail">
                        <p className="name">{item.name}</p>
                        <p className="size">{sizeReadable}</p>
                      </div>
                      <div className="actions">
                        <Icon onClick={() => deleteAttachment(item.uuid)} type="delete" theme="filled" />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>

          <div id="quill-toolbar" className="buttons-container">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-link" />
            <button className="ql-list" value="bullet" />
            <Widget
              publicKey={process.env.REACT_APP_UPLOADCARE_KEY}
              id="file"
              name="file"
              tabs="file url gdrive dropbox"
              previewStep="true"
              onFileSelect={handleFileSelected}
            />
            <button className="ql-emoji" />
            <Popover
              content={gifContainer(handleGifSelect)}
              overlayClassName="chat-container"
              visible={gifVisible}
              trigger="click"
              onVisibleChange={(visible) => setGifVisible(visible)}
            >
              <GifButton>GIF</GifButton>
            </Popover>
          </div>
        </>
      )}
    </ContainerTextEditor>
  )
}

TextEditor.modules = {
  toolbar: {
    container: '#quill-toolbar',
  },
  'emoji-toolbar': true,
}

TextEditor.formats = ['header', 'bold', 'italic', 'underline', 'list', 'link', 'align', 'emoji', 'color', 'video']

export default forwardRef(TextEditor)
