import React, { PureComponent } from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getDateReview } from 'services'
import { Icon } from 'antd'
import GlobalContext from 'context/GlobalContext'
import moment from 'moment'
import { UserBoard } from './UserList.styled'
import UserList from './UserList'

export const getUserList = gql`
  fragment student_data on view_user_list_master {
    program_id
    trainee_id
    first_name
    last_name
    macro_status
    gender
    birthday
    level
    picture
    sign_date
    is_active
    coach_approver
    status
    trainee_program_coach_aggregate {
      aggregate {
        count
      }
    }
  }

  query getStudentData($coachID: uuid!, $programId: uuid!, $actualWeekStart: String!) {
    current_master: view_user_list_master(where: { _and: [{ program_id: { _eq: $programId } }, { is_active: { _eq: true } }] }) {
      ...student_data
    }
    other_masters: view_user_list_master(where: { _and: [{ program_id: { _eq: $programId } }, { is_active: { _eq: true } }] }) {
      ...student_data
    }
    progress: view_user_list_coach(
      where: { _and: [{ program_id: { _eq: $programId } }, { coach_id: { _eq: $coachID } }, { is_active: { _eq: true } }] }
    ) {
      program_id
      trainee_id
      first_name
      last_name
      gender
      birthday
      level
      picture
      sign_date
      measurements(where: { for_date: { _eq: $actualWeekStart } }) {
        status
        for_date
      }
    }
  }
`

export const getUserListWMealPlanners = gql`
  fragment student_data on view_user_list_master {
    program_id
    trainee_id
    first_name
    last_name
    macro_status
    gender
    birthday
    level
    picture
    sign_date
    is_active
    coach_approver
    status
  }

  query getStudentData($coachID: uuid!, $programId: uuid!, $actualWeekStart: String!) {
    current_master: view_user_list_master(where: { _and: [{ program_id: { _eq: $programId } }] }) {
      ...student_data
    }
    other_masters: view_user_list_master(
      where: { _and: [{ program_id: { _eq: $programId } }, { coach_approver: { _neq: $coachID } }, { is_active: { _eq: true } }] }
    ) {
      ...student_data
    }
    progress: view_user_list_coach(
      where: { _and: [{ program_id: { _eq: $programId } }, { coach_id: { _eq: $coachID } }, { is_active: { _eq: true } }] }
    ) {
      program_id
      trainee_id
      first_name
      last_name
      gender
      type
      birthday
      level
      picture
      sign_date
      measurements(where: { for_date: { _eq: $actualWeekStart } }) {
        status
        for_date
      }
    }
    meal_planners: view_user_list_master(where: { program_id: { _eq: $programId } }) {
      ...student_data
      extras
    }
  }
`

let refetchListQuery = () => {}

class UserListQuery extends PureComponent {
  refreshList = () => {
    refetchListQuery()
  }

  render() {
    const { currentTrainee, coachID } = this.props
    const today = moment().local()
    const coachIsMealPlanner = localStorage.getItem('IS_MEAL_PLANNER') === true || localStorage.getItem('IS_MEAL_PLANNER') === 'true' ? true : false

    return (
      <GlobalContext.Consumer>
        {({ bootcampActive }) => {
          const programId = bootcampActive.id
          const programType = bootcampActive.type
          let actualWeekStart
          if (moment(today).isAfter(moment(bootcampActive.end_date))) {
            actualWeekStart = moment(bootcampActive.end_date)
              .startOf('isoWeek')
              .format('YYYYMMDD')
          } else if (moment(today).isBefore(moment(bootcampActive.start_date).add(5, 'days'))) {
            actualWeekStart = moment(bootcampActive.start_date)
              .startOf('isoWeek')
              .format('YYYYMMDD')
          } else {
            actualWeekStart = getDateReview().actualWeekStart
          }

          return (
            <Query
              query={coachIsMealPlanner ? getUserListWMealPlanners : getUserList}
              fetchPolicy="cache-and-network"
              variables={{ coachID, programId, actualWeekStart }}
            >
              {({ loading, error, data, refetch }) => {
                if (error) {
                  console.log(error)
                  return null
                }

                refetchListQuery = () => {
                  refetch()
                }

                const coachIsMaster = localStorage.getItem('IS_MASTER') === true || localStorage.getItem('IS_MASTER') === 'true' ? true : false
                const progress = _.get(data, 'progress') || []
                const macros_current = _.get(data, 'current_master') || []
                const macros_others = _.get(data, 'other_masters') || []
                const meal_planners = _.get(data, 'meal_planners') || []
                let trainees = [...progress]
                let macrosTrainees = [...macros_current, coachID]
                let otherMacrosTrainees = [...macros_others]
                let mealPlanTrainees = [...meal_planners]

                if (loading) {
                  return (
                    <UserBoard style={{ display: 'flex', justifyContent: 'center' }}>
                      <Icon type="loading" style={{ fontSize: '40px', paddingTop: '150px' }} />
                    </UserBoard>
                  )
                }
                return (
                  <UserList
                    {...{
                      trainees,
                      macrosTrainees,
                      mealPlanTrainees,
                      otherMacrosTrainees,
                      coachIsMaster,
                      coachIsMealPlanner,
                      currentTrainee,
                      coachID,
                      loading,
                      programType,
                    }}
                  />
                )
              }}
            </Query>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default UserListQuery
