import React, { Fragment } from "react";

const NotFound = () => (
  <Fragment>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Fragment>
);

export default NotFound;
