import React, { Component, useState } from 'react'
import { Row, Col, Select, Radio } from 'antd'
import { PhotoContainer } from './ProgressPhotos.styled'
import { StyledRadioGroupButton } from 'styled'
import PhotoFrontDefault from 'images/icon-body-front.png'
import PhotoSideDefault from 'images/icon-body-side.png'
import PhotoBackDefault from 'images/icon-body-back.png'
import DashboardContext from 'context/DashboardContext'
import GlobalContext from 'context/GlobalContext'
import moment from 'moment'
import _ from 'lodash'
import PhotoDetailModal from 'components/Modals/PhotoDetailModal'

const { Option } = Select

const ProgressPhoto = (props) => {
  const pictureSufix = '-/preview/'
  const [visibility, setVisibility] = useState(false)

  const toggleVisibility = () => {
    setVisibility(!visibility)
  }

  const { picture, type, onChange, weeks, number, weekSelected, enrollmentStartDate } = props
  let pictureURL
  let isZoomable = !!picture

  switch (type) {
    case 'front':
    default:
      pictureURL = !!picture ? `${picture}${pictureSufix}` : PhotoFrontDefault
      break
    case 'side':
      pictureURL = !!picture ? `${picture}${pictureSufix}` : PhotoSideDefault
      break
    case 'back':
      pictureURL = !!picture ? `${picture}${pictureSufix}` : PhotoBackDefault
      break
  }

  return (
    <PhotoContainer>
      <div className="photo">
        <img src={pictureURL} alt="" onClick={() => toggleVisibility()} />
        {isZoomable && (
          <PhotoDetailModal
            date={weekSelected === 'enrollment' ? enrollmentStartDate : weekSelected}
            url={pictureURL}
            visible={visibility}
            handleClose={() => toggleVisibility()}
          />
        )}
      </div>
      <Select defaultValue={weekSelected} className="small" dropdownClassName="small" onChange={(e) => onChange(e, number)}>
        <Option key="enrollment">
          <b>Enrollment ({moment(enrollmentStartDate).format('DD-MMM-YYYY')})</b>
        </Option>
        {weeks.map((w, index) => {
          return (
            <Option key={w.for_date}>
              <b>Week {index + 1}</b> ({moment(w.for_date).format('DD-MMM-YYYY')})
            </Option>
          )
        })}
      </Select>
    </PhotoContainer>
  )
}

class ProgressPhotos extends Component {
  state = {
    view: 'front',
    photo1: 'enrollment',
    photo2: null,
    photo3: null,
    modalShow: false,
  }

  handleViewChange = (e) => {
    this.setState({ view: e.target.value })
  }

  handlePhotoChange = (e, viewNumber) => {
    switch (viewNumber) {
      case 1:
      default:
        this.setState({ photo1: e })
        break
      case 2:
        this.setState({ photo2: e })
        break
      case 3:
        this.setState({ photo3: e })
        break
    }
  }

  handleModalShow = (e) => {
    this.setState({ modalShow: !this.state.modalShow })
  }

  getPhoto = (tag, view, pictures, enrollment, enrollmentStartDate, enrollmentEndDate) => {
    let photoSelected
    let photoUrl
    if (tag === 'enrollment') {
      if (enrollmentStartDate.isAfter(moment('20201201'))) {
        photoSelected = {
          front_url: enrollment.photo_front_url,
          side_url: enrollment.photo_side_url,
          back_url: enrollment.photo_back_url,
          date: enrollmentStartDate.format('YYYYMMDD'),
        }
      } else {
        photoSelected = pictures.find((p) => moment(p.for_date).isBetween(enrollmentStartDate, enrollmentEndDate, null, '[]')) || null
      }
    } else photoSelected = !!tag ? pictures.find((p) => p.for_date === tag) : null
    if (!!photoSelected) photoUrl = photoSelected[`${view}_url`]
    return photoUrl
  }

  getLastPhotoTag = (tag, weekMap, position) => {
    if (!!!tag) {
      if (!!weekMap && weekMap.length > 0) {
        const lastPictures = _.takeRight(weekMap, 2)
        return !!lastPictures[position] ? lastPictures[position].for_date : 'enrollment'
      }
      return 'enrollment'
    }
    return tag
  }

  render() {
    const { ...rest } = this.props
    let { photo1, photo2, photo3, view } = this.state

    return (
      <GlobalContext.Consumer>
        {({ bootcampActive }) => {
          const enrollmentEndDate = moment(bootcampActive.start_date).add(-1, 'day')
          const enrollmentStartDate = moment(enrollmentEndDate).startOf('isoWeek')
          return (
            <DashboardContext.Consumer>
              {({ weekMap, pictures, enrollment }) => {
                photo2 = this.getLastPhotoTag(photo2, weekMap, 0)
                photo3 = this.getLastPhotoTag(photo3, weekMap, 1)

                let photo1Url = this.getPhoto(photo1, view, pictures, enrollment, enrollmentStartDate, enrollmentEndDate)
                let photo2Url = this.getPhoto(photo2, view, pictures, enrollment, enrollmentStartDate, enrollmentEndDate)
                let photo3Url = this.getPhoto(photo3, view, pictures, enrollment, enrollmentStartDate, enrollmentEndDate)

                return (
                  <div className="keto-card" {...rest}>
                    <header>
                      <h3>Progress Photos</h3>
                      <div className="actions" style={{ width: '70%' }}>
                        <StyledRadioGroupButton defaultValue="front" onChange={this.handleViewChange} style={{ float: 'left' }}>
                          <Radio.Button value="front">Front</Radio.Button>
                          <Radio.Button value="side">Side</Radio.Button>
                          <Radio.Button value="back">Back</Radio.Button>
                        </StyledRadioGroupButton>
                      </div>
                    </header>
                    <main>
                      <Row gutter={[2, 2]}>
                        <Col span={8}>
                          <ProgressPhoto
                            number={1}
                            weeks={weekMap}
                            weekSelected={photo1}
                            type={view}
                            picture={photo1Url}
                            onChange={this.handlePhotoChange}
                            enrollmentStartDate={enrollmentStartDate}
                          />
                        </Col>
                        <Col span={8}>
                          <ProgressPhoto
                            number={2}
                            weeks={weekMap}
                            weekSelected={photo2}
                            type={view}
                            picture={photo2Url}
                            onChange={this.handlePhotoChange}
                            enrollmentStartDate={enrollmentStartDate}
                          />
                        </Col>
                        <Col span={8}>
                          <ProgressPhoto
                            number={3}
                            weeks={weekMap}
                            weekSelected={photo3}
                            type={view}
                            picture={photo3Url}
                            onChange={this.handlePhotoChange}
                            enrollmentStartDate={enrollmentStartDate}
                          />
                        </Col>
                      </Row>
                    </main>
                  </div>
                )
              }}
            </DashboardContext.Consumer>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default ProgressPhotos
