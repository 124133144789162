import gql from 'graphql-tag'

export const getMacroNotes = gql`
  query($currentTrainee: uuid!, $coachID: uuid!, $endDate: timestamp) {
    macro_notes: macro_note(
      order_by: { created: desc }
      where: { created: { _lte: $endDate }, _and: { trainee_id: { _eq: $currentTrainee } } }
      limit: 15
    ) {
      note
      created
      program_id
      change_details
      coach {
        user {
          first_name
          last_name
          picture
        }
      }
      program {
        description
      }
    }
  }
`

export const approveCancelStudentData = gql`
  mutation approveCancelData($id: uuid!, $value: Boolean) {
    update_trainee_details(where: { id: { _eq: $id } }, _set: { need_coach_approval: $value }) {
      returning {
        need_coach_approval
      }
    }
  }
`

export const appendNote = gql`
  mutation appendNote($data: macro_note_insert_input!) {
    insert_macro_note(objects: [$data]) {
      returning {
        id
      }
    }
  }
`

export const updateMacros = gql`
  mutation updateDetails(
    $macro_id: bigint!
    $status: String
    $programId: uuid!
    $currentTrainee: uuid!
    $subscription_id: bigint!
    $activity_level: Int
    $level: Int
    $current_goal: String
    $body_fat_percent: numeric
    $lean_body_mass: numeric
    $use_lean_gains: Boolean
    $protein_intake_override: numeric
    $recommended_deficit: numeric
    $calorie_intake_pre_health: numeric
    $deficit_override: numeric
    $base_protein_base_grams: numeric
    $base_protein_total_grams: numeric
    $base_protein_kcal_gram: numeric
    $base_protein_daily_kcals: numeric
    $base_protein_percent_calories: numeric
    $base_carbs_base_grams: numeric
    $base_carbs_total_grams: numeric
    $base_carbs_kcal_gram: numeric
    $base_carbs_daily_kcals: numeric
    $base_carbs_percent_calories: numeric
    $base_fat_base_grams: numeric
    $base_fat_total_grams: numeric
    $base_fat_kcal_gram: numeric
    $base_fat_daily_kcals: numeric
    $base_fat_percent_calories: numeric
    $base_total_daily_kcals: numeric
    $base_total_percent_calories: numeric
    $training_protein_base_grams: numeric
    $training_protein_pre_workout_grams: numeric
    $training_protein_total_grams: numeric
    $training_protein_kcal_gram: numeric
    $training_protein_daily_kcals: numeric
    $training_protein_percent_calories: numeric
    $training_carbs_base_grams: numeric
    $training_carbs_pre_workout_grams: numeric
    $training_carbs_total_grams: numeric
    $training_carbs_kcal_gram: numeric
    $training_carbs_daily_kcals: numeric
    $training_carbs_percent_calories: numeric
    $training_fat_base_grams: numeric
    $training_fat_pre_workout_grams: numeric
    $training_fat_total_grams: numeric
    $training_fat_kcal_gram: numeric
    $training_fat_daily_kcals: numeric
    $training_fat_percent_calories: numeric
    $training_total_daily_kcals: numeric
    $training_total_percent_calories: numeric
    $is_copy: Boolean
    $copy_from: uuid
  ) {
    update_trainee_details(
      where: { subscription_id: { _eq: $subscription_id } }
      _set: { activity_level: $activity_level, level: $level, current_goal: $current_goal }
    ) {
      returning {
        id
      }
    }
    update_baseMacros: insert_macro(
      objects: [
        {
          status: $status
          program_id: $programId
          trainee_id: $currentTrainee
          subscription_id: $subscription_id
          body_fat_percent: $body_fat_percent
          lean_body_mass: $lean_body_mass
          use_lean_gains: $use_lean_gains
          protein_intake_override: $protein_intake_override
          recommended_deficit: $recommended_deficit
          calorie_intake_pre_health: $calorie_intake_pre_health
          deficit_override: $deficit_override
          is_last_bootcamp_copy: $is_copy
          copy_from_bootcamp: $copy_from
        }
      ]
      on_conflict: {
        constraint: macro_subscription_id_key
        update_columns: [
          body_fat_percent
          lean_body_mass
          use_lean_gains
          protein_intake_override
          recommended_deficit
          calorie_intake_pre_health
          deficit_override
          is_last_bootcamp_copy
          copy_from_bootcamp
        ]
      }
    ) {
      returning {
        trainee_id
      }
    }
    insert_macro_table(
      objects: [
        {
          macro_id: $macro_id
          program_id: $programId
          trainee_id: $currentTrainee
          type: "BaseDay"
          protein_base_grams: $base_protein_base_grams
          protein_total_grams: $base_protein_total_grams
          protein_kcal_gram: $base_protein_kcal_gram
          protein_daily_kcals: $base_protein_daily_kcals
          protein_percent_calories: $base_protein_percent_calories
          carbs_base_grams: $base_carbs_base_grams
          carbs_total_grams: $base_carbs_total_grams
          carbs_kcal_gram: $base_carbs_kcal_gram
          carbs_daily_kcals: $base_carbs_daily_kcals
          carbs_percent_calories: $base_carbs_percent_calories
          fat_base_grams: $base_fat_base_grams
          fat_total_grams: $base_fat_total_grams
          fat_kcal_gram: $base_fat_kcal_gram
          fat_daily_kcals: $base_fat_daily_kcals
          fat_percent_calories: $base_fat_percent_calories
          total_daily_kcals: $base_total_daily_kcals
          total_percent_calories: $base_total_percent_calories
        }
      ]
      on_conflict: {
        constraint: macro_table_pkey
        update_columns: [
          protein_base_grams
          protein_total_grams
          protein_kcal_gram
          protein_daily_kcals
          protein_percent_calories
          carbs_base_grams
          carbs_total_grams
          carbs_kcal_gram
          carbs_daily_kcals
          carbs_percent_calories
          fat_base_grams
          fat_total_grams
          fat_kcal_gram
          fat_daily_kcals
          fat_percent_calories
          total_daily_kcals
          total_percent_calories
        ]
      }
    ) {
      returning {
        trainee_id
      }
    }
    updateTrainingMacros: insert_macro_table(
      objects: [
        {
          macro_id: $macro_id
          program_id: $programId
          trainee_id: $currentTrainee
          type: "TrainingDay"
          protein_base_grams: $training_protein_base_grams
          protein_pre_workout_grams: $training_protein_pre_workout_grams
          protein_total_grams: $training_protein_total_grams
          protein_kcal_gram: $training_protein_kcal_gram
          protein_daily_kcals: $training_protein_daily_kcals
          protein_percent_calories: $training_protein_percent_calories
          carbs_base_grams: $training_carbs_base_grams
          carbs_pre_workout_grams: $training_carbs_pre_workout_grams
          carbs_total_grams: $training_carbs_total_grams
          carbs_kcal_gram: $training_carbs_kcal_gram
          carbs_daily_kcals: $training_carbs_daily_kcals
          carbs_percent_calories: $training_carbs_percent_calories
          fat_base_grams: $training_fat_base_grams
          fat_pre_workout_grams: $training_fat_pre_workout_grams
          fat_total_grams: $training_fat_total_grams
          fat_kcal_gram: $training_fat_kcal_gram
          fat_daily_kcals: $training_fat_daily_kcals
          fat_percent_calories: $training_fat_percent_calories
          total_daily_kcals: $training_total_daily_kcals
          total_percent_calories: $training_total_percent_calories
        }
      ]
      on_conflict: {
        constraint: macro_table_pkey
        update_columns: [
          protein_base_grams
          protein_pre_workout_grams
          protein_total_grams
          protein_kcal_gram
          protein_daily_kcals
          protein_percent_calories
          carbs_base_grams
          carbs_pre_workout_grams
          carbs_total_grams
          carbs_kcal_gram
          carbs_daily_kcals
          carbs_percent_calories
          fat_base_grams
          fat_pre_workout_grams
          fat_total_grams
          fat_kcal_gram
          fat_daily_kcals
          fat_percent_calories
          total_daily_kcals
          total_percent_calories
        ]
      }
    ) {
      returning {
        trainee_id
      }
    }
    delete_macro_table(
      where: { _and: [{ type: { _eq: "Alternate" } }, { program_id: { _eq: $programId } }, { trainee_id: { _eq: $currentTrainee } }] }
    ) {
      affected_rows
    }
  }
`

export const updateMacrosWAlternate = gql`
  mutation updateDetails(
    $macro_id: bigint!
    $status: String
    $programId: uuid!
    $currentTrainee: uuid!
    $subscription_id: bigint!
    $activity_level: Int
    $level: Int
    $current_goal: String
    $body_fat_percent: numeric
    $lean_body_mass: numeric
    $use_lean_gains: Boolean
    $protein_intake_override: numeric
    $recommended_deficit: numeric
    $calorie_intake_pre_health: numeric
    $deficit_override: numeric
    $base_protein_base_grams: numeric
    $base_protein_total_grams: numeric
    $base_protein_kcal_gram: numeric
    $base_protein_daily_kcals: numeric
    $base_protein_percent_calories: numeric
    $base_carbs_base_grams: numeric
    $base_carbs_total_grams: numeric
    $base_carbs_kcal_gram: numeric
    $base_carbs_daily_kcals: numeric
    $base_carbs_percent_calories: numeric
    $base_fat_base_grams: numeric
    $base_fat_total_grams: numeric
    $base_fat_kcal_gram: numeric
    $base_fat_daily_kcals: numeric
    $base_fat_percent_calories: numeric
    $base_total_daily_kcals: numeric
    $base_total_percent_calories: numeric
    $training_protein_base_grams: numeric
    $training_protein_pre_workout_grams: numeric
    $training_protein_total_grams: numeric
    $training_protein_kcal_gram: numeric
    $training_protein_daily_kcals: numeric
    $training_protein_percent_calories: numeric
    $training_carbs_base_grams: numeric
    $training_carbs_pre_workout_grams: numeric
    $training_carbs_total_grams: numeric
    $training_carbs_kcal_gram: numeric
    $training_carbs_daily_kcals: numeric
    $training_carbs_percent_calories: numeric
    $training_fat_base_grams: numeric
    $training_fat_pre_workout_grams: numeric
    $training_fat_total_grams: numeric
    $training_fat_kcal_gram: numeric
    $training_fat_daily_kcals: numeric
    $training_fat_percent_calories: numeric
    $training_total_daily_kcals: numeric
    $training_total_percent_calories: numeric
    $alternate_protein_base_grams: numeric
    $alternate_protein_pre_workout_grams: numeric
    $alternate_protein_total_grams: numeric
    $alternate_protein_kcal_gram: numeric
    $alternate_protein_daily_kcals: numeric
    $alternate_protein_percent_calories: numeric
    $alternate_carbs_base_grams: numeric
    $alternate_carbs_pre_workout_grams: numeric
    $alternate_carbs_total_grams: numeric
    $alternate_carbs_kcal_gram: numeric
    $alternate_carbs_daily_kcals: numeric
    $alternate_carbs_percent_calories: numeric
    $alternate_fat_base_grams: numeric
    $alternate_fat_pre_workout_grams: numeric
    $alternate_fat_total_grams: numeric
    $alternate_fat_kcal_gram: numeric
    $alternate_fat_daily_kcals: numeric
    $alternate_fat_percent_calories: numeric
    $alternate_total_daily_kcals: numeric
    $alternate_total_percent_calories: numeric
    $is_copy: Boolean
    $copy_from: uuid
  ) {
    update_trainee_details(
      where: { subscription_id: { _eq: $subscription_id } }
      _set: { activity_level: $activity_level, level: $level, current_goal: $current_goal }
    ) {
      returning {
        id
      }
    }
    update_baseMacros: insert_macro(
      objects: [
        {
          status: $status
          program_id: $programId
          trainee_id: $currentTrainee
          subscription_id: $subscription_id
          body_fat_percent: $body_fat_percent
          lean_body_mass: $lean_body_mass
          use_lean_gains: $use_lean_gains
          protein_intake_override: $protein_intake_override
          recommended_deficit: $recommended_deficit
          calorie_intake_pre_health: $calorie_intake_pre_health
          deficit_override: $deficit_override
          is_last_bootcamp_copy: $is_copy
          copy_from_bootcamp: $copy_from
        }
      ]
      on_conflict: {
        constraint: macro_subscription_id_key
        update_columns: [
          body_fat_percent
          lean_body_mass
          use_lean_gains
          protein_intake_override
          recommended_deficit
          calorie_intake_pre_health
          deficit_override
          is_last_bootcamp_copy
          copy_from_bootcamp
        ]
      }
    ) {
      returning {
        trainee_id
      }
    }
    insert_macro_table(
      objects: [
        {
          macro_id: $macro_id
          program_id: $programId
          trainee_id: $currentTrainee
          type: "BaseDay"
          protein_base_grams: $base_protein_base_grams
          protein_total_grams: $base_protein_total_grams
          protein_kcal_gram: $base_protein_kcal_gram
          protein_daily_kcals: $base_protein_daily_kcals
          protein_percent_calories: $base_protein_percent_calories
          carbs_base_grams: $base_carbs_base_grams
          carbs_total_grams: $base_carbs_total_grams
          carbs_kcal_gram: $base_carbs_kcal_gram
          carbs_daily_kcals: $base_carbs_daily_kcals
          carbs_percent_calories: $base_carbs_percent_calories
          fat_base_grams: $base_fat_base_grams
          fat_total_grams: $base_fat_total_grams
          fat_kcal_gram: $base_fat_kcal_gram
          fat_daily_kcals: $base_fat_daily_kcals
          fat_percent_calories: $base_fat_percent_calories
          total_daily_kcals: $base_total_daily_kcals
          total_percent_calories: $base_total_percent_calories
        }
      ]
      on_conflict: {
        constraint: macro_table_pkey
        update_columns: [
          protein_base_grams
          protein_total_grams
          protein_kcal_gram
          protein_daily_kcals
          protein_percent_calories
          carbs_base_grams
          carbs_total_grams
          carbs_kcal_gram
          carbs_daily_kcals
          carbs_percent_calories
          fat_base_grams
          fat_total_grams
          fat_kcal_gram
          fat_daily_kcals
          fat_percent_calories
          total_daily_kcals
          total_percent_calories
        ]
      }
    ) {
      returning {
        trainee_id
      }
    }
    updateTrainingMacros: insert_macro_table(
      objects: [
        {
          macro_id: $macro_id
          program_id: $programId
          trainee_id: $currentTrainee
          type: "TrainingDay"
          protein_base_grams: $training_protein_base_grams
          protein_pre_workout_grams: $training_protein_pre_workout_grams
          protein_total_grams: $training_protein_total_grams
          protein_kcal_gram: $training_protein_kcal_gram
          protein_daily_kcals: $training_protein_daily_kcals
          protein_percent_calories: $training_protein_percent_calories
          carbs_base_grams: $training_carbs_base_grams
          carbs_pre_workout_grams: $training_carbs_pre_workout_grams
          carbs_total_grams: $training_carbs_total_grams
          carbs_kcal_gram: $training_carbs_kcal_gram
          carbs_daily_kcals: $training_carbs_daily_kcals
          carbs_percent_calories: $training_carbs_percent_calories
          fat_base_grams: $training_fat_base_grams
          fat_pre_workout_grams: $training_fat_pre_workout_grams
          fat_total_grams: $training_fat_total_grams
          fat_kcal_gram: $training_fat_kcal_gram
          fat_daily_kcals: $training_fat_daily_kcals
          fat_percent_calories: $training_fat_percent_calories
          total_daily_kcals: $training_total_daily_kcals
          total_percent_calories: $training_total_percent_calories
        }
      ]
      on_conflict: {
        constraint: macro_table_pkey
        update_columns: [
          protein_base_grams
          protein_pre_workout_grams
          protein_total_grams
          protein_kcal_gram
          protein_daily_kcals
          protein_percent_calories
          carbs_base_grams
          carbs_pre_workout_grams
          carbs_total_grams
          carbs_kcal_gram
          carbs_daily_kcals
          carbs_percent_calories
          fat_base_grams
          fat_pre_workout_grams
          fat_total_grams
          fat_kcal_gram
          fat_daily_kcals
          fat_percent_calories
          total_daily_kcals
          total_percent_calories
        ]
      }
    ) {
      returning {
        trainee_id
      }
    }
    updateAlternateMacros: insert_macro_table(
      objects: [
        {
          macro_id: $macro_id
          program_id: $programId
          trainee_id: $currentTrainee
          type: "Alternate"
          protein_base_grams: $alternate_protein_base_grams
          protein_pre_workout_grams: $alternate_protein_pre_workout_grams
          protein_total_grams: $alternate_protein_total_grams
          protein_kcal_gram: $alternate_protein_kcal_gram
          protein_daily_kcals: $alternate_protein_daily_kcals
          protein_percent_calories: $alternate_protein_percent_calories
          carbs_base_grams: $alternate_carbs_base_grams
          carbs_pre_workout_grams: $alternate_carbs_pre_workout_grams
          carbs_total_grams: $alternate_carbs_total_grams
          carbs_kcal_gram: $alternate_carbs_kcal_gram
          carbs_daily_kcals: $alternate_carbs_daily_kcals
          carbs_percent_calories: $alternate_carbs_percent_calories
          fat_base_grams: $alternate_fat_base_grams
          fat_pre_workout_grams: $alternate_fat_pre_workout_grams
          fat_total_grams: $alternate_fat_total_grams
          fat_kcal_gram: $alternate_fat_kcal_gram
          fat_daily_kcals: $alternate_fat_daily_kcals
          fat_percent_calories: $alternate_fat_percent_calories
          total_daily_kcals: $alternate_total_daily_kcals
          total_percent_calories: $alternate_total_percent_calories
        }
      ]
      on_conflict: {
        constraint: macro_table_pkey
        update_columns: [
          protein_base_grams
          protein_pre_workout_grams
          protein_total_grams
          protein_kcal_gram
          protein_daily_kcals
          protein_percent_calories
          carbs_base_grams
          carbs_pre_workout_grams
          carbs_total_grams
          carbs_kcal_gram
          carbs_daily_kcals
          carbs_percent_calories
          fat_base_grams
          fat_pre_workout_grams
          fat_total_grams
          fat_kcal_gram
          fat_daily_kcals
          fat_percent_calories
          total_daily_kcals
          total_percent_calories
        ]
      }
    ) {
      returning {
        trainee_id
      }
    }
  }
`

export const updateMacrosStatus = gql`
  mutation updateMacrosStatus($program_id: uuid!, $trainee_id: uuid!, $status: String, $approved_date: timestamp) {
    update_macro(
      where: { program_id: { _eq: $program_id }, _and: { trainee_id: { _eq: $trainee_id } } }
      _set: { status: $status, approved_date: $approved_date }
    ) {
      returning {
        trainee_id
        program_id
        status
        approved_date
      }
    }
  }
`
