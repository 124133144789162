import { Avatar, Badge, Drawer } from 'antd'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const BadgeStyled = styled(Badge)`
  font-size: 30px;
  margin-right: 12px;
  top: 7px;
  position: relative;
  cursor: pointer;
  color: #bbbbbb;

  .ant-badge-count {
    font-size: 9px;
    padding: 0px;
    top: 20px;
  }
`

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-body {
    padding-top: 10px;
  }

  .ant-drawer-title {
    .anticon {
      color: #ff6b00;
      margin-right: 10px;
    }
  }

  .ant-list-item-meta-content {
    .ant-list-item-meta-title {
      line-height: 15px;
      font-size: 12px;

      a,
      a:hover,
      a:active,
      a:visited,
      a:focus {
        color: #5d5d5d;
        font-weight: 600;
      }
    }

    p {
      margin: 0;
      font-size: 10px;
      line-height: 13px;
      color: #959595;
      font-weight: 600;
      max-width: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      small {
        font-weight: 500;
      }
    }
  }
`

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  ${({ custom }) => {
    if (custom === 'admin') {
      return css`
        & {
          padding: 0px 20px;
        }
      `
    }
  }}
`

export const StyledAvatar = styled(Avatar)`
  margin-right: -7px;
  line-height: 30px;
  font-size: 18px;
  .ant-avatar-image {
    background-color: #9b9b9b;
  }
  ${({ custom }) => {
    if (custom === 'admin') {
      return css`
        & {
          img {
            filter: grayscale(100%);
          }
        }
      `
    }
    if (custom === 'coach') {
      return css`
        & {
          margin-right: -7px;
        }
      `
    }
  }}
`

export const DrawerStyledFooter = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: center;
  .ant-btn-primary {
    background-color: #fff !important;
    color: #ff6b00 !important;
    border-color: #ff6b00 !important;
    border-radius: 17.5px;
    height: 22px;
    font-size: 12px;
  }
`
