import gql from 'graphql-tag'

export const getHomepageData = (traineeID) => {
  let traineeQuery

  if (traineeID) {
    traineeQuery = `
      trainee(where: { id: { _eq: "${traineeID}" } }) {
        total_past_bootcamp
        user {
          first_name
          last_name
          picture
          email
          birthday
          gender
          units
        }
        total_program: selected_aggregate {
          aggregate {
            count
          }
        }
        currentProgram: selected(where: { program_id: { _eq: "e13739cd-3d04-45da-869c-c14c35dfd72a" } }) {
          program_id
          extras
          coach {
            user {
              first_name
              last_name
              picture
            }
          }
          enrollment {
            metric
            height
            current_goal
            level
            sub_level
            activity_level
            reprod_health
            med_cond
            prescription_med 
            desord_eating
            diet
            diet_supliments
            has_diet
            type_of_diet
          }
        }
        macros(where: { program_id: { _eq: "e13739cd-3d04-45da-869c-c14c35dfd72a" } }) {
          status
          macro_tables {
            total_daily_kcals
          }
          macro_notes {
            note
          }
        }
      }
    `
  }

  return gql(String.raw`
    query getHomepageData($today: date!){
      program(
        where: { access_date: { _lt: $today } }
        order_by: { close_date: desc }
        limit: 8
      ) {
        program_id
        description
        duration
        access_date
        start_date
        end_date
        close_date
        type
      }
      activity_level {
        id
        label
      }
      ${traineeQuery || ''}
    }
  `)
}
