import React from 'react'
import { Col, Card } from 'antd'
import { changeDetailsLabels } from './data'

export const TableMacroChange = (props) => {
  const { items, activityLevels } = props

  return (
    <>
      {items.map((item, index) => {
        const label = changeDetailsLabels.find((l) => l.id === item.name) || { value: item.name }
        let oldValue = item.oldValue
        let newValue = item.newValue
        if (!isNaN(parseFloat(newValue))) {
          oldValue = parseFloat(parseFloat(oldValue).toFixed(2))
          newValue = parseFloat(parseFloat(newValue).toFixed(2))
        }
        if (item.name.indexOf('percent') !== -1) {
          oldValue += '%'
          newValue += '%'
        }
        if (item.name === 'activity_level') {
          const oldActivityLevel = activityLevels.find((a) => a.id === oldValue) || { label: oldValue }
          const newActivityLevel = activityLevels.find((a) => a.id === newValue) || { label: newValue }
          oldValue = oldActivityLevel.label.substr(0, 10) + '...'
          newValue = newActivityLevel.label.substr(0, 10) + '...'
        }

        if (item.name === 'current_goal') {
          if (item.newValue === 'GAIN_MUSCLE') {
            newValue = 'Gain muscle'
          } else if (item.newValue === 'LOSE_BODY_FAT') {
            newValue = 'Lose body...'
          } else if (item.newValue === 'BODY_RECOMPOSITION') {
            newValue = 'Body rec...'
          }
          if (item.oldValue === 'GAIN_MUSCLE') {
            oldValue = 'Gain Muscle'
          } else if (item.oldValue === 'LOSE_BODY_FAT') {
            oldValue = 'Lose body...'
          } else if (item.oldValue === 'BODY_RECOMPOSITION') {
            oldValue = 'Body rec...'
          }
        }

        if (item.name === 'use_lean_gains') {
          if (newValue === true) {
            newValue = 'true'
          }
          if (newValue === false) {
            newValue = 'false'
          }
          if (oldValue === true) {
            oldValue = 'true'
          }
          if (oldValue === false) {
            oldValue = 'false'
          }
        }

        return (
          <Card style={{ width: 190, height: 90 }} title={label.value} className={'macros-card'}>
            <Col style={{ padding: 0 }} span={12} key={index} className={'section-changes-details-container'}>
              <div className="value-change" style={{ marginBottom: -10 }}>
                <span className="macros-card-label">Original</span>
              </div>

              <div className="macros-card-meassurements macros-card-proteins" style={{ marginBottom: -15 }}>
                <p className="value-change">
                  <span style={{ textTransform: 'capitalize' }} className="old-value">{`${!!oldValue ? oldValue : '-'} `}</span>
                </p>
              </div>
            </Col>
            <Col span={12} style={{ padding: 0 }} key={index} className={'section-changes-details-container'}>
              <div className="value-change" style={{ marginBottom: -10 }}>
                <span className="macros-card-label">Adjusted</span>
              </div>

              <div className="macros-card-meassurements macros-card-proteins" style={{ marginBottom: -15 }}>
                <p className="value-change">
                  <span style={{ textTransform: 'capitalize' }} className="new-value">
                    {!!newValue ? newValue : '-'}
                  </span>
                </p>
              </div>
            </Col>
          </Card>
        )
      })}
    </>
  )
}

export const TableMacro = (props) => {
  const { items, section } = props

  let protein_base_grams = items.filter((item) => item.name === 'protein_base_grams')
  let protein_total_grams = items.filter((item) => item.name === 'protein_total_grams')
  let protein_daily_kcals = items.filter((item) => item.name === 'protein_daily_kcals')
  let protein_pre_workout_grams = items.filter((item) => item.name === 'protein_pre_workout_grams')

  let carbs_base_grams = items.filter((item) => item.name === 'carbs_base_grams')
  let carbs_total_grams = items.filter((item) => item.name === 'carbs_total_grams')
  let carbs_daily_kcals = items.filter((item) => item.name === 'carbs_daily_kcals')
  let carbs_pre_workout_grams = items.filter((item) => item.name === 'carbs_pre_workout_grams')

  let fat_base_grams = items.filter((item) => item.name === 'fat_base_grams')
  let fat_total_grams = items.filter((item) => item.name === 'fat_total_grams')
  let fat_daily_kcals = items.filter((item) => item.name === 'fat_daily_kcals')
  let fat_pre_workout_grams = items.filter((item) => item.name === 'fat_pre_workout_grams')

  let className = 'macros-card card'
  if (section === 'Base Macros') {
    className = 'macros-card-rest-day'
  } else if (section === 'Training Macros') {
    className = 'macros-card-training-day'
  } else if (section === 'Alternate Macros') {
    className = 'macros-card-alter-macros'
  }
  return (
    <Card title={section} style={{ height: 250, width: 675 }} className={`${className} macros-card card`}>
      <div>
        <div className="modal-macros-card-labels">
          <span className="modal-macros-card-label">macros</span>
          <span className="modal-macros-card-label" style={{ marginLeft: -30 }}>
            Base Grams
          </span>
          {section !== 'Base Macros' && <span className="modal-macros-card-label">PWO Grams</span>}
          <span className="modal-macros-card-label">Total Grams</span>
          <span className="modal-macros-card-label">Daily Kcals</span>
        </div>

        <div className="macros-card-meassurements macros-card-proteins">
          <span className="macros-card-data label-left">Protein</span>
          <div className="modal-macros-card-data">
            <span>Original</span>
            <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
          </div>

          {section !== 'Base Macros' && (
            <div className="modal-macros-card-data">
              <span>Original</span>
              <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
            </div>
          )}

          <div className="modal-macros-card-data">
            <span>Original</span>
            <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
          </div>

          <div className="modal-macros-card-data">
            <span>Original</span>
            <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
          </div>
        </div>

        <div className="macros-card-meassurements macros-card-proteins">
          <span className="macros-card-data" style={{ marginRight: 0 }} />

          <div className="modal-macros-card-data">
            <span>{!!protein_base_grams[0] ? protein_base_grams[0].oldValue : '-'}</span>
            <span className={!!protein_base_grams[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
              {!!protein_base_grams[0] ? protein_base_grams[0].newValue : '-'}
            </span>
          </div>

          {section !== 'Base Macros' && (
            <div className="modal-macros-card-data">
              <span>{!!protein_pre_workout_grams[0] ? protein_pre_workout_grams[0].oldValue : '-'}</span>
              <span className={!!protein_pre_workout_grams[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
                {!!protein_pre_workout_grams[0] ? protein_pre_workout_grams[0].newValue : '-'}
              </span>
            </div>
          )}

          <div className="modal-macros-card-data">
            <span>{!!protein_total_grams[0] ? protein_total_grams[0].oldValue : '-'}</span>
            <span className={!!protein_total_grams[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
              {!!protein_total_grams[0] ? protein_total_grams[0].newValue : '-'}
            </span>
          </div>

          <div className="modal-macros-card-data">
            <span>{!!protein_daily_kcals[0] ? protein_daily_kcals[0].oldValue : '-'}</span>
            <span className={!!protein_daily_kcals[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
              {!!protein_daily_kcals[0] ? protein_daily_kcals[0].newValue : '-'}
            </span>
          </div>
        </div>

        <div className="macros-card-meassurements macros-card-proteins">
          <span className="macros-card-data label-left">Carbs</span>
          <div className="modal-macros-card-data">
            <span>Original</span>
            <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
          </div>

          {section !== 'Base Macros' && (
            <div className="modal-macros-card-data">
              <span>Original</span>
              <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
            </div>
          )}

          <div className="modal-macros-card-data">
            <span>Original</span>
            <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
          </div>

          <div className="modal-macros-card-data">
            <span>Original</span>
            <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
          </div>
        </div>

        <div className="macros-card-meassurements macros-card-proteins">
          <span className="macros-card-data" style={{ marginRight: 0 }} />

          <div className="modal-macros-card-data">
            <span>{!!carbs_base_grams[0] ? carbs_base_grams[0].oldValue : '-'}</span>
            <span className={!!carbs_base_grams[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
              {!!carbs_base_grams[0] ? carbs_base_grams[0].newValue : '-'}
            </span>
          </div>

          {section !== 'Base Macros' && (
            <div className="modal-macros-card-data">
              <span>{!!carbs_pre_workout_grams[0] ? carbs_pre_workout_grams[0].oldValue : '-'}</span>
              <span className={!!carbs_pre_workout_grams[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
                {!!carbs_pre_workout_grams[0] ? carbs_pre_workout_grams[0].newValue : '-'}
              </span>
            </div>
          )}

          <div className="modal-macros-card-data">
            <span>{!!carbs_total_grams[0] ? carbs_total_grams[0].oldValue : '-'}</span>
            <span className={!!carbs_total_grams[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
              {!!carbs_total_grams[0] ? carbs_total_grams[0].newValue : '-'}
            </span>
          </div>

          <div className="modal-macros-card-data">
            <span>{!!carbs_daily_kcals[0] ? carbs_daily_kcals[0].oldValue : '-'}</span>
            <span className={!!carbs_daily_kcals[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
              {!!carbs_daily_kcals[0] ? carbs_daily_kcals[0].newValue : '-'}
            </span>
          </div>
        </div>

        <div className="macros-card-meassurements macros-card-proteins">
          <span className="macros-card-data label-left">Fat</span>
          <div className="modal-macros-card-data">
            <span>Original</span>
            <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
          </div>

          {section !== 'Base Macros' && (
            <div className="modal-macros-card-data">
              <span>Original</span>
              <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
            </div>
          )}

          <div className="modal-macros-card-data">
            <span>Original</span>
            <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
          </div>

          <div className="modal-macros-card-data">
            <span>Original</span>
            <span style={{ color: '#000', marginLeft: 10 }}>Adjusted</span>
          </div>
        </div>

        <div className="macros-card-meassurements macros-card-proteins">
          <span className="macros-card-data" style={{ marginRight: 0 }} />

          <div className="modal-macros-card-data">
            <span>{!!fat_base_grams[0] ? fat_base_grams[0].oldValue : '-'}</span>
            <span className={!!fat_base_grams[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
              {!!fat_base_grams[0] ? fat_base_grams[0].newValue : '-'}
            </span>
          </div>

          {section !== 'Base Macros' && (
            <div className="modal-macros-card-data">
              <span>{!!fat_pre_workout_grams[0] ? fat_pre_workout_grams[0].oldValue : '-'}</span>
              <span className={!!fat_pre_workout_grams[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
                {!!fat_pre_workout_grams[0] ? fat_pre_workout_grams[0].newValue : '-'}
              </span>
            </div>
          )}

          <div className="modal-macros-card-data">
            <span>{!!fat_total_grams[0] ? fat_total_grams[0].oldValue : '-'}</span>
            <span className={!!fat_total_grams[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
              {!!fat_total_grams[0] ? fat_total_grams[0].newValue : '-'}
            </span>
          </div>

          <div className="modal-macros-card-data">
            <span>{!!fat_daily_kcals[0] ? fat_daily_kcals[0].oldValue : '-'}</span>
            <span className={!!fat_daily_kcals[0] ? 'modal-card-data-change' : 'modal-card-data-nochange'}>
              {!!fat_daily_kcals[0] ? fat_daily_kcals[0].newValue : '-'}
            </span>
          </div>
        </div>
      </div>
    </Card>
  )
}
