import React from 'react'
import GlobalContext from 'context/GlobalContext'
import { Redirect } from '@reach/router'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import AppLoading from 'components/AppLoading'
import { LOCAL_STORAGE } from 'constants.js'

const getData = gql`
  query {
    User @client {
      id
      coach_id
      as_coach
      nickname
      picture
    }
    Bootcamp @client {
      id
      start_date
      end_date
      close_date
      description
      program_type
      access_date
    }
    App @client {
      loading
    }
  }
`

export class PrivateRoute extends React.Component {
  constructor() {
    super()
    this.state = {
      user: null,
      bootcampActive: null,
      showMeeting: false,
      showMessage: false,
      changeBootcamp: (newBootcamp) => {
        localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_ID, newBootcamp.program_id)
        localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_START_DATE, newBootcamp.start_date)
        localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_END_DATE, newBootcamp.end_date)
        localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_CLOSE_DATE, newBootcamp.close_date)
        localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_DESCRIPTION, newBootcamp.description)
        localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_TYPE, newBootcamp.type)
        localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_ACCESS_DATE, newBootcamp.access_date)
        this.setState({
          bootcampActive: {
            ...newBootcamp,
            id: newBootcamp.program_id,
          },
        })
      },
      displayMeeting: (showed) => {
        this.setState({
          showMeeting: showed,
        })
      },
      displayMessage: (showed) => {
        this.setState({
          showMessage: showed,
        })
      },
    }
  }

  render() {
    const { component: Component, ...rest } = this.props

    return (
      <Query query={getData}>
        {({ error, loading, data }) => {
          if (error) return <p>{`${error}`}</p>
          if (loading) return <AppLoading type="loading" />

          const {
            User: { id },
            App: { loading: loadingApp },
          } = data

          if (!id) {
            return <Redirect to="/login" noThrow />
          }

          if (loadingApp) {
            return <AppLoading type="loading" />
          }

          const context = {
            user: data.User,
            bootcampActive: this.state.bootcampActive || { ...data.Bootcamp, type: data.Bootcamp.program_type },
            showMeeting: this.state.showMeeting,
            showMessage: this.state.showMessage,
            changeBootcamp: this.state.changeBootcamp,
            displayMeeting: this.state.displayMeeting,
            displayMessage: this.state.displayMessage,
          }

          return (
            <GlobalContext.Provider value={context}>
              <Component {...rest} />
            </GlobalContext.Provider>
          )
        }}
      </Query>
    )
  }
}

export default PrivateRoute
