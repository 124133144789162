import React, { useState } from 'react'
import { ModalChangesNoteCard } from './ModalChangesNoteCard'
import { Button } from 'antd'
import moment from 'moment'

export const MacroFooter = ({
  onSave,
  changed,
  changeDetails,
  textNote,
  handleShowChanges,
  handleDiscardChanges,
  handleNoteCreation,
  handleChange,
  macrosStatus,
  coachIsMaster,
  handleMacrosStatus,
  assignedCoachId,
  ApprovedSaveMacros,
  coachID,
  programId,
  currentTrainee,
  refetch,
  macrosSelected,
  lastBootcampMacros,
  threadId,
  onSendMessage,
}) => {
  let text1 = ''
  let text2 = ''
  let message = 'Your Macros are ready! You can review them in the Macros Section of the Ketogains Client Portal.'

  const userID = localStorage.getItem('USER_ID')
  const lastMessageDate = moment().local()

  const [visibility, setVisibility] = useState(false)

  const autoModal = async () => {
    await handleShowChanges()
    await setVisibility(!visibility)
  }

  const toggleVisibility = () => {
    setVisibility(!visibility)
  }

  const discardChanges = async () => {
    await setVisibility(!visibility)
    await handleDiscardChanges()
    await refetch()
  }

  const changueMacroStatus = () => {
    handleMacrosStatus()
    onSendMessage(message, threadId, lastMessageDate, userID)
  }

  switch (macrosStatus) {
    case 'PendingToReview':
    case 'InProgress':
    case 'PendingToApprove':
      text1 = 'Save all Changes'
      text2 = 'Approve'
      break
    case 'Approved':
      text1 = 'Save & Send Changes'
      text2 = 'Approve'
      break
    default:
  }

  if (coachID === assignedCoachId) {
    if (macrosStatus === 'PendingToReview' || macrosStatus === 'InProgress') {
      return (
        <div className="footer-container">
          {ApprovedSaveMacros && changed ? (
            <div>
              <Button key="save" className="footer-save-button" onClick={() => autoModal()}>
                {text1}
              </Button>
              <ModalChangesNoteCard
                visible={visibility}
                changeDetails={changeDetails}
                handleChange={handleChange}
                handleCancel={() => toggleVisibility()}
                handleDiscardChanges={() => discardChanges()}
                handleNoteCreation={handleNoteCreation}
                onSave={onSave}
                textNote={textNote}
                coachID={coachID}
                programId={programId}
                currentTrainee={currentTrainee}
                refetch={refetch}
                macrosSelected={macrosSelected}
                lastBootcampMacros={lastBootcampMacros}
                macrosStatus={macrosStatus}
              />
            </div>
          ) : (
            <Button key="save" className="footer-save-button ant-btn-disabled" disabled={true}>
              {text1}
            </Button>
          )}
          <Button key="send" className="footer-send-button" onClick={() => handleMacrosStatus()}>
            {text2}
          </Button>
        </div>
      )
    } else if (macrosStatus === 'PendingToApprove') {
      return (
        <div className="footer-container">
          {ApprovedSaveMacros && changed ? (
            <div>
              <Button key="save" className="footer-save-button" onClick={() => autoModal()}>
                {text1}
              </Button>
              <ModalChangesNoteCard
                visible={visibility}
                changeDetails={changeDetails}
                handleChange={handleChange}
                handleCancel={() => toggleVisibility()}
                handleDiscardChanges={() => discardChanges()}
                handleNoteCreation={handleNoteCreation}
                onSave={onSave}
                textNote={textNote}
                coachID={coachID}
                programId={programId}
                currentTrainee={currentTrainee}
                refetch={refetch}
                macrosSelected={macrosSelected}
                lastBootcampMacros={lastBootcampMacros}
                macrosStatus={macrosStatus}
              />
            </div>
          ) : (
            <Button key="save" className="footer-save-button ant-btn-disabled" disabled={true}>
              {text1}
            </Button>
          )}
          <Button key="send" className="footer-send-button" onClick={() => changueMacroStatus()}>
            {text2}
          </Button>
        </div>
      )
    } else if (macrosStatus === 'Approved') {
      return (
        <div className="footer-container">
          {ApprovedSaveMacros && changed ? (
            <div>
              <Button key="save" className="footer-save-button" onClick={() => autoModal()}>
                {text1}
              </Button>
              <ModalChangesNoteCard
                visible={visibility}
                changeDetails={changeDetails}
                handleChange={handleChange}
                handleCancel={() => toggleVisibility()}
                handleDiscardChanges={() => discardChanges()}
                handleNoteCreation={handleNoteCreation}
                onSave={onSave}
                textNote={textNote}
                coachID={coachID}
                programId={programId}
                currentTrainee={currentTrainee}
                refetch={refetch}
                macrosSelected={macrosSelected}
                lastBootcampMacros={lastBootcampMacros}
                macrosStatus={macrosStatus}
              />
            </div>
          ) : (
            <Button key="save" className="footer-save-button ant-btn-disabled" disabled={true}>
              {text1}
            </Button>
          )}
        </div>
      )
    } else {
      return null
    }
  } else {
    if (coachIsMaster === 'true' || coachIsMaster === true) {
      if (macrosStatus === 'PendingToReview' || macrosStatus === 'InProgress') {
        return (
          <div className="footer-container">
            {ApprovedSaveMacros && changed ? (
              <div>
                <Button key="save" className="footer-save-button" onClick={() => autoModal()}>
                  {text1}
                </Button>
                <ModalChangesNoteCard
                  visible={visibility}
                  changeDetails={changeDetails}
                  handleChange={handleChange}
                  handleCancel={() => toggleVisibility()}
                  handleDiscardChanges={() => discardChanges()}
                  handleNoteCreation={handleNoteCreation}
                  onSave={onSave}
                  textNote={textNote}
                  coachID={coachID}
                  programId={programId}
                  currentTrainee={currentTrainee}
                  refetch={refetch}
                  macrosSelected={macrosSelected}
                  lastBootcampMacros={lastBootcampMacros}
                  macrosStatus={macrosStatus}
                />
              </div>
            ) : (
              <Button key="save" className="footer-save-button ant-btn-disabled" disabled={true}>
                {text1}
              </Button>
            )}
            <Button key="send" className="footer-send-button" onClick={() => handleMacrosStatus()}>
              {text2}
            </Button>
          </div>
        )
      } else if (macrosStatus === 'PendingToApprove') {
        return (
          <div className="footer-container">
            {ApprovedSaveMacros && changed ? (
              <div>
                <Button key="save" className="footer-save-button" onClick={() => autoModal()}>
                  {text1}
                </Button>
                <ModalChangesNoteCard
                  visible={visibility}
                  changeDetails={changeDetails}
                  handleChange={handleChange}
                  handleCancel={() => toggleVisibility()}
                  handleDiscardChanges={() => discardChanges()}
                  handleNoteCreation={handleNoteCreation}
                  onSave={onSave}
                  textNote={textNote}
                  coachID={coachID}
                  programId={programId}
                  currentTrainee={currentTrainee}
                  refetch={refetch}
                  macrosSelected={macrosSelected}
                  lastBootcampMacros={lastBootcampMacros}
                  macrosStatus={macrosStatus}
                />
              </div>
            ) : (
              <Button key="save" className="footer-save-button ant-btn-disabled" disabled={true}>
                {text1}
              </Button>
            )}
            <Button key="send" className="footer-send-button" onClick={() => changueMacroStatus()}>
              {text2}
            </Button>
          </div>
        )
      } else if (macrosStatus === 'Approved') {
        return (
          <div className="footer-container">
            {ApprovedSaveMacros && changed ? (
              <div>
                <Button key="save" className="footer-save-button" onClick={() => autoModal()}>
                  {text1}
                </Button>
                <ModalChangesNoteCard
                  visible={visibility}
                  changeDetails={changeDetails}
                  handleChange={handleChange}
                  handleCancel={() => toggleVisibility()}
                  handleDiscardChanges={() => discardChanges()}
                  handleNoteCreation={handleNoteCreation}
                  onSave={onSave}
                  textNote={textNote}
                  coachID={coachID}
                  programId={programId}
                  currentTrainee={currentTrainee}
                  refetch={refetch}
                  macrosSelected={macrosSelected}
                  lastBootcampMacros={lastBootcampMacros}
                  macrosStatus={macrosStatus}
                />
              </div>
            ) : (
              <Button key="save" className="footer-save-button ant-btn-disabled" disabled={true}>
                {text1}
              </Button>
            )}
          </div>
        )
      } else {
        return null
      }
    } else {
      if (macrosStatus === 'PendingToReview' || macrosStatus === 'InProgress') {
        return (
          <div className="footer-container">
            {ApprovedSaveMacros && changed ? (
              <div>
                <Button key="save" className="footer-save-button" onClick={() => autoModal()}>
                  {text1}
                </Button>
                <ModalChangesNoteCard
                  visible={visibility}
                  changeDetails={changeDetails}
                  handleChange={handleChange}
                  handleCancel={() => toggleVisibility()}
                  handleDiscardChanges={() => discardChanges()}
                  handleNoteCreation={handleNoteCreation}
                  onSave={onSave}
                  textNote={textNote}
                  coachID={coachID}
                  programId={programId}
                  currentTrainee={currentTrainee}
                  refetch={refetch}
                  macrosSelected={macrosSelected}
                  lastBootcampMacros={lastBootcampMacros}
                  macrosStatus={macrosStatus}
                />
              </div>
            ) : (
              <Button key="save" className="footer-save-button ant-btn-disabled" disabled={true}>
                {text1}
              </Button>
            )}
            <Button key="send" className="footer-send-button" onClick={() => handleMacrosStatus()}>
              {text2}
            </Button>
          </div>
        )
      } else {
        return null
      }
    }
  }
}
