import React, { Component } from 'react'
import _ from 'lodash'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import { getHomepageData } from './query'
import GlobalContext from 'context/GlobalContext'
import moment from 'moment'
import { Select, Spin, Result, Row, Col, Progress } from 'antd'
import { LOCAL_STORAGE } from '../../constants'
import BootcampBarIcon from 'images/bootcamp-bar-icon.svg'
import GoogleMeetIcon from 'assets/icons/google-meet.png'
import SentMessageIcon from 'assets/icons/sent-message.png'
import 'styled/BootcampBar.scss'

const { Option } = Select

const getMaxCapacity = gql`
  query getMaxCapacity($coachId: uuid!, $programId: uuid!) {
    coach(where: { id: { _eq: $coachId } }) {
      max_students
      configuration(
        where: { coach_id: { _eq: $coachId }, _and: { program_id: { _eq: $programId }, _and: { type: { _eq: "max_students" } } } }
        limit: 1
      ) {
        type
        value
      }
      user {
        first_name
        last_name
      }
      assigned_aggregate(where: { _and: [{ program_id: { _eq: $programId } }, { is_active: { _eq: true } }] }) {
        aggregate {
          count
        }
      }
    }
  }
`

const filterBootcamps = (bootcamps) => {
  const roles = localStorage.getItem(LOCAL_STORAGE.USER_ROLES)
  const isMaster = localStorage.getItem(LOCAL_STORAGE.IS_MASTER)
  const isMealPlanner = localStorage.getItem(LOCAL_STORAGE.IS_MEAL_PLANNER)
  if (!roles) return []
  const showBootcamps = roles.includes('coach') || roles.includes('meal_planner')
  const showMacrosAndMealPlan = roles.includes('coach') || roles.includes('meal_planner') || roles.includes('reviewer')
  const mealPlan = bootcamps.find((b) => b.type === 'meal_plan')
  const macros = bootcamps.find((b) => b.type === 'macros')
  const filteredBootcamps = showBootcamps ? bootcamps.filter((b) => b.type !== 'meal_plan' && b.type !== 'macros') : []
  if (showMacrosAndMealPlan) {
    if (isMaster === 'true' || isMaster === true) {
      if (macros) filteredBootcamps.unshift(macros)
    }
    if (isMealPlanner === 'true' || isMealPlanner === true) {
      if (mealPlan) filteredBootcamps.unshift(mealPlan)
    }
  }

  return filteredBootcamps
}
class BootcampBar extends Component {
  shouldComponentUpdate(nextProps) {
    return false
  }

  setFilterValue = (newBootcampSelected) => {
    const weekNumber = moment().diff(moment(newBootcampSelected.start_date).subtract(3, 'days'), 'week')
    const isMaster = localStorage.getItem(LOCAL_STORAGE.IS_MASTER)
    if (newBootcampSelected.type === 'macros') {
      localStorage.setItem(LOCAL_STORAGE.FILTER_CATEGORY, 'macros-review')
      localStorage.setItem(LOCAL_STORAGE.FILTER_VALUE, 'to-review')
    } else if (newBootcampSelected.type === 'meal_plan') {
      localStorage.setItem(LOCAL_STORAGE.FILTER_CATEGORY, 'meal-plan')
      localStorage.setItem(LOCAL_STORAGE.FILTER_VALUE, 'pending')
    } else {
      if (weekNumber < 1) {
        if (isMaster === 'true' || isMaster === true) {
          localStorage.setItem(LOCAL_STORAGE.FILTER_CATEGORY, 'macros')
          localStorage.setItem(LOCAL_STORAGE.FILTER_VALUE, 'to-approve')
        } else {
          localStorage.setItem(LOCAL_STORAGE.FILTER_CATEGORY, 'progress')
          localStorage.setItem(LOCAL_STORAGE.FILTER_VALUE, 'all-users')
        }
      } else {
        localStorage.setItem(LOCAL_STORAGE.FILTER_CATEGORY, 'progress')
        localStorage.setItem(LOCAL_STORAGE.FILTER_VALUE, 'weekly-ready')
      }
    }
  }

  onSelectedBootcamp = (bootcampSelected, bootcamps, changeBootcamp) => {
    const newBootcampSelected = bootcamps.find((b) => b.program_id === bootcampSelected) || {}
    changeBootcamp(newBootcampSelected)
  }

  render() {
    const { traineeID, coachID } = this.props
    const query = getHomepageData(traineeID)

    return (
      <GlobalContext.Consumer>
        {({ bootcampActive, changeBootcamp, showMeeting, displayMeeting, showMessage, displayMessage }) => {
          return (
            <Query
              query={query}
              variables={{
                today: moment()
                  .local()
                  .format('YYYYMMDD'),
              }}
            >
              {({ error, data }) => {
                if (error) return <Result status="error" title="Error loading the dashboard" subTitle={error.message} />

                const bootcamps = _.get(data, 'program') || []
                const filteredBootcamps = filterBootcamps(bootcamps)
                const actualBootcamp = filteredBootcamps.find((b) => b.program_id === bootcampActive.id) || {}
                this.setFilterValue(bootcampActive)
                const weekNumber = moment().diff(moment(actualBootcamp.start_date), 'week')
                let weekNumberLabel
                if (weekNumber < 0) weekNumberLabel = 'Upcoming'
                else if (weekNumber === 0) weekNumberLabel = 'Enrollment'
                else if (weekNumber > actualBootcamp.duration) weekNumberLabel = 'Finished'
                else weekNumberLabel = `Week ${weekNumber}`

                return (
                  <Query query={getMaxCapacity} variables={{ coachId: coachID, programId: bootcampActive.id }}>
                    {({ error, loading, data }) => {
                      if (error) return <Result status="error" title="Error loading the dashboard" subTitle={error.message} />
                      if (loading) return <Result icon={<Spin />} title="Loading" />

                      const coach = _.get(data, 'coach[0]') || {}
                      const general_max = coach.max_students
                      const configuration =
                        coach.configuration && coach.configuration.length > 0 && coach.configuration[0].value ? coach.configuration[0] : undefined
                      const assigned = coach.assigned_aggregate.aggregate.count

                      const max_students = !!configuration ? configuration.value : general_max

                      let percentaje = assigned / max_students
                      let status
                      switch (true) {
                        case percentaje <= 0.8:
                          status = 'rgba(38, 172, 0, 0.3)'
                          break
                        case percentaje >= 0.81 && percentaje <= 0.9:
                          status = 'rgba(247, 171, 47, 0.5)'
                          break
                        case percentaje >= 0.91 && percentaje <= 0.99:
                          status = 'rgba(229, 61, 46, 0.5)'
                          break
                        default:
                          status = 'rgba(229, 61, 46, 0.8)'
                          break
                      }

                      return (
                        <div className="bootcamp-bar">
                          <Row gutter={[8, 8]} align="middle">
                            <Col xl={6} md={9} xs={24} className="with-divider select">
                              <div className="bootcamp-select">
                                <img src={BootcampBarIcon} alt="" />
                                <div className="select">
                                  <p className="label">Select your Bootcamp</p>
                                  <Select
                                    size="small"
                                    defaultValue={bootcampActive.id}
                                    onChange={(e) => this.onSelectedBootcamp(e, filteredBootcamps, changeBootcamp)}
                                  >
                                    {filteredBootcamps.map((bootcamp) => {
                                      return (
                                        <Option key={bootcamp.program_id} value={bootcamp.program_id}>
                                          {bootcamp.description}
                                        </Option>
                                      )
                                    })}
                                  </Select>
                                </div>
                              </div>
                            </Col>
                            {actualBootcamp && actualBootcamp.type === 'bootcamp' && (
                              <>
                                <Col xl={12} md={15} xs={24} className="with-divider">
                                  <div className="bootcamp-info">
                                    <div>
                                      <p className="label">Duration</p>
                                      <p>{actualBootcamp.duration} weeks</p>
                                    </div>
                                    <div>
                                      <p className="label">Start Day</p>
                                      <p>{actualBootcamp.start_date}</p>
                                    </div>
                                    <div>
                                      <p className="label">End Day</p>
                                      <p>{actualBootcamp.end_date}</p>
                                    </div>
                                    <div>
                                      <p className="label">Current Week</p>
                                      <p>{weekNumberLabel}</p>
                                    </div>
                                    <div>
                                      <p className="label">Students Count</p>
                                      <div className="capacity-progress">
                                        <Progress percent={percentaje * 100} showInfo={false} strokeColor={status} />
                                        <p>
                                          {assigned} / {max_students}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                <Col xl={3} md={12} xs={12} className="with-divider">
                                  <div className="meet">
                                    <p className="label">Students Communication</p>
                                    <p>
                                      <img src={SentMessageIcon} alt="Send Message" onClick={() => displayMessage(!showMessage)} />
                                      Send Message
                                    </p>
                                  </div>
                                </Col>
                                <Col xl={3} md={12} xs={12}>
                                  <div className="meet">
                                    <p className="label">Meet with Students</p>
                                    <p>
                                      <img src={GoogleMeetIcon} alt="Create meeting" onClick={() => displayMeeting(!showMeeting)} />
                                    </p>
                                  </div>
                                </Col>
                              </>
                            )}
                          </Row>
                        </div>
                      )
                    }}
                  </Query>
                )
              }}
            </Query>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default BootcampBar
