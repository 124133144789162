import React, { useState } from 'react'
import { Button, Drawer, Input, Select, DatePicker, TimePicker, Row, Col, notification } from 'antd'
import moment from 'moment-timezone'
import gql from 'graphql-tag'
import apolloClient from 'apolloClient.js'

const { Option } = Select
const { TextArea } = Input

const getStudents = gql`
  query getStudents($coachId: uuid!, $programId: uuid!, $levels: [Int!]!) {
    trainee_program_coach(
      where: { _and: [{ coach_id: { _eq: $coachId } }, { program_id: { _eq: $programId } }, { enrollment: { level: { _in: $levels } } }] }
    ) {
      trainee {
        user {
          email
        }
      }
    }
  }
`

export default (props) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [date, setDate] = useState('')
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [guestLevel, setGuestLevel] = useState(['1', '2', '3'])
  const [timeZone, setTimeZone] = useState(moment.tz.guess())
  const timeZones = moment.tz.names()

  const createMeetingLink = async () => {
    const isValid = !!title && !!date && !!start && !!end && !!timeZone && !!guestLevel
    if (!isValid) {
      alert('Please complete all fields!')
      return
    }

    const startDate = moment(date + ' ' + start).format('YYYYMMDDTHHmmss')
    const endDate = moment(date + ' ' + end).format('YYYYMMDDTHHmmss')

    let link = 'https://calendar.google.com/calendar/render?action=TEMPLATE'
    link += '&text=' + title
    link += '&dates=' + startDate + '/' + endDate
    link += '&ctz=' + timeZone
    // link += '&recur=RRULE:FREQ=WEEKLY;UNTIL=' + moment(localStorage.getItem('BOOTCAMP_CLOSE_DATE')).format('YYYYMMDDTHHmmss')
    link += '&details=' + description
    link += '&location=&trp=true'
    link += '&add='

    await apolloClient
      .query({
        query: getStudents,
        variables: {
          coachId: localStorage.getItem('COACH_ID'),
          programId: localStorage.getItem('BOOTCAMP_ID'),
          levels: guestLevel.map((gl) => parseInt(gl)),
        },
      })
      .then((response) => {
        if (response.errors) {
          throw new Error('Connection Error')
        } else {
          link += response.data.trainee_program_coach.map((t) => t.trainee.user.email).join(',')
          window.open(link)
          props.displayMeeting(false)
          notification.success({ message: 'Your meeting was successfully scheduled! You can review and edit it in your Calendar.' })
        }
      })
      .catch((error) => {
        console.log('error :>> ', error)
      })
  }

  const isValidDate = (current) => {
    return (
      current &&
      (current < moment(localStorage.getItem('BOOTCAMP_START_DATE')) || current > moment(localStorage.getItem('BOOTCAMP_END_DATE')).add(1, 'day'))
    )
  }

  return (
    <Drawer
      title="Meet with Students"
      placement="right"
      className="google-meet"
      width="650px"
      onClose={() => props.displayMeeting(false)}
      visible={props.showMeeting}
      getContainer={false}
      style={{ position: 'absolute' }}
    >
      <Row gutter={[32, 16]}>
        <Col span={24}>
          <Input className="meet-title" placeholder="Add Title" onChange={(e) => setTitle(e.target.value)} />
        </Col>
        <Col span={12}>
          <label>Date</label>
          <DatePicker
            onChange={(date) => {
              setDate(date ? date.format('YYYY-MM-DD') : date)
            }}
            disabledDate={isValidDate}
          />
        </Col>
        <Col span={12}>
          <Row>
            <label>Time</label>
            <TimePicker
              use12Hours
              format="h:mm a"
              onChange={(time) => {
                setStart(time ? time.format('HH:mm') : time)
              }}
            />{' '}
            to
            <TimePicker
              use12Hours
              format="h:mm a"
              onChange={(time) => {
                setEnd(time ? time.format('HH:mm') : time)
              }}
            />
          </Row>
        </Col>
        <Col span={12}>
          <label>Time Zone</label>
          <Select placeholder="Select timezone" onChange={(tz) => setTimeZone(tz)} defaultValue={moment.tz.guess()} showSearch>
            {timeZones.map((tz) => {
              return (
                <Option key={tz} value={tz}>
                  {tz}
                </Option>
              )
            })}
          </Select>
        </Col>
        <Col span={12}>
          <label>Frequency</label>
          <Select placeholder="Coming Soon!" disabled>
            <Option value="">Coming soon</Option>
          </Select>
        </Col>
        <Col span={24}>
          <label>Guests</label>
          <Select mode="multiple" placeholder="Please select" defaultValue={['1', '2', '3']} onChange={(e) => setGuestLevel(e)}>
            <Option value="1">Level 1</Option>
            <Option value="2">Level 2</Option>
            <Option value="3">Level 3</Option>
          </Select>
        </Col>
        <Col span={24}>
          <label>Description</label>
          <TextArea rows={8} onChange={(e) => setDescription(e.target.value)} />
        </Col>
      </Row>

      <div className="footer">
        <Button size="small" onClick={() => props.displayMeeting(false)}>
          Cancel
        </Button>
        &nbsp;
        <Button size="small" type="primary" onClick={() => createMeetingLink()}>
          Schedule
        </Button>
      </div>
    </Drawer>
  )
}
