import React, { useState, useRef } from 'react'
import { Drawer, Row, Col, Select, Button, notification } from 'antd'
import TextEditor from 'components/TextEditor'
import apolloClient from 'apolloClient.js'
import gql from 'graphql-tag'

const { Option } = Select

const getStudents = gql`
  query getStudents($coachId: uuid!, $programId: uuid!, $levels: [Int!]!) {
    trainee_program_coach(
      where: { _and: [{ coach_id: { _eq: $coachId } }, { program_id: { _eq: $programId } }, { enrollment: { level: { _in: $levels } } }] }
    ) {
      thread_id
      trainee {
        user {
          email
        }
      }
    }
  }
`

const sendMessages = gql`
  mutation sendMessages($data: [message_insert_input!]!) {
    insert_message(objects: $data) {
      returning {
        timestamp
        body
      }
    }
  }
`

const StudentMessage = (props) => {
  const [levelsSelected, setLevelsSelected] = useState(['1', '2', '3'])
  const textEditorRef = useRef(null)

  const sendMessage = async (message, callback) => {
    console.log(levelsSelected)
    if (levelsSelected.length > 0) {
      const userID = localStorage.getItem('USER_ID')
      let students = []
      await apolloClient
        .query({
          query: getStudents,
          variables: {
            coachId: localStorage.getItem('COACH_ID'),
            programId: localStorage.getItem('BOOTCAMP_ID'),
            levels: levelsSelected.map((sl) => parseInt(sl)),
          },
        })
        .then(async (resp) => {
          if (resp.errors) {
            throw new Error('Connection Error')
          } else {
            students = resp.data.trainee_program_coach
            let data = students.map((st) => {
              return { thread_id: st.thread_id, body: message, sender_id: userID }
            })
            await apolloClient
              .mutate({
                mutation: sendMessages,
                variables: {
                  data,
                },
              })
              .then((response) => {
                if (response.errors) {
                  throw new Error('Connection Error')
                } else {
                  props.displayMessage(false)
                  notification.success({ message: 'Your message was successfully sent.' })
                  callback()
                }
              })
              .catch((error) => {
                notification.error({ message: 'The global message could not be sent', description: `${error}` })
              })
          }
        })
        .catch(console.warn)
    }
  }

  const handleCancelMessage = () => {
    props.displayMessage(false)
    localStorage.removeItem(`ATTACHMENTS`)
    localStorage.removeItem(`MESSAGE`)
  }

  return (
    <div>
      <Drawer
        title="Students Communication - Portal Message"
        width="650px"
        placement="right"
        onClose={() => props.displayMessage(false)}
        visible={props.showMessage}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <Row gutter={[32, 16]}>
          <Col span={24}>
            <label>Guests</label>
            <Select mode="multiple" placeholder="Please select" defaultValue={['1', '2', '3']} onChange={(e) => setLevelsSelected(e)}>
              <Option value="1">Level 1</Option>
              <Option value="2">Level 2</Option>
              <Option value="3">Level 3</Option>
            </Select>
          </Col>
          <Col span={24}>
            <label>Message</label>
            <TextEditor send={sendMessage} ref={textEditorRef} />
          </Col>
        </Row>
        <div className="footer">
          <Button size="small" onClick={handleCancelMessage}>
            Cancel
          </Button>
          &nbsp;
          <Button size="small" type="primary" onClick={() => textEditorRef.current.handleSendMessage()}>
            Send
          </Button>
        </div>
      </Drawer>
    </div>
  )
}

export default StudentMessage
