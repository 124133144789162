import { InputNumber } from "antd";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

export default styled(InputNumber)`
  &.ant-input-number {
    font-family: "Montserrat", sans-serif;
    height: 35px;
    line-height: 35px;
    &:hover {
      border-color: #5d5d5d;
    }
    &:focus {
      border-color: #5d5d5d;
      box-shadow: 0 0 3px 0 #5d5d5d;
    }
  }
  & + span {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;
    font-size: 9px;
    text-transform: capitalize;
  }
  ${({ custom }) => {
    if (custom === "short") {
      return css`
        &.ant-input {
          max-width: 130px;
          &:hover {
            border-color: #ff4613;
          }
          &:focus {
            border-color: #ff4613;
            box-shadow: 0 0 3px 0 #ff4613;
          }
        }
      `;
    }
    if (custom === "veryShort") {
      return css`
        &.ant-input {
          max-width: 76px;
        }
      `;
    }
  }}
`;
