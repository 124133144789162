import { TableRow, TableHead, TableCell } from 'styled'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const ModalTableRow = styled(TableRow)`
  ${({ custom }) => {
    if (custom === 'odd') {
      return css`
        && {
          background-color: #f6f6f6;
        }
      `
    }
  }}
`
export const ModalTableHead = styled(TableHead)`
  padding-bottom: 1px;
  color: #383838;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  vertical-align: middle;
  &:first-of-type {
    width: 100px;
    padding-right: 40px;
    text-align: right;
  }
  &:last-of-type {
    width: 100px;
    padding-left: 20px;
  }
`
export const ModalTableCell = styled(TableCell)`
  padding: 0 5px;
  color: #5d5d5d;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  &:first-of-type {
    width: 100px;
    padding-right: 40px;
    text-align: right;
  }
  ${({ custom }) => {
    if (custom === 'week-info') {
      return css`
        && {
          padding-top: 10px;
          padding-bottom: 1px;
          font-size: 11px;
          font-weight: 600;
          color: #383838;
          border-bottom: 1px solid #f6f6f6;
          &:first-of-type {
            font-size: 12px;
          }
        }
      `
    }
  }}
`
export const Unit = styled.div`
  width: 22px;
  height: 22px;
  margin: -10px auto 10px;
  border-radius: 50%;
  border: 1px solid #979797;
  font-size: 11px;
  text-align: center;
  color: #959595;
`
