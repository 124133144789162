import React from 'react'
import iconShownCompleted from 'images/icon-shown-completed.svg'
import iconShownMissing from 'images/icon-shown-missing.svg'
import iconShownReady from 'images/icon-shown-ready.svg'
import iconShowToReview from 'images/icon_show_to_review.png'
import iconShowInProgress from 'images/icon_show_in_progress.png'
import iconShowToApprove from 'images/icon_show_to_approve.png'
import iconShowApproved from 'images/icon_show_approved.png'
import iconMealPlanner from 'images/icon_show_meal_plan.png'
import { Icon } from 'antd'

const baseOptions = [
  {
    value: 'progress',
    label: (
      <div className="category-filter">
        <Icon type="idcard" theme="filled" />
        <span className="filter-label">Progress</span>
      </div>
    ),
    children: [
      {
        value: 'all-users',
        label: (
          <div>
            <Icon type="user" className="all-users-icon" />
            <span className="filter-label">All Users</span>
          </div>
        ),
      },
      {
        value: 'weekly-completed',
        label: (
          <div>
            <img className="filter-icon" src={iconShownCompleted} alt="" />
            <span className="filter-label">Weekly Review Completed</span>
          </div>
        ),
      },
      {
        value: 'weekly-ready',
        label: (
          <div>
            <img className="filter-icon" src={iconShownReady} alt="" />
            <span className="filter-label">Ready for Weekly Review</span>
          </div>
        ),
      },
      {
        value: 'missing-info',
        label: (
          <div>
            <img className="filter-icon" src={iconShownMissing} alt="" />
            <span className="filter-label">Missing Info</span>
          </div>
        ),
      },
    ],
  },
  {
    value: 'macros',
    label: (
      <div className="category-filter">
        <Icon type="idcard" theme="filled" />
        <span className="filter-label">Macros</span>
      </div>
    ),
    children: [
      {
        order: 1,
        value: 'all-users',
        label: (
          <div>
            <Icon type="user" className="all-users-icon" />
            <span className="filter-label">All Users</span>
          </div>
        ),
      },
      {
        order: 2,
        value: 'to-review',
        label: (
          <div>
            <img className="filter-icon" src={iconShowToReview} alt="" />
            <span className="filter-label">To Review</span>
          </div>
        ),
      },
      {
        order: 7,
        value: 'approved',
        label: (
          <div>
            <img className="filter-icon" src={iconShowApproved} alt="" />
            <span className="filter-label">Approved</span>
          </div>
        ),
      },
    ],
  },
]

const mealPlanOptions = [
  {
    value: 'meal-plan',
    label: (
      <div className="category-filter">
        <Icon type="idcard" theme="filled" />
        <span className="filter-label">Meal Plan</span>
      </div>
    ),
    children: [
      {
        value: 'pending',
        label: (
          <div>
            <img className="filter-icon" src={iconShowInProgress} alt="" />
            <span className="filter-label">Pending</span>
          </div>
        ),
      },
      {
        value: 'approved',
        label: (
          <div>
            <img className="filter-icon" src={iconMealPlanner} alt="" />
            <span className="filter-label">Approved</span>
          </div>
        ),
      },
    ],
  },
]

const macrosReview = [
  {
    value: 'macros-review',
    label: (
      <div className="category-filter">
        <Icon type="idcard" theme="filled" />
        <span className="filter-label">Macros Review</span>
      </div>
    ),
    children: [
      {
        order: 1,
        value: 'to-review',
        label: (
          <div>
            <img className="filter-icon" src={iconShowToReview} alt="" />
            <span className="filter-label">To Review</span>
          </div>
        ),
      },
      {
        order: 2,
        value: 'in-progress',
        label: (
          <div>
            <img className="filter-icon" src={iconShowInProgress} alt="" />
            <span className="filter-label">In Progress</span>
          </div>
        ),
      },
      {
        order: 3,
        value: 'approved',
        label: (
          <div>
            <img className="filter-icon" src={iconShowApproved} alt="" />
            <span className="filter-label">Approved</span>
          </div>
        ),
      },
    ],
  },
]

const adminOptions = [
  {
    order: 5,
    value: 'to-approve',
    label: (
      <div>
        <img className="filter-icon" src={iconShowToApprove} alt="" />
        <span className="filter-label">To Approve (Assigned)</span>
      </div>
    ),
  },
  {
    order: 6,
    value: 'to-approve-others',
    label: (
      <div>
        <img className="filter-icon" src={iconShowToApprove} alt="" />
        <span className="filter-label">To Approve (All Master Coaches)</span>
      </div>
    ),
  },
]

export const getOptions = (coachIsMaster, coachIsMealPlanner, programType) => {
  let options = baseOptions

  if (coachIsMaster) {
    let macros = []
    macros = options.find((o) => o.value === 'macros')
    macros.children = [...macros.children, ...adminOptions]
    macros.children = [...new Set(macros.children)]
    macros.children.sort((a, b) => a.order - b.order)
    if (programType === 'macros') {
      options = macrosReview
    }
    if (coachIsMealPlanner) {
      if (programType === 'meal_plan') {
        options = mealPlanOptions
      } else if (programType === 'macros') {
        options = macrosReview
      } else {
        options = [...options, ...mealPlanOptions]
      }
    }
  } else if (coachIsMealPlanner) {
    if (programType === 'meal_plan') {
      options = mealPlanOptions
    } else if (programType === 'macros') {
      options = macrosReview
    } else {
      options = [...options, ...mealPlanOptions]
    }
  }

  return options
}
