import React from 'react'

const DashboardContext = React.createContext({
  bootcamps: [],
  enrollment: {},
  macros: [],
  student: {},
  coach: {},
  activityLevelChoosen: '',
  units: 'lbs/in',
  totalBootcamps: 0,
})

export default DashboardContext
