import gql from 'graphql-tag'

const measurementFields = `
  neck
  chest
  shoulder
  arm
  forearm
  belly_waist
  narrow_waist
  hip
  thigh
  calf
`

const enrollmentFields = `
  ${measurementFields}
  id
  metric
  height
  weight
  bf_pct
  bf_method
  current_goal
  level
  sub_level
  activity_level
  med_cond
  reprod_health
  desord_eating
  prescription_med
  diet_supliments
  diet
  has_diet 
  type_of_diet
  injuries
  created
  prot
  carbs
  need_coach_approval
  fat
  medical_conditions{
    medical_condition_id
  }
  photo_front_url
  photo_side_url
  photo_back_url
`

const dailyFields = `
  fat
  carbs
  prot
  notes
  for_date
  day_type
  weight
  bf_pct
`

export const getStudentDashboardData = gql`
  query getStudentDashboardData($traineeID: uuid!, $programId: uuid!, $enrollmentDate: String!, $enrollmentDateAux: date!, $startDate: String!, $endDate: String!) {
    activity_level{
      id
      label
    }
    configuration(where: { id: { _in: ["type_of_diets", "body_fat_methods", "goal"] } }){
      id
      value
    }
    medical_condition{
      id
      label
      need_macro_approve
      category
    }
    trainee(where: { id: { _eq: $traineeID } }) {
      id
      total_past_bootcamp
      user {
        first_name
        last_name
        picture
        email
        birthday
        gender
        units
      }
      total_program: selected_aggregate(where: {is_active: {_eq: true}}) {
        aggregate {
          count
        }
      }
      currentProgram: selected(where: { program_id: { _eq: $programId } }) {
        program_id
        extras
        thread_id
        student_can_reply
        coach {
          user {
            first_name
            last_name
            picture
          }
        }
        enrollment {
          ${enrollmentFields}
        }
        program{
          type
        }
      }
      macros(where: { program_id: { _eq: $programId } }) {
        id
        subscription_id
        status
        body_fat_percent
        lean_body_mass
        use_lean_gains
        protein_intake_override
        recommended_deficit
        calorie_intake_pre_health
        deficit_override
        is_last_bootcamp_copy
        copy_from_bootcamp
        macro_tables {
          macro_id
          type
          protein_base_grams
          protein_pre_workout_grams
          protein_total_grams
          protein_kcal_gram
          protein_daily_kcals
          protein_percent_calories
          carbs_base_grams
          carbs_pre_workout_grams
          carbs_total_grams
          carbs_kcal_gram
          carbs_daily_kcals
          carbs_percent_calories
          fat_base_grams
          fat_pre_workout_grams
          fat_total_grams
          fat_kcal_gram
          fat_daily_kcals
          fat_percent_calories
          total_daily_kcals
          total_percent_calories
        }
        macro_notes {
          note
        }
      }
      last_bootcamp_macros: macros(
        where: { 
          _and: [ 
            { program_id: { _neq: $programId } }
            { program: { start_date: { _lt: $enrollmentDateAux } } }
          ]
        }
        order_by: {created: desc}, limit: 1
      ) {
        trainee_id
        program_id
        status
        edited
        program {
          description
        }
        macro_tables {
          macro_id
          type
          carbs_base_grams
          carbs_daily_kcals
          carbs_kcal_gram
          carbs_percent_calories
          carbs_pre_workout_grams
          carbs_total_grams
          fat_base_grams
          fat_daily_kcals
          fat_kcal_gram
          fat_percent_calories
          fat_pre_workout_grams
          fat_total_grams
          protein_base_grams
          protein_daily_kcals
          protein_kcal_gram
          protein_percent_calories
          protein_pre_workout_grams
          protein_total_grams
          total_daily_kcals
          total_percent_calories
          trainee_id
        }
      }
      mealPlan: selected(where: { program_id: { _eq: $programId } }){
        meal_plan{
          status
          meals_per_day
          heat_level
          cuisine_style
          has_sensitivities
          ingredient_sensitivities
          ingredient_category_sensitivities
          ingredient_dislikes
          recipes_favorites
          user_notes
          url
        }
      }
      trainee_details: trainee_details(where: {program_id: {_eq: $programId}}){
        carbs
        fat
        prot
        need_coach_approval
      }
      pictures(where: {  _and:[ { for_date: {_gte: $enrollmentDate} }, { for_date: {_lte: $endDate} } ] }) {
        front_url
        side_url
        back_url
        for_date
      }
      dailies: macro(where: { _and:[ { for_date: {_gte: $startDate } }, { for_date: {_lte: $endDate} } ] }) {
        ${dailyFields}
      }
      weeklies: measurement(where: { _and:[ { for_date: {_gte: $startDate} }, { for_date: {_lte: $endDate} } ] }) {
        ${measurementFields}
        status
        macro_adherence
        training_adherence
        sleep_issues
        period
        hunger_issues
        fatigue
        lethargy
        for_date
        stress
      }
      firstDaily: macro(limit: 1, order_by: { for_date: asc }, where: { _and:[ { for_date: { _neq: "Invalid date" } }, { for_date: { _lt: $startDate } } ]  }){
        ${dailyFields}
      }
      firstWeekly: measurement(limit: 1, order_by: { for_date: asc }, where: { for_date: { _neq: "Invalid date" }  }){
        ${measurementFields}
        for_date
      }
      firstEnrollment: trainee_details(limit: 1, order_by: { created: asc }){
        ${enrollmentFields}
      }
    }
  }
`

export const completeWeek = gql`
  mutation completeWeek($traineeId: uuid!, $forDate: String!, $data: measurement_set_input) {
    update_measurement(where: { _and: [{ trainee_id: { _eq: $traineeId } }, { for_date: { _eq: $forDate } }] }, _set: $data) {
      returning {
        status
      }
    }
  }
`
