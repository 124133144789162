import ApolloClient from 'apollo-boost'
import { getCookie } from 'tiny-cookie'
import { renewToken } from 'auth'
import { LOCAL_STORAGE, COOKIE, DB } from 'constants.js'
import * as Sentry from '@sentry/browser'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(() => resolve, ms))
}

class ApolloError extends Error {
  constructor(args) {
    super(args)
    this.name = 'ApolloError'
  }
}

export default new ApolloClient({
  uri: DB,
  headers: {
    'Content-Type': 'application/json',
  },
  onError: ({ graphQLErrors, networkError, operation }) => {
    Sentry.withScope((scope) => {
      let error = 'Error on Apollo Operation'
      if (!!networkError) {
        scope.setExtra('Apollo Network Errors', networkError)
      }
      if (!!graphQLErrors) {
        scope.setExtra('Apollo GraphQL Errors', graphQLErrors)
      }
      if (!!operation) {
        scope.setExtra('Apollo Operation', operation)
      }
      Sentry.captureException(new ApolloError(error))
    })
  },
  request: async (operation) => {
    if (!getCookie(COOKIE.USER_TOKEN_EXPIRE_TIME)) {
      if (localStorage.getItem('REFRESHING_TOKEN')) {
        await sleep(1000)
      }

      localStorage.setItem('REFRESHING_TOKEN', true)
      await renewToken().then((token) => {
        localStorage.removeItem('REFRESHING_TOKEN')
        operation.setContext({
          headers: {
            Authorization: token,
          },
        })
      })
    } else {
      operation.setContext({
        headers: {
          Authorization: getCookie(COOKIE.USER_TOKEN),
        },
      })
    }
  },
  clientState: {
    defaults: {
      User: {
        id: localStorage.getItem(LOCAL_STORAGE.USER_ID) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.USER_ID),
        ex_user_id: localStorage.getItem(LOCAL_STORAGE.EX_USER_ID) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.EX_USER_ID),
        coach_id: localStorage.getItem(LOCAL_STORAGE.COACH_ID) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.COACH_ID),
        as_coach: localStorage.getItem(LOCAL_STORAGE.AS_COACH) === 'false' ? false : localStorage.getItem(LOCAL_STORAGE.AS_COACH),
        nickname: localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.USER_NICKNAME),
        picture: localStorage.getItem(LOCAL_STORAGE.USER_PICTURE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.USER_PICTURE),
        roles: !!localStorage.getItem(LOCAL_STORAGE.USER_ROLES) ? localStorage.getItem(LOCAL_STORAGE.USER_ROLES).split(',') : [],
        __typename: 'User',
      },
      Bootcamp: {
        id: localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_ID) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_ID),
        start_date:
          localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_START_DATE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_START_DATE),
        end_date: localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_END_DATE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_END_DATE),
        close_date:
          localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_CLOSE_DATE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_CLOSE_DATE),
        description:
          localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_DESCRIPTION) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_DESCRIPTION),
        program_type: localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_TYPE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.BOOTCAMP_TYPE),
        access_date: localStorage.getItem(LOCAL_STORAGE.ACCESS_DATE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.ACCESS_DATE),
        __typename: 'Bootcamp',
      },
      App: {
        loading: false,
        __typename: 'Application',
      },
      Admin: {
        id: localStorage.getItem(LOCAL_STORAGE.ADMIN_ID) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.ADMIN_ID),
        nickname: localStorage.getItem(LOCAL_STORAGE.ADMIN_NICKNAME) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.ADMIN_NICKNAME),
        picture: localStorage.getItem(LOCAL_STORAGE.ADMIN_PICTURE) === 'null' ? null : localStorage.getItem(LOCAL_STORAGE.ADMIN_PICTURE),
        roles: !!localStorage.getItem(LOCAL_STORAGE.ADMIN_ROLES) ? localStorage.getItem(LOCAL_STORAGE.ADMIN_ROLES).split(',') : [],
        __typename: 'Admin',
      },
    },
    resolver: {},
  },
})
