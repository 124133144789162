import styled from '@emotion/styled'

export const PhotoContainer = styled.div`
  .photo {
    height: 300px;
    display: flex;
    justify-content: center;
    align-content: center;
    img {
      cursor: pointer;
      display: block;
      margin: auto auto;
      max-width: 100%;
      max-height: 100%;
    }

    @media screen and (max-width: 575.9px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-height: max-content !important;
    }
  }

  .ant-select {
    margin: 0 auto;
    margin-top: 5px;
    display: block;
    width: 90%;
  }
`
