import React, { Component, Fragment } from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import _ from 'lodash'
import showdown from 'showdown'
import Waypoint from 'react-intersection-visible'
import apolloClient from 'apolloClient'
import { Popconfirm } from 'antd'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { Root, MessageList, Message, Answer, Date, ReadBlock } from './Messages.styled'

const converter = new showdown.Converter()

const readMessage = gql`
  mutation readMessage($messageId: uuid!) {
    update_message(where: { id: { _eq: $messageId } }, _set: { read_coach: true }) {
      returning {
        id
      }
    }
  }
`

const deleteMessage = gql`
  mutation deleteMessage($messageId: uuid!) {
    delete_message(where: { id: { _eq: $messageId } }) {
      affected_rows
    }
  }
`

const createMarkup = (body) => {
  return { __html: body }
}

class Messages extends Component {
  rootElement = React.createRef()

  componentDidMount() {
    if (!!_.get(this.rootElement, 'current')) {
      const newMessage = this.rootElement.current
      newMessage.scrollTop = newMessage.scrollHeight
    }
  }

  componentDidUpdate() {
    if (!!_.get(this.rootElement, 'current')) {
      const newMessage = this.rootElement.current
      newMessage.scrollTop = newMessage.scrollHeight
    }
  }

  readMessages = (messageId) => {
    apolloClient.mutate({
      mutation: readMessage,
      variables: {
        messageId,
      },
    })
  }

  deleteMessage = (messageId) => {
    apolloClient.mutate({
      mutation: deleteMessage,
      variables: {
        messageId,
      },
      update: () => {
        this.props.refetch()
      },
    })
  }

  getClasses = (senderId, read) => {
    if (senderId !== this.props.userID) {
      return `trainee ${!read ? 'unread' : ''}`
    }

    return 'coach'
  }

  scrollToUnread = (element) => {
    if (!!_.get(this.rootElement, 'current') && !!_.get(element, 'offsetTop')) {
      const newMessage = this.rootElement.current
      newMessage.scrollTop = newMessage.scrollHeight
    }
  }

  render() {
    const { messages, userID } = this.props
    return (
      <Root ref={this.rootElement}>
        <MessageList>
          {messages.map((message) => {
            return (
              <Fragment key={message.id}>
                {userID !== message.sender_id && !message.read_coach && (
                  <Waypoint>
                    <ReadBlock ref={this.scrollToUnread} />
                  </Waypoint>
                )}
                <Message className={this.getClasses(message.sender_id, message.read_coach)}>
                  <Answer dangerouslySetInnerHTML={createMarkup(converter.makeHtml(message.body))} />
                  <div className="information">
                    {message.sender_id === userID && (
                      <Popconfirm
                        title={
                          <p>
                            Are you sure you want to <br />
                            <b>delete</b> this message?
                          </p>
                        }
                        onConfirm={() => this.deleteMessage(message.id)}
                        okText="Yes"
                        okType="primary block"
                        cancelText="Cancel"
                        icon={null}
                        placement="bottom"
                      >
                        <DeleteIcon id={`delete-${message.id}`} className="delete" />
                      </Popconfirm>
                    )}
                    <Date>
                      {moment(message.timestamp)
                        .local()
                        .format('MMMM DD YYYY h:mm a')}
                    </Date>
                  </div>
                </Message>
              </Fragment>
            )
          })}
        </MessageList>
      </Root>
    )
  }
}

export default Messages
