import React from 'react'
import { Popover, Icon } from 'antd'
import { Widget } from '@uploadcare/react-widget'
import { StyledButton, GifButton, TextEditorContainer, GiftSelector } from './ClientChat.styled'
import ReactQuill, { Quill } from 'react-quill'
import quillEmoji from 'quill-emoji'
import Picker from 'react-giphy-component'
import sendIcon from 'images/send_icon.png'
import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
  },
  true,
)

const gifContainer = (onSelectGif) => (
  <GiftSelector>
    <Picker
      {...{
        apiKey: process.env.REACT_APP_GIPHY_KEY,
        modal: true,
        placeholder: 'Search GIPHY',
        imagePlaceholderColor: '#FF6B00',
        loader: <Icon type="loading" />,
        onSelected: onSelectGif,
        children: <Icon className="search-icon" type="search" />,
      }}
    />
  </GiftSelector>
)

const fileSizeReadable = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

class TextEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editorHtml: '',
      attachments: [],
      gifVisible: false,
    }
    this.quillRef = React.createRef()
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(html) {
    const { currentTrainee } = this.props
    localStorage.setItem(`MESSAGE_${currentTrainee}`, html)
    this.setState({ editorHtml: html }, () => {
      if (this.quillRef) {
        if (this.quillRef.current) {
          if (this.quillRef.current.editingArea) {
            let editorChild = this.quillRef.current.editingArea.getElementsByClassName('ql-editor')
            if (editorChild && editorChild.length > 0) {
              editorChild[0].removeAttribute('data-gramm')
            }
          }
        }
      }
    })
  }

  handleGifSelect = (gif) => {
    const { onSend, thread_id, userID, lastMessageDate, refetch } = this.props
    let gifVersion = gif.downsized
    if (!!!gifVersion.url) {
      gifVersion = gif.downsized_large
    }
    let width = gifVersion.width
    if (width > 250) width = 250
    const message = `<img width="${width}" src="${gifVersion.url}" />`
    if (typeof onSend === 'function') {
      this.setState({ gifVisible: false }, () => {
        onSend(message, thread_id, lastMessageDate, userID, refetch)
      })
    }
  }

  handleGifVisibleChange = (visible) => {
    this.setState({ gifVisible: visible })
  }

  handleFileSelect = (file) => {
    if (file) {
      file.done((fileInfo) => {
        const { currentTrainee } = this.props
        const attachments = [fileInfo]
        localStorage.setItem(`ATTACHMENTS_${currentTrainee}`, JSON.stringify(attachments))
        this.setState({ attachments })
      })
    }
  }

  deleteAttachment = (uuid) => {
    const { attachments } = this.state
    const { currentTrainee } = this.props
    const newAttachments = attachments.filter((a) => a.uuid !== uuid)
    localStorage.setItem(`ATTACHMENTS_${currentTrainee}`, JSON.stringify(newAttachments))
    this.setState({ attachments: newAttachments })
  }

  handleSendMessage = () => {
    const { attachments, editorHtml } = this.state
    const { onSend, currentTrainee, thread_id, userID, lastMessageDate, refetch } = this.props
    if (typeof onSend === 'function') {
      if ((!!!editorHtml || editorHtml.replace(/<(.|\n)*?>/g, '').trim().length === 0) && attachments.length === 0) {
        alert('Write something first...')
      } else {
        let message = editorHtml
        message += '<div class="attachments">'
        attachments.forEach((attach) => {
          const sizeReadable = fileSizeReadable(attach.size)
          if (attach.isImage) {
            message += '<div class="attachment-item image">'
            message += `<img width="250" src="${attach.cdnUrl}" />`
            message += `<p><a href="${attach.cdnUrl}" target="_blank">${attach.name}</a></p>`
            message += `<p>${sizeReadable}</p>`
            message += '</div>'
          } else {
            message += '<div class="attachment-item file">'
            message += '<div class="preview">'
            message += `<img src="/icons/icon-file.png" alt="" />`
            message += '</div>'
            message += '<div class="detail">'
            message += `<p><a href="${attach.cdnUrl}" target="_blank">${attach.name}</a></p>`
            message += `<p>${sizeReadable}</p>`
            message += '</div>'
            message += '</div>'
          }
        })
        message += '</div>'
        onSend(message, thread_id, lastMessageDate, userID, refetch, () => {
          localStorage.removeItem(`MESSAGE_${currentTrainee}`)
          localStorage.removeItem(`ATTACHMENTS_${currentTrainee}`)
          this.setState({ editorHtml: '', attachments: [] })
        })
      }
    }
  }

  componentDidMount() {
    const { currentTrainee } = this.props

    if (this.quillRef) {
      if (this.quillRef.current) {
        if (this.quillRef.current.editingArea) {
          let editorChild = this.quillRef.current.editingArea.getElementsByClassName('ql-editor')
          if (editorChild && editorChild.length > 0) {
            editorChild[0].removeAttribute('data-gramm')
          }
        }
      }
    }

    const message = localStorage.getItem(`MESSAGE_${currentTrainee}`)
    const attachments = localStorage.getItem(`ATTACHMENTS_${currentTrainee}`)
    this.setState({
      editorHtml: !!message ? message : '',
      attachments: !!attachments ? JSON.parse(attachments) : [],
    })
  }

  render() {
    const { student } = this.props
    const { editorHtml, attachments, gifVisible } = this.state
    return (
      <TextEditorContainer>
        <div className="wrap-editor">
          <ReactQuill
            placeholder={`Type a message for @${student.first_name}`}
            onChange={this.handleChange}
            modules={TextEditor.modules}
            formats={TextEditor.formats}
            value={editorHtml}
            ref={this.quillRef}
          />
          {attachments && attachments.length > 0 && (
            <div className="attachments">
              {attachments.map((item) => {
                const sizeReadable = fileSizeReadable(item.size)
                return (
                  <div className="attachment-item" key={item.uuid}>
                    <div className={`preview ${item.isImage ? 'image' : 'icon'}`}>
                      {item.isImage && <img src={item.cdnUrl} alt="" />}
                      {!item.isImage && <Icon type="file" theme="filled" />}
                    </div>
                    <div className="detail">
                      <p className="name">{item.name}</p>
                      <p className="size">{sizeReadable}</p>
                    </div>
                    <div className="actions">
                      <Icon onClick={() => this.deleteAttachment(item.uuid)} type="delete" theme="filled" />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <div id="quill-toolbar" className="buttons-container">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-link" />
          <select className="ql-color">
            <option value="#E53D2F" />
            <option value="#6FCF97" />
            <option value="#2EA3F2" />
            <option value="#FF6B00" />
            <option value="rgba(0, 0, 0, 0.65)" />
          </select>
          <select className="ql-align">
            <option value="" />
            <option value="center" />
            <option value="right" />
          </select>
          <button className="ql-list" value="bullet" />
          <button className="ql-video" />
          <Widget
            publicKey={process.env.REACT_APP_UPLOADCARE_KEY}
            id="file"
            name="file"
            tabs="file url gdrive dropbox"
            previewStep="true"
            onFileSelect={this.handleFileSelect}
          />
          <button className="ql-emoji" />
          <Popover
            content={gifContainer(this.handleGifSelect)}
            overlayClassName="chat-container"
            visible={gifVisible}
            trigger="click"
            onVisibleChange={this.handleGifVisibleChange}
          >
            <GifButton>GIF</GifButton>
          </Popover>
          <button className="ql-clean" />
          <StyledButton onClick={this.handleSendMessage}>
            <img src={sendIcon} alt="send_icon" />
          </StyledButton>
        </div>
      </TextEditorContainer>
    )
  }
}

TextEditor.modules = {
  toolbar: {
    container: '#quill-toolbar',
  },
  'emoji-toolbar': true,
}

TextEditor.formats = ['header', 'bold', 'italic', 'underline', 'list', 'link', 'align', 'emoji', 'color', 'video']

export default TextEditor
