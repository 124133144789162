import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from 'components/PrivateRoute'
import Login from 'components/Login'
import Logout from 'components/Logout'
import Personal from 'components/Personal'
import WelcomeLetter from 'views/WelcomeLetter'
import Homepage from 'components/Homepage'
import NotFound from 'components/NotFound'
import Impersonate from 'views/Security/Impersonate'

const App = () => (
  <Router>
    <PrivateRoute path="/:coachID" component={Homepage} />
    <PrivateRoute path="/:coachID/:traineeID" component={Homepage} />
    <PrivateRoute path="/personal" component={Personal} />
    <PrivateRoute path="/welcome-letter" component={WelcomeLetter} />
    <Login path="/" />
    <Login path="/login" />
    <Logout path="/logout" />
    <Impersonate path="/sso" />
    <NotFound default />
  </Router>
)

export default App
