import moment from 'moment'
import _ from 'lodash'

export const getSuffix = (number) => {
  let suffix = ''
  switch (number) {
    case 1:
      suffix = 'st'
      break
    case 2:
      suffix = 'nd'
      break
    case 3:
      suffix = 'rd'
      break
    default:
      suffix = 'th'
  }
  return suffix
}

export const getWeekNumber = (start_date, finish_date, lastMonday) => {
  const currentMonday = moment(lastMonday)
    .local()
    .startOf('isoWeek')
  const startBootcamp = moment(start_date)
  const finishDate = moment(finish_date)
  if (currentMonday.isAfter(finishDate, 'day') || currentMonday.isBefore(startBootcamp, 'day')) {
    return 0
  }
  const weekNumber = currentMonday.diff(startBootcamp, 'weeks') + 1
  return weekNumber
}

export const getMeasurementName = (name) => {
  let result = ''
  switch (name) {
    case 'belly_waist':
      result = 'Waist (Navel)'
      break
    case 'narrow_waist':
      result = 'Waist (Narrowest)'
      break
    default:
      result = name
      break
  }
  return result
}

export const getCustom = (value) => {
  if (value > 0) {
    return 'up'
  }
  if (value < 0) {
    return 'down'
  }
  if (value === 0) {
    return 'zero'
  }
  return ''
}

export const removeMinus = (value) => {
  if (isNaN(value)) {
    return NaN
  }
  return Math.abs(value).toFixed(2)
}

export const getMeasurementsRatio = (measurement) => {
  const { belly_waist, hip } = measurement
  if (_.isNumber(belly_waist) && _.isNumber(belly_waist)) {
    return parseFloat((belly_waist / hip).toFixed(2))
  }
  return NaN
}

export const getNumericChanges = (currentValue, prevValue) => {
  if (_.isNumber(currentValue) && _.isNumber(prevValue)) {
    return (currentValue - prevValue).toFixed(2)
  }
  return NaN
}

export const getWeekArray = (startDate, finishDate) => {
  const dates = []
  while (!startDate.isAfter(finishDate, 'day')) {
    const date = moment(startDate).format('YYYYMMDD')
    dates.push(date)
    startDate.add(1, 'weeks')
  }
  return dates
}

/**
 * Takes a valid date and returns a string with format nn-nn
 * with start and end day of ISO week where that date lands
 * @param {Date} date
 */
export const getWeekRange = (date) => {
  const startDate = moment(date)
  const endDate = moment(date).add(6, 'd')
  return `${startDate.format('DD')}-${endDate.format('DD')}`
}

export const getWeeksForEachDayMap = (start_date, finish_date, entries) => {
  const rangeReport = {}
  const startDate = moment(start_date)
  const finishDate = moment(finish_date)
  while (!startDate.isSame(finishDate, 'day')) {
    const date = moment(startDate).format('YYYYMMDD')
    rangeReport[date] = {
      date,
    }
    startDate.add(1, 'days')
  }

  entries.forEach((entry) => {
    const { date } = entry
    if (rangeReport[date]) {
      rangeReport[date] = { ...rangeReport[date], ...entry }
    }
  })
  const rangeReportArrayData = Object.keys(rangeReport).map((item) => rangeReport[item])
  const weeksMap = _.chunk(rangeReportArrayData, 7)
  return weeksMap
}

export const getWeeksMap = (startDate, finishDate, entries) => {
  const rangeReport = []
  while (!startDate.isAfter(finishDate, 'day')) {
    const date = moment(startDate).format('YYYYMMDD')
    rangeReport[date] = {
      date,
    }
    startDate.add(7, 'days')
  }

  entries.forEach((entry) => {
    const { date } = entry
    if (rangeReport[date]) {
      rangeReport[date] = { ...rangeReport[date], ...entry }
    }
  })
  const weeksMap = Object.keys(rangeReport).map((item) => rangeReport[item])
  return weeksMap
}

/**
 * gets start of week standarized to avoid local diferences in start of week
 * Starting on Monday we add 5 days to get to Saturday and 17 hours to start after 5 PM
 *
 * @return {Object} - which has properties:
 * actualWeek {a moment object) - actual Week for filling,
 * isStartOfFilledWeek {Boolean} - allow to fill current week,
 * today {String} - current Day in special format for using in Queries,
 * actualWeekStart {String} - start of filled week in special format for using in Queries,
 * actualWeekEnd {String} - end of filled week in special format for using in Queries,
 * prevWeekStart {String} - start of previous from filled week in special format  for using in Queries,
 * prevWeekEnd {String} - end of previous from filled week in special format for using in Queries
 */
export const getDateReview = () => {
  const today = moment()
    .local()
    .format('YYYYMMDD')
  // const today = moment('2019-03-09 17:01:01')
  const endOfWeekDay = moment(today)
    .startOf('isoWeek')
    .add(4, 'd')
  const isStartOfFilledWeek = moment(today).isAfter(endOfWeekDay, 'minute')
  const actualWeek = isStartOfFilledWeek ? moment(today) : moment(today).subtract(1, 'weeks')
  const actualWeekStart = moment(actualWeek.startOf('isoWeek')).format('YYYYMMDD')
  const actualWeekEnd = moment(actualWeek.endOf('isoWeek')).format('YYYYMMDD')
  const prevWeek = moment(actualWeek).subtract(1, 'weeks')
  const prevWeekStart = moment(prevWeek.startOf('isoWeek')).format('YYYYMMDD')
  const prevWeekEnd = moment(prevWeek.endOf('isoWeek')).format('YYYYMMDD')

  return { today, actualWeek, isStartOfFilledWeek, actualWeekStart, actualWeekEnd, prevWeekStart, prevWeekEnd }
}

export const getExtraInfoForWeek = (week, additionalEntries) => {
  const startDate = moment(week.date)
  const finishDate = startDate.clone().add(6, 'days')
  let weight = null
  const finishDateOut = finishDate.clone()
  const additionalEntriesObject = additionalEntries.reduce((acc, item) => {
    return { ...acc, [item.date]: item }
  }, {})

  while (finishDate.isSameOrAfter(startDate)) {
    const key = finishDate.clone().format('YYYYMMDD')
    if (additionalEntriesObject[key]) {
      weight = additionalEntriesObject[key].weight
      break
    }
    finishDate.subtract(1, 'days')
  }

  return { weight, finishDate: finishDateOut.format('YYYYMMDD') }
}
