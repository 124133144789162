import React, { Component } from 'react'
import { Row, Col, Tabs, Avatar, Result, Spin, Button, notification } from 'antd'
import _ from 'lodash'
import { Query } from 'react-apollo'
import UserProgress from 'components/UserProgress'
import moment from 'moment'
import { BootcampInfoCard } from './UserDashboard.styled'
import MacrosBody from 'components/UserMacros/MacrosCards'
import DashboardContext from 'context/DashboardContext'
import GlobalContext from 'context/GlobalContext'
import { completeWeek } from 'components/UserDashboard/query'
import CompletedModal from 'components/Modals/CompletedModal'
import apolloClient from 'apolloClient'
import { setStudentContactTag } from 'queries'
import { WeeklyButton } from '../UserProgress/UserProgress.styled'
import { getStudentDashboardData } from './query'
import { getWeekArray } from 'services'
import { Redirect } from '@reach/router'
import { MACROS_STATUS_MAP, WEEKLY_STATUS_MAP, LOCAL_STORAGE } from 'constants.js'
import MealPlan from 'components/UserMealPlan'
const { TabPane } = Tabs
const today = moment().local()
class UserDashboard extends Component {
  state = {
    showModalWeeklyReview: false,
    loadingCompleteWeekButton: false,
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { traineeID } = this.props
    if (!!nextProps.traineeID && nextProps.traineeID === traineeID) {
      if (nextState.showModalWeeklyReview !== this.state.showModalWeeklyReview) {
        return true
      }
      return false
    }
    return true
  }

  getProgressStatus = (weeklies, endDate) => {
    const endOfWeekDay = moment(today)
      .startOf('isoWeek')
      .add(5, 'd')
    const isStartOfFilledWeek = moment(today).isAfter(endOfWeekDay, 'minute')
    const lastWeekTag = isStartOfFilledWeek
      ? moment(today)
          .startOf('isoWeek')
          .format('YYYYMMDD')
      : moment(today)
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .format('YYYYMMDD')

    const lastWeekly = weeklies.find((w) => w.for_date === lastWeekTag) || { status: 4 }
    const weeklyStatus = WEEKLY_STATUS_MAP.find((ws) => ws.id === lastWeekly.status)

    return (
      <p title={weeklyStatus.name}>
        <img className="filter-icon" src={weeklyStatus.icon} alt="" /> {weeklyStatus.name}
      </p>
    )
  }

  getMacrosStatus = (macros) => {
    if (!!macros.status) {
      const macroStatus = MACROS_STATUS_MAP.find((status) => status.macrosStatus === macros.status) || {}
      if (!!macroStatus.id) {
        return (
          <p title={macroStatus.name}>
            <img className="filter-icon" src={macroStatus.icon} alt="" /> {macroStatus.name}
          </p>
        )
      }
    }
    return <p>-</p>
  }

  handleTabChange = (e) => {
    localStorage.setItem(LOCAL_STORAGE.CURRENT_TAB, e)
  }

  getContext = (data, bootcampActive, refetch) => {
    const trainee = _.get(data, 'trainee[0]')
    let student = {}

    const currentProgram = _.get(trainee, 'currentProgram[0]') || {}

    const coach = _.get(currentProgram.coach, 'user') || {}
    let enrollment = _.get(currentProgram, 'enrollment') || {}

    const medicalConditions = _.get(data, 'medical_condition') || []
    const configuration = _.get(data, 'configuration') || []
    const simpleMacros = _.get(trainee, 'trainee_details[0]') || null
    const macros = _.get(trainee, 'macros[0]') || {}
    const meal_plan = _.get(trainee, 'mealPlan[0]') || {}
    const lastBootcampMacros = _.get(trainee, 'last_bootcamp_macros[0]') || null
    const weeklies = _.get(trainee, 'weeklies') || []
    const firstWeekly = _.get(trainee, 'firstWeekly[0]') || {}
    const firstEnrollment = _.get(trainee, 'firstEnrollment[0]') || {}
    const dailies = _.get(trainee, 'dailies') || []
    const pictures = _.get(trainee, 'pictures') || []
    const activityLevels = _.get(data, 'activity_level') || []
    const units = enrollment.metric ? enrollment.metric.split('/') : ['lbs', 'in']
    let typeOfDiets = []
    let bodyFatMethods = []
    let goals = []
    const typeOfDietCatalog = configuration.find((c) => c.id === 'type_of_diets')
    const bodyFatMethodsCatalog = configuration.find((c) => c.id === 'body_fat_methods')
    const goalsCatalog = configuration.find((c) => c.id === 'goal')
    if (typeOfDietCatalog) typeOfDiets = JSON.parse(typeOfDietCatalog.value)
    if (bodyFatMethodsCatalog) bodyFatMethods = JSON.parse(bodyFatMethodsCatalog.value)
    if (goalsCatalog) goals = JSON.parse(goalsCatalog.value)
    let totalBootcamps

    if (!!trainee) {
      student = {
        ...trainee.user,
        trainee_id: trainee.id,
      }
      totalBootcamps = trainee.total_past_bootcamp + trainee.total_program.aggregate.count
    }

    const weekMap = getWeekArray(moment(bootcampActive.start_date), moment(bootcampActive.end_date))
      .map((w) => {
        return {
          for_date: w,
          start_date: moment(w).format('DD MMM'),
          end_date: moment(w)
            .add(6, 'day')
            .format('DD MMM'),
        }
      })
      .filter((w) => {
        const endOfWeekDay = moment(today)
          .startOf('isoWeek')
          .add(5, 'd')
        const isStartOfFilledWeek = moment(today).isAfter(endOfWeekDay, 'minute')
        const lastWeekTag = isStartOfFilledWeek
          ? moment(today).startOf('isoWeek')
          : moment(today)
              .subtract(1, 'weeks')
              .startOf('isoWeek')
        return moment(w.for_date).isBefore(lastWeekTag.add(1, 'day'))
      })

    return {
      student,
      macros,
      simpleMacros,
      mealPlan: meal_plan ? meal_plan.meal_plan : null,
      lastBootcampMacros,
      enrollment,
      currentProgram,
      weeklies,
      firstWeekly,
      firstEnrollment,
      pictures,
      dailies,
      units,
      totalBootcamps,
      coach,
      activityLevels,
      activityLevelChoosen: {},
      weekMap,
      medicalConditions,
      typeOfDiets,
      bodyFatMethods,
      goals,
      refetch,
    }
  }

  completeWeekReview = (refetch, programId) => {
    const { traineeID } = this.props
    let today = moment().local()

    const endOfWeekDay = moment(today)
      .startOf('isoWeek')
      .add(5, 'd')
    const isStartOfFilledWeek = moment(today).isAfter(endOfWeekDay, 'minute')
    const lastWeekTag = isStartOfFilledWeek
      ? moment(today)
          .startOf('isoWeek')
          .format('YYYYMMDD')
      : moment(today)
          .subtract(1, 'weeks')
          .startOf('isoWeek')
          .format('YYYYMMDD')

    this.setState({ loadingCompleteWeekButton: true })
    apolloClient
      .mutate({ mutation: completeWeek, variables: { traineeId: traineeID, forDate: lastWeekTag, data: { status: 1 } } })
      .then(async () => {
        refetch()
        await setStudentContactTag(traineeID, programId, ['WeeklyCompleted']).catch((error) =>
          notification.warning({ title: 'InfusionSoft Warning!', message: error }),
        )
        this.setState({ showModalWeeklyReview: !this.state.showModalWeeklyReview, loadingCompleteWeekButton: false })
      })
      .catch((error) => {
        console.log(error)
        notification['error']({ message: 'Error while trying to connect to the database', description: `${error}` })
        this.setState({ loadingCompleteWeekButton: false })
      })
  }

  closeModalWeeklyReview = () => {
    this.setState({
      showModalWeeklyReview: false,
      loadingCompleteWeekButton: false,
    })
  }

  isDisabledCompletWeekReview = (bootcampActive, weeklies) => {
    let today = moment().local()

    if (today.isSameOrBefore(moment(bootcampActive.close_date))) {
      if (today.isAfter(moment(bootcampActive.end_date))) {
        today = moment(bootcampActive.end_date)
      }

      if (weeklies.length > 0) {
        const endOfWeekDay = moment(today)
          .startOf('isoWeek')
          .add(5, 'd')
        const isStartOfFilledWeek = moment(today).isAfter(endOfWeekDay, 'minute')
        const lastWeekTag = isStartOfFilledWeek
          ? moment(today)
              .startOf('isoWeek')
              .format('YYYYMMDD')
          : moment(today)
              .subtract(1, 'weeks')
              .startOf('isoWeek')
              .format('YYYYMMDD')
        const weekly = weeklies.find((w) => w.for_date === lastWeekTag) || { status: 4 }
        if (weekly.status !== 1 && weekly.status !== 4) {
          return false
        }
      }
    }
    return true
  }

  render() {
    const { traineeID, coachID, userListRefetch } = this.props
    return (
      <GlobalContext.Consumer>
        {({ bootcampActive, showMessage }) => {
          const variables = {
            traineeID,
            programId: bootcampActive.id,
            enrollmentDate: moment(bootcampActive.start_date)
              .add(-1, 'day')
              .startOf('isoWeek')
              .format('YYYYMMDD'),
            startDate: moment(bootcampActive.start_date).format('YYYYMMDD'),
            endDate: moment(bootcampActive.end_date).format('YYYYMMDD'),
          }

          variables.enrollmentDateAux = variables.enrollmentDate

          return (
            <Query query={getStudentDashboardData} fetchPolicy="network-only" variables={variables}>
              {({ error, loading, data, refetch }) => {
                if (error) return <Result status="error" title="Error loading the dashboard" subTitle={error.message} />
                if (loading) return <Result icon={<Spin />} title="Loading" />

                const is_master = localStorage.getItem(LOCAL_STORAGE.IS_MASTER)
                const is_meal_planner = localStorage.getItem(LOCAL_STORAGE.IS_MEAL_PLANNER)

                const refetch2 = () => {
                  userListRefetch()
                  refetch()
                }
                const context = this.getContext(data, bootcampActive, refetch2)
                if (!!!context.currentProgram.program_id) {
                  //The user has not the bootcamp assigned
                  return <Redirect to={`/${coachID}/`} noThrow />
                }
                const hasMealPlan = context.currentProgram.extras ? context.currentProgram.extras.meal_plan !== 0 : false
                const bootcampTypeUser = context.currentProgram.program.type
                const showBootcampTab = bootcampTypeUser === 'bootcamp'
                const showMacrosTab = showBootcampTab || bootcampTypeUser === 'macros'
                const showMealPlanTab = (is_master === 'true' || is_meal_planner === 'true') && (bootcampTypeUser === 'meal_plan' || hasMealPlan)

                const activityLevelChoosen = context.activityLevels.find((al) => al.id === context.enrollment.activity_level) || { label: 'Unknow' }

                let currentTab = localStorage.getItem(LOCAL_STORAGE.CURRENT_TAB)

                let hasExtras = context.currentProgram.extras

                let goal = ''
                let goalItem = context.goals.find((g) => g.id === context.enrollment.current_goal)
                if (goalItem) goal = goalItem.label

                return (
                  <DashboardContext.Provider value={context}>
                    <Row gutter={[8, 8]}>
                      <Col lg={8} md={24}>
                        <BootcampInfoCard className="keto-card">
                          <Row gutter={[4, 1]}>
                            <Col md={4} xs={6}>
                              <Avatar size={45} className="profile-picture" icon="user" src={context.student.picture} />
                            </Col>
                            <Col md={9} xs={18}>
                              <p className="name">{`${context.student.first_name} ${context.student.last_name}`}</p>
                              <p className="email">{context.student.email}</p>
                              <p className="total-bootcamps">{context.totalBootcamps} Bootcamp</p>
                            </Col>
                            <Col md={2} xs={8}>
                              <p className="label">Age</p>
                              <p>{moment().diff(moment(context.student.birthday), 'year')}</p>
                            </Col>
                            <Col md={3} xs={8}>
                              <p className="label">Gender</p>
                              <p>{context.student.gender}</p>
                            </Col>
                            <Col md={3} xs={8}>
                              <p className="label">Units</p>
                              <p>{context.student.units}</p>
                            </Col>
                            <Col md={3} xs={8}>
                              <p className="label">Height</p>
                              <p>
                                {context.enrollment.height} {context.units[1]}
                              </p>
                            </Col>
                          </Row>
                        </BootcampInfoCard>
                      </Col>
                      <Col lg={8} md={12}>
                        <BootcampInfoCard className="keto-card">
                          <Row gutter={[4, 0]}>
                            <Col span={8}>
                              <p className="label">Goal</p>
                              <p>{goal}</p>
                            </Col>
                            <Col span={4}>
                              <p className="label">Level</p>
                              <p>Level {`${context.enrollment.level}-${context.enrollment.sub_level}`}</p>
                            </Col>
                            <Col span={8}>
                              <p className="label">Activity Level</p>
                              <p title={activityLevelChoosen.label}>{activityLevelChoosen.label}</p>
                            </Col>
                            <Col span={4}>
                              <p className="label">Meal Plan</p>
                              <p>{hasExtras && hasExtras.meal_plan !== 0 ? 'Yes' : 'No'}</p>
                            </Col>
                          </Row>
                        </BootcampInfoCard>
                      </Col>
                      <Col lg={8} md={12}>
                        <BootcampInfoCard className="keto-card">
                          <Row gutter={[4, 0]}>
                            <Col span={8}>
                              <p className="label">Progress status</p>
                              {this.getProgressStatus(context.weeklies, bootcampActive.end_date)}
                            </Col>
                            <Col span={5}>
                              <p className="label">Macros</p>
                              {this.getMacrosStatus(context.macros)}
                            </Col>
                            <Col span={3}>
                              <Avatar size={40} icon="question" src={context.coach.picture} />
                            </Col>
                            <Col span={8}>
                              <p className="label">Assigned Coach</p>
                              <p>{`${context.coach.first_name} ${context.coach.last_name}`}</p>
                            </Col>
                          </Row>
                        </BootcampInfoCard>
                      </Col>
                      <Col lg={24} md={24}>
                        <div className="keto-card">
                          <Tabs
                            onChange={(e) => this.handleTabChange(e)}
                            defaultActiveKey={currentTab}
                            tabBarExtraContent={
                              <>
                                {showBootcampTab && (
                                  <WeeklyButton
                                    className="complete"
                                    icon="check"
                                    disabled={this.isDisabledCompletWeekReview(bootcampActive, context.weeklies)}
                                    onClick={() => this.completeWeekReview(context.refetch, context.currentProgram.program_id)}
                                    loading={this.state.loadingCompleteWeekButton}
                                  >
                                    Complete client week
                                  </WeeklyButton>
                                )}
                              </>
                            }
                          >
                            {showBootcampTab && (
                              <TabPane tab="Progress" key="1">
                                <UserProgress
                                  ref={(instance) => {
                                    this.userProgress = instance
                                  }}
                                  trainee_id={context.student.trainee_id}
                                  program_id={context.currentProgram.program_id}
                                  showMessage={showMessage}
                                />
                                <CompletedModal visible={this.state.showModalWeeklyReview} handleClose={this.closeModalWeeklyReview} />
                              </TabPane>
                            )}
                            {showMacrosTab && (
                              <TabPane tab="Macros" key="2">
                                {!!context.macros.macro_tables && (
                                  <MacrosBody
                                    isBootcamp={showBootcampTab}
                                    traineeID={traineeID}
                                    macroDetails={context.enrollment}
                                    macroMacros={context.macros}
                                    lastBootcampMacros={context.lastBootcampMacros}
                                    details={context.enrollment}
                                    macros={context.macros}
                                    macroBaseDay={context.macros.macro_tables.find((t) => t.type === 'BaseDay') || {}}
                                    macroTrainingDay={context.macros.macro_tables.find((t) => t.type === 'TrainingDay') || {}}
                                    macroAlternate={
                                      context.macros.macro_tables.find((t) => t.type === 'Alternate') || {
                                        protein_base_grams: 0,
                                        protein_pre_workout_grams: 0,
                                        protein_kcal_gram: 4,
                                        carbs_base_grams: 0,
                                        carbs_pre_workout_grams: 0,
                                        carbs_kcal_gram: 4,
                                        fat_base_grams: 0,
                                        fat_pre_workout_grams: 0,
                                        fat_kcal_gram: 9,
                                        protein_daily_kcals: 0,
                                        carbs_daily_kcals: 0,
                                        fat_daily_kcals: 0,
                                        total_daily_kcals: 0,
                                      }
                                    }
                                    programId={context.currentProgram.program_id}
                                    currentProgram={bootcampActive}
                                    currentTrainee={context.student.trainee_id}
                                    coachID={coachID}
                                    pictures={context.pictures}
                                    selected={[{}]}
                                    refetch={refetch2}
                                  />
                                )}
                                {!!!context.macros.macro_tables && (
                                  <Result
                                    status="info"
                                    title="User with old Macros"
                                    extra={
                                      <>
                                        {context.currentProgram && context.currentProgram.extras && context.currentProgram.extras.old_macro_url && (
                                          <Button href={context.currentProgram.extras.old_macro_url} target="_blank">
                                            View PDF
                                          </Button>
                                        )}
                                      </>
                                    }
                                  />
                                )}
                              </TabPane>
                            )}
                            {showMealPlanTab && context.mealPlan && (
                              <TabPane tab="Meal Plan" key="3">
                                <MealPlan
                                  macros={context.simpleMacros}
                                  refetch={refetch}
                                  mealPlan={context.mealPlan}
                                  traineeId={context.student.trainee_id}
                                  programId={context.currentProgram.program_id}
                                />
                              </TabPane>
                            )}
                          </Tabs>
                        </div>
                      </Col>
                    </Row>
                  </DashboardContext.Provider>
                )
              }}
            </Query>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default UserDashboard
