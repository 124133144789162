import styled from '@emotion/styled'

export const AdminTitle = styled.div`
  margin-right: 20px;
  color: #353535;
  font-size: 16px;
  span {
    font-weight: 600;
  }
`
export const AdminInfo = styled.div`
  display: flex;
  align-items: center;
`
