export const changeDetailsSections = [
  'Macros Selected',
  'Starting Goal, Level & Lifestyle',
  'Personal Info',
  'Protein Intake',
  'Dietary Deficit',
  'Base Macros',
  'Training Macros',
  'Alternate Macros',
]

export const changeDetailsLabels = [
  { id: 'activity_level', value: 'Activity' },
  { id: 'body_fat_percent', value: 'Body fat percent' },
  { id: 'lean_body_mass', value: 'Lean body mass' },
  { id: 'macro_id', value: 'Macro id' },
  { id: 'need_coach_approval', value: 'Need Coach Approval' },
  { id: 'current_goal', value: 'Initial Goal' },
  { id: 'level', value: 'Level' },
  { id: 'use_lean_gains', value: 'Use Lean Gains' },
  { id: 'protein_intake_override', value: 'Protein Intake Override' },
  { id: 'deficit_override', value: 'Coach Override' },
  { id: 'calorie_intake_pre_health', value: 'Recommended Cal/Day' },
  { id: 'protein_base_grams', value: 'Protein Base Grams' },
  { id: 'protein_total_grams', value: 'Protein Total Grams' },
  { id: 'protein_daily_kcals', value: 'Protein Daily Kcals' },
  { id: 'protein_percent_calories', value: 'Protein Percent Calories' },
  { id: 'carbs_base_grams', value: 'Carbs Base Grams' },
  { id: 'carbs_total_grams', value: 'Carbs Total Grams' },
  { id: 'carbs_daily_kcals', value: 'Carbs Daily Kcals' },
  { id: 'carbs_percent_calories', value: 'Carbs Percent Calories' },
  { id: 'fat_base_grams', value: 'Fat Base Grams' },
  { id: 'fat_total_grams', value: 'Fat Total Grams' },
  { id: 'fat_daily_kcals', value: 'Fat Daily Kcals' },
  { id: 'fat_percent_calories', value: 'Fat Percent Calories' },
  { id: 'total_daily_kcals', value: 'Total Daily Kcals' },
  { id: 'carbs_pre_workout_grams', value: 'Carbs pre workout grams' },
  { id: 'fat_pre_workout_grams', value: 'Fat pre workout grams' },
  { id: 'protein_pre_workout_grams', value: 'Protein pre workout grams' },
  { id: 'recommended_deficit', value: 'Recommended deficit' },
]
