import styled from "@emotion/styled";

export default styled.h2`
  && {
    margin-bottom: 0;
    padding: 20px 10px 30px;
    color: #4a4a4a;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    letter-spacing: 0.05em;
    line-height: 13px;
    text-align: center;
    text-transform: uppercase;
  }
`;
