import React, { useState } from 'react'
import { Modal, Button, Alert, Row, Input, Checkbox } from 'antd'
import { changeDetailsSections } from './data'
import moment from 'moment'
import DashboardContext from 'context/DashboardContext'
import { TableMacro, TableMacroChange } from './ModalValidationChange'

const { TextArea } = Input

export const ModalChangesNoteCard = ({
  visible,
  handleCancel,
  handleChange,
  changeDetails,
  handleDiscardChanges,
  handleNoteCreation,
  onSave,
  coachID,
  programId,
  currentTrainee,
  refetch,
  textNote,
  readOnly,
  macrosSelected,
  lastBootcampMacros,
  macrosStatus,
  name,
  date,
}) => {
  const handleSave = async (note, coachID, programId, currentTrainee, change_details, refetch, check) => {
    if (note === '' && check) {
      note = 'Your macros were reviewed and edited by your coach, please check them in the Macros Section of the Ketogains Client Portal.'
    }
    await handleNoteCreation(note, coachID, programId, currentTrainee, change_details, refetch, check)
    await handleCancel()
    await onSave()
  }
  const showLevelWarning = !!changeDetails.find((c) => c.name === 'level')
  const showMacrosWarning = !!changeDetails.find((c) => c.name === 'macros')
  const [check, setCheck] = useState(false)
  let day = moment(date).format('DD')
  let month = moment(date).format('MMM')
  let year = moment(date).format('YYYY')

  const handleSendNote = (e) => {
    setCheck(e.target.checked)
  }

  let SendNoteLabel = 'Send note to user'

  const checkValue = changeDetails.find((value) => value.name === 'Check')

  if (checkValue !== undefined) {
    if (checkValue.name === 'Check' && checkValue.Value === true) {
      SendNoteLabel = 'Note has been sent to the user'
    } else if (checkValue.name === 'Check' && checkValue.Value === false) {
      SendNoteLabel = 'Note has not been sent to the user'
    }
  }

  return (
    <Modal
      title="Macros Notes and Changes"
      className="modal-changes-note-card"
      visible={visible}
      width={700}
      onCancel={handleCancel}
      footer={
        readOnly
          ? [
              <Button key="back" className="modal-cancel-button" onClick={handleCancel}>
                Ok
              </Button>,
            ]
          : [
              <Button key="back" className="modal-cancel-button" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" className="modal-save-note-button" type="primary" onClick={handleDiscardChanges}>
                Discard All Changes
              </Button>,
              <Button
                key="save"
                className="modal-save-changes-note-button"
                type="primary"
                onClick={() => handleSave(textNote, coachID, programId, currentTrainee, changeDetails, refetch, check)}
              >
                Save All Changes
              </Button>,
            ]
      }
    >
      <div className="note-changes-container">
        <div className="changes-container">
          {showLevelWarning && (
            <Alert
              message="Warning"
              description="Be aware that changing level may remove you (or original coach) as this person's coach."
              type="warning"
              showIcon
              style={{ margin: '0 0 5px 0' }}
            />
          )}
          {showMacrosWarning && (
            <Alert
              message="Warning"
              description={
                macrosSelected === 'last'
                  ? `Are you sure you want to use the macros calculated for this student for ${lastBootcampMacros.program.description}?`
                  : `You are using the macros calculated with the newest enrollment data`
              }
              type="warning"
              showIcon
            />
          )}
          {changeDetailsSections.map((section) => {
            const items = changeDetails.filter((i) => i.section === section)

            if (items.length <= 0) return null
            let columSize = parseInt((24 / items.length).toFixed(0))
            if (columSize < 4) columSize = 4

            return (
              <div key={section}>
                {section === 'Starting Goal, Level & Lifestyle' && <p className="section-title">{section}</p>}
                {section === 'Protein Intake' && <p className="section-title">{section}</p>}
                {section === 'Dietary Deficit' && <p className="section-title">{section}</p>}
                <DashboardContext.Consumer>
                  {({ activityLevels }) => {
                    return (
                      <Row gutter={24} className="section-item-container" style={{ marginBottom: 15 }}>
                        <div style={{ overflowX: 'scroll', overflowY: 'hidden' }}>
                          {section === 'Base Macros' && <TableMacro items={items} section={section} />}
                          {section === 'Training Macros' && <TableMacro items={items} section={section} />}
                          {section === 'Alternate Macros' && <TableMacro items={items} section={section} />}
                        </div>
                        {section === 'Starting Goal, Level & Lifestyle' && (
                          <TableMacroChange items={items} activityLevels={activityLevels} section={section} />
                        )}
                        {section === 'Protein Intake' && <TableMacroChange items={items} activityLevels={activityLevels} section={section} />}
                        {section === 'Dietary Deficit' && <TableMacroChange items={items} activityLevels={activityLevels} section={section} />}
                      </Row>
                    )
                  }}
                </DashboardContext.Consumer>
              </div>
            )
          })}
        </div>

        <p className="notes-textarea-message">Important Notes</p>
        <TextArea
          name="changesNoteText"
          placeholder="Brief description of your changes"
          disabled={readOnly ? true : false}
          style={{ width: '50%' }}
          value={textNote}
          autosize={{ minRows: 2, maxRows: 6 }}
          onChange={(e) => handleChange(e)}
        />
        <> </>
        <Checkbox
          defaultChecked={SendNoteLabel === 'Note has been sent to the user' ? true : check}
          disabled={macrosStatus !== 'Approved' ? true : false}
          onChange={handleSendNote}
        >
          {SendNoteLabel}
        </Checkbox>

        {name && (
          <div style={{ marginTop: 10 }}>
            <span className="notes-user-container-name">
              Changes made by <b style={{ fontWeight: 600 }}>{name}</b> at <b style={{ fontWeight: 600 }}>{`${day}/${month}/${year}`}</b>
            </span>
          </div>
        )}
      </div>
    </Modal>
  )
}
