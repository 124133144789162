import React, { Component } from 'react'
import { Layout } from 'antd'
import Header from './Header'
import { AppVersion } from 'constants.js'

import 'styled/Layout.scss'

const { Content, Footer } = Layout

class KetogainsLayout extends Component {
  render() {
    const { user, bootcampActive } = this.props
    return (
      <Layout className="ketogains-layout">
        <Header user={user} bootcampActive={bootcampActive} />
        <Content>{this.props.children}</Content>
        <Footer>{AppVersion}</Footer>
      </Layout>
    )
  }
}

export default KetogainsLayout
