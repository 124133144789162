import React, { Component } from 'react'
import apolloClient from 'apolloClient.js'
import gql from 'graphql-tag'
import { Form, notification, Alert } from 'antd'
import moment from 'moment'
import GlobalContext from 'context/GlobalContext'
import DashboardContext from 'context/DashboardContext'
import Messages from './Messages'
import TextEditor from './TextEditor'
import { StyledSwitch } from './ClientChat.styled'
import { Query } from 'react-apollo'

const appendMessage = gql`
  mutation appendMessage($data: message_insert_input!, $lastMessageDate: timestamptz!, $userId: uuid!, $thread_id: uuid!) {
    insert_message(objects: [$data]) {
      returning {
        timestamp
        body
      }
    }
    update_message(
      where: {
        _and: { timestamp: { _lte: $lastMessageDate }, thread_id: { _eq: $thread_id }, sender_id: { _neq: $userId }, read_coach: { _eq: false } }
      }
      _set: { read_coach: true }
    ) {
      affected_rows
    }
  }
`

const activateClientChat = gql`
  mutation activateClientChat($traineeId: uuid!, $programId: uuid!, $studentCanReply: Boolean!) {
    update_trainee_program_coach(
      where: { _and: [{ trainee_id: { _eq: $traineeId } }, { program_id: { _eq: $programId } }] }
      _set: { student_can_reply: $studentCanReply }
    ) {
      returning {
        trainee_id
        coach_id
        program_id
      }
    }
  }
`

const getClientChatData = gql`
  query getClientChatData($threadId: uuid!) {
    message(where: { thread_id: { _eq: $threadId } }, order_by: { timestamp: asc }) {
      id
      timestamp
      body
      sender_id
      read_coach
    }
  }
`
let refetchChat = () => {}
class ClientChat extends Component {
  state = {
    studentCanReply: null,
    loading: true,
  }

  componentDidUpdate() {
    refetchChat()
  }

  componentDidMount() {
    refetchChat()
  }

  activateClientChat = (checked) => {
    const { currentTrainee, programId } = this.props
    apolloClient.mutate({
      mutation: activateClientChat,
      variables: {
        traineeId: currentTrainee,
        programId,
        studentCanReply: checked,
      },
      update: () => {
        this.setState({
          studentCanReply: checked,
        })
      },
    })
  }

  onSendMessage = (message, thread_id, lastMessageDate, userID, refetch, callback) => {
    apolloClient
      .mutate({
        mutation: appendMessage,
        variables: {
          data: { thread_id, body: message, sender_id: userID },
          lastMessageDate,
          userId: userID,
          thread_id,
        },
        update: () => {
          if (typeof callback === 'function') {
            callback()
          }
          refetch()
        },
      })
      .catch((error) => {
        notification.error({ message: 'Your message could not be sent', description: `${error}` })
      })
  }

  render() {
    return (
      <GlobalContext.Consumer>
        {({ user }) => {
          const userID = user.id
          return (
            <DashboardContext.Consumer>
              {({ student, currentProgram }) => {
                const studentCanReply = this.state.studentCanReply !== null ? this.state.studentCanReply : currentProgram.student_can_reply
                const thread_id = currentProgram.thread_id
                const coachUnits = localStorage.getItem('COACH_UNITS')
                return (
                  <div className="keto-card">
                    <header>
                      <h3>Client Communication</h3>
                      <div className="actions">
                        <span>
                          Student Can Reply{' '}
                          <StyledSwitch
                            size="small"
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            checked={studentCanReply}
                            onChange={this.activateClientChat}
                          />
                        </span>
                      </div>
                    </header>
                    <main>
                      <Query query={getClientChatData} variables={{ threadId: thread_id }}>
                        {({ data, error, loading, refetch }) => {
                          if (loading) return <p>Loading...</p>
                          if (error) return <p>Error :(</p>
                          const messages = data.message ? data.message : []
                          const lastMessageDate = messages.length > 0 ? messages[messages.length - 1].timestamp : moment().local()
                          refetchChat = () => {
                            refetch()
                          }
                          return (
                            <>
                              {student.units !== coachUnits && <Alert message={`The student use ${student.units}`} type="warning" showIcon />}
                              <Messages {...{ messages, userID, refetch }} />
                              <TextEditor
                                {...{
                                  onSend: this.onSendMessage,
                                  student,
                                  thread_id,
                                  userID,
                                  lastMessageDate,
                                  currentTrainee: this.props.currentTrainee,
                                  refetch,
                                }}
                              />
                            </>
                          )
                        }}
                      </Query>
                    </main>
                  </div>
                )
              }}
            </DashboardContext.Consumer>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Form.create()(ClientChat)
