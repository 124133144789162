import React, { Component, Fragment } from 'react'
import { navigate, Link } from '@reach/router'
import gql from 'graphql-tag'
import { Mutation, Query } from 'react-apollo'
import moment from 'moment'
import _ from 'lodash'
import { Form, Select, Radio } from 'antd'
import { getUserData } from 'queries/user'
import { validateData } from 'services/validation'
import countries from 'countries'
import { LOCAL_STORAGE } from 'constants.js'
import Uploader from 'components/Uploader'
import { StyledButton, StyledForm, StyledRadioGroup, StyledDatePicker, StyledInput, StyledSelect } from 'styled'
import { Title, UploaderWrap, UploadedPicture } from './Personal.styled'
import pictureIcon from 'images/picture.svg'

const FormItem = Form.Item
const { Option } = Select

// GQL for Personal data
const getPersonalData = gql`
  query($id: uuid!) {
    user(where: { id: { _eq: $id } }) {
      id
      picture
      birthday
      country
      email
      first_name
      last_name
      gender
      coach {
        id
        units
      }
    }
  }
`
const updatePersonalData = gql`
  mutation updatePersonalData($id: uuid!, $data: user_set_input, $units: String!) {
    update_user(where: { id: { _eq: $id } }, _set: $data) {
      returning {
        id
        trainee {
          id
        }
      }
    }
    update_coach(where: { user_id: { _eq: $id } }, _set: { units: $units }) {
      returning {
        id
        user {
          id
        }
      }
    }
  }
`

class MainForm extends Component {
  state = {
    uploadedFileUrl: null,
    isFileUploaded: false,
  }

  lastValidDate = () => moment().subtract(18, 'years') // new Date(today.getFullYear() - 18, today.getMonth() - 1, today.getDay())

  disabledDate = (dateValue) => {
    const validDate = this.lastValidDate()
    return validDate.diff(moment(dateValue)) < 0 // dateValue.valueOf() > validDate.valueOf()
  }

  handleNewDate = (rule, value, callback) => {
    if (value && this.disabledDate(value)) {
      callback('You must be at least 18 years old')
    }

    // Note: Always need to callback
    callback()
  }

  handleUploadedFile = (uploadedFileUrl) => {
    this.setState({
      uploadedFileUrl: uploadedFileUrl.cdnUrl,
      isFileUploaded: true,
    })
  }

  handleSubmit = (save) => (event) => {
    event.preventDefault()
    const {
      form: { validateFieldsAndScroll },
      id,
    } = this.props
    const { uploadedFileUrl } = this.state
    validateFieldsAndScroll((error, values) => {
      if (error) {
        console.error(error)
        return
      }
      if (validateData(id)) {
        const data = {
          ...values,
        }
        const units = data.units
        delete data['units']
        if (!!uploadedFileUrl) data.picture = uploadedFileUrl
        save({
          variables: {
            id,
            data,
            units,
          },
        }).then(() => {
          localStorage.setItem(LOCAL_STORAGE.COACH_UNITS, units)
          navigate(`/${localStorage.getItem(LOCAL_STORAGE.COACH_ID)}`)
        })
      }
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      id,
    } = this.props
    const { uploadedFileUrl, isFileUploaded } = this.state
    const eighteenYearsAgo = this.lastValidDate()

    return (
      <Query query={getPersonalData} variables={{ id }} fetchPolicy="cache-and-network">
        {({ loading, error, data }) => {
          if (loading || error) return null
          const { first_name, last_name, email, birthday, country, gender, picture } = _.get(data, 'user[0]') || {}
          const units = _.get(data, 'user[0].coach.units') || {}
          return (
            <Mutation
              mutation={updatePersonalData}
              update={(store, { data }) => {
                if (!id) {
                  const userData = store.readQuery({ query: getUserData })
                  userData.User.id = _.get(data, 'insert_trainee.returning[0].user_id')
                  userData.User.trainee_id = _.get(data, 'insert_trainee.returning[0].id')
                  store.writeQuery({ data: userData, query: getUserData })
                  localStorage.setItem(LOCAL_STORAGE.USER_ID, _.get(data, 'insert_trainee.returning[0].user_id'))
                  localStorage.setItem(LOCAL_STORAGE.TRAINEE_ID, _.get(data, 'insert_trainee.returning[0].id'))
                }
                if (!!id && !!uploadedFileUrl) {
                  const userData = store.readQuery({ query: getUserData })
                  userData.User.picture = uploadedFileUrl
                  store.writeQuery({ data: userData, query: getUserData })
                  localStorage.setItem(LOCAL_STORAGE.USER_PICTURE, uploadedFileUrl)
                }
              }}
            >
              {(mutation) => (
                <StyledForm hideRequiredMark onSubmit={this.handleSubmit(mutation)}>
                  <Title>personal data</Title>
                  <UploaderWrap>
                    <UploadedPicture src={uploadedFileUrl || picture || pictureIcon} alt="" isFileUploaded={isFileUploaded} />
                    <Uploader name="profile" data-crop="300x300 minimum" onUploadComplete={this.handleUploadedFile} />
                  </UploaderWrap>
                  <FormItem label="First Name">
                    {getFieldDecorator('first_name', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your first name!',
                        },
                      ],
                      initialValue: first_name || '',
                    })(<StyledInput />)}
                  </FormItem>
                  <FormItem label="Last Name">
                    {getFieldDecorator('last_name', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your last name!',
                        },
                      ],
                      initialValue: last_name || '',
                    })(<StyledInput />)}
                  </FormItem>
                  <FormItem label="Preferred contact email">
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: 'The input is not valid email!',
                        },
                        {
                          required: true,
                          message: 'Please input your email!',
                        },
                      ],
                      initialValue: email || '',
                    })(<StyledInput type="email" />)}
                  </FormItem>
                  <FormItem
                    label={
                      <Fragment>
                        Birthdate <span className="notation">(MM/DD/YYYY)</span>
                      </Fragment>
                    }
                  >
                    {getFieldDecorator('birthday', {
                      rules: [
                        {
                          type: 'object',
                          required: true,
                          message: 'Please input your birthdate!',
                        },
                      ],
                      initialValue: moment(birthday) || eighteenYearsAgo,
                    })(<StyledDatePicker showToday={false} disabledDate={this.disabledDate} format="MM/DD/YYYY" />)}
                  </FormItem>
                  <FormItem label="Country of residence" hasFeedback>
                    {getFieldDecorator('country', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your country!',
                        },
                      ],
                      initialValue: country || 'US',
                    })(
                      <StyledSelect
                        showSearch
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        placeholder="Select"
                        autoClearSearchValue={false}
                      >
                        {countries.map(({ name, code }) => (
                          <Option key={code} value={code}>
                            {name}
                          </Option>
                        ))}
                      </StyledSelect>,
                    )}
                  </FormItem>
                  <FormItem label="Biological Gender">
                    {getFieldDecorator('gender', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your biological gender!',
                        },
                      ],
                      initialValue: gender || '',
                    })(
                      <StyledRadioGroup custom="inline">
                        <Radio value="female">Female</Radio>
                        <Radio value="male">Male</Radio>
                      </StyledRadioGroup>,
                    )}
                  </FormItem>

                  <FormItem label="Units">
                    {getFieldDecorator('units', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your unit of measure!',
                        },
                      ],
                      initialValue: units || '',
                    })(
                      <StyledRadioGroup custom="inline">
                        <Radio value="kg/cm">Kg/Cm</Radio>
                        <Radio value="lbs/in">Lb/In</Radio>
                      </StyledRadioGroup>,
                    )}
                  </FormItem>

                  <FormItem className="inline">
                    <Link custom="back" to={`/${localStorage.getItem(LOCAL_STORAGE.COACH_ID)}`}>
                      Back
                    </Link>
                    <StyledButton htmlType="submit">Save</StyledButton>
                  </FormItem>
                </StyledForm>
              )}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

export default Form.create()(MainForm)
