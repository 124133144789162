import React from 'react'
import { Root, Wrap, StyledIcon, P } from './AppLoading.styled'
const AppLoading = (props) => (
  <Root>
    <Wrap>
      <StyledIcon type="loading" />
      <P>{props.text || 'Your access token expired, we are getting a new token'}</P>
    </Wrap>
  </Root>
)

export default AppLoading
