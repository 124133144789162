import { setCookie, getCookie, removeCookie } from 'tiny-cookie'
import { message } from 'antd'
import { getDateReview } from 'services'
import { LOCAL_STORAGE, COOKIE } from 'constants.js'
import apolloClient from './apolloClient.js'
import gql from 'graphql-tag'
import moment from 'moment'
import { getUserData } from 'queries'

const { actualWeekStart } = getDateReview()
const authServer = process.env.REACT_APP_AUTH_SERVER
const today = moment()
  .local()
  .format('YYYY-MM-DD')

const login = gql`
  query($id: uuid!, $contactID: String!) {
    coach(where: { id: { _eq: $id } }) {
      is_master
      is_meal_planner
      units
      assigned(limit: 1, order_by: { program: { start_date: desc } }) {
        program {
          program_id
          start_date
          close_date
          end_date
          description
          access_date
        }
      }
      user {
        id
        first_name
        last_name
        picture
        ex_user_id
      }
    }

    admin: user(where: { contact_id: { _eq: $contactID } }) {
      first_name
      last_name
      picture
    }
  }
`

export const loginCredentials = ({ username, password }) => {
  return new Promise((resolve, reject) => {
    var urlencoded = new URLSearchParams()
    urlencoded.append('username', username)
    urlencoded.append('password', password)
    urlencoded.append('issueJWT', 'true')
    urlencoded.append('includeRoles', 'true')
    urlencoded.append('refreshableJWT', 'true')

    fetch(`${authServer}/wp-json/aam/v2/authenticate`, {
      method: 'POST',
      body: urlencoded,
    })
      .then((resp) => resp.json())
      .then((wpData) => {
        if (wpData.user && wpData.jwt) {
          const user = wpData.user.data

          if (!user.roles.includes('coach') && !user.roles.includes('meal_planner') && !user.roles.includes('reviewer')) {
            return reject("Sorry, you don't have access")
          } else {
            setCookie(COOKIE.USER_TOKEN_EXPIRE_TIME, 'Refresh token on 24 hours ', { expires: '24h' })
            setCookie(COOKIE.USER_TOKEN, `Bearer ${wpData.jwt.token}`, { expires: moment.unix(wpData.jwt.token_expires).toDate() })
            apolloClient
              .query({
                variables: { id: `${user.contact_id}`, actualWeekStart },
                query: gql`
                  query($id: String!, $actualWeekStart: date!) {
                    user(where: { contact_id: { _eq: $id } }) {
                      id
                      picture
                      email
                      coach {
                        id
                        is_master
                        is_meal_planner
                        units
                        assigned(where: { program: { start_date: { _lte: $actualWeekStart } } }, order_by: { program: { start_date: desc } }) {
                          program {
                            program_id
                            description
                            start_date
                            end_date
                            close_date
                            duration
                            type
                            access_date
                          }
                        }
                      }
                    }
                  }
                `,
              })
              .then(({ data }) => {
                const { id, coach, picture, email } = data.user[0] || {}

                if (!!!coach) {
                  return reject("You don't have a coach profile configured yet.")
                }

                const coach_id = coach.id
                const bootcamp = coach.assigned[0].program

                if (!!!bootcamp) {
                  return reject('Your profile is empty, please come back later.')
                }

                const { program_id, start_date, end_date, close_date, description, access_date } = bootcamp
                const is_master = data.user[0].coach.is_master
                const units = data.user[0].coach.units
                const is_meal_planner = data.user[0].coach.is_meal_planner

                localStorage.setItem(LOCAL_STORAGE.EX_USER_ID, user.contact_id)
                localStorage.setItem(LOCAL_STORAGE.USER_ID, id)
                localStorage.setItem(LOCAL_STORAGE.AS_COACH, true)
                localStorage.setItem(LOCAL_STORAGE.COACH_ID, coach_id)
                localStorage.setItem(LOCAL_STORAGE.ACCESS_DATE, access_date)
                localStorage.setItem(LOCAL_STORAGE.USER_NICKNAME, user.display_name)
                localStorage.setItem(LOCAL_STORAGE.USER_PICTURE, picture)
                localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_ID, program_id)
                localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_START_DATE, start_date)
                localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_END_DATE, end_date)
                localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_CLOSE_DATE, close_date)
                localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_DESCRIPTION, description)
                localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_TYPE, bootcamp.type)
                localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_ACCESS_DATE, bootcamp.access_date)
                localStorage.setItem(LOCAL_STORAGE.USER_ROLES, user.roles)
                localStorage.setItem(LOCAL_STORAGE.IS_MASTER, is_master)
                localStorage.setItem(LOCAL_STORAGE.IS_MEAL_PLANNER, is_meal_planner)
                localStorage.setItem(LOCAL_STORAGE.USER_EMAIL, email)
                localStorage.setItem(LOCAL_STORAGE.COACH_UNITS, units)

                apolloClient.writeData({
                  data: {
                    User: {
                      as_coach: true,
                      nickname: user.display_name,
                      picture,
                      id,
                      ex_user_id: user.contact_id,
                      roles: user.roles,
                      coach_id,
                      __typename: 'User',
                    },
                    Bootcamp: {
                      id: program_id,
                      start_date,
                      end_date,
                      close_date,
                      description,
                      program_type: bootcamp.type,
                      __typename: 'Bootcamp',
                      access_date: bootcamp.access_date,
                    },
                  },
                })
                resolve()
              })
          }
        } else {
          let message = wpData.message || wpData.reason || 'Something failed please try again'
          if (wpData.code) {
            message = 'Wrong email or password.'
          }
          reject(message)
        }
      })
      .catch((error) => reject(`${error}`))
  })
}

export const logout = async (withRedirect = true) => {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  const oldToken = getCookie(COOKIE.USER_TOKEN)
  if (oldToken) {
    await fetch(`${authServer}/wp-json/aam/v2/jwt/revoke`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ jwt: oldToken.replace('Bearer ', '') }),
    })
      .then((resp) => resp.json())
      .then((wpData) => {
        console.log('wpData :>> ', wpData)
      })
      .catch((error) => {
        console.log('error :>> ', error)
      })
  }

  localStorage.clear()
  removeCookie(COOKIE.API_TOKEN)
  removeCookie(COOKIE.USER_TOKEN)
  removeCookie(COOKIE.USER_TOKEN_EXPIRE_TIME)
  if (withRedirect) window.location = '/login'
}

export const renewToken = async () => {
  apolloClient.writeData({
    data: {
      App: {
        loading: true,
        __typename: 'Application',
      },
    },
  })
  return new Promise(async (resolve, reject) => {
    let result = {}
    const oldToken = getCookie(COOKIE.USER_TOKEN)
    if (!getCookie(COOKIE.USER_TOKEN_EXPIRE_TIME) && !oldToken) {
      result = { hasError: true, message: 'We could not refresh your credentials' }
    } else {
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')

      await fetch(`${authServer}/wp-json/aam/v2/jwt/refresh`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ jwt: oldToken.replace('Bearer ', '') }),
      })
        .then((resp) => resp.json())
        .then((wpData) => {
          if (wpData.token && wpData.token_expires) {
            setCookie(COOKIE.USER_TOKEN_EXPIRE_TIME, 'Refresh token on 24 hours ', { expires: '2h' })
            setCookie(COOKIE.USER_TOKEN, `Bearer ${wpData.token}`, {
              expires: moment.unix(wpData.token_expires).toDate(),
            })

            result = { hasError: false, token: wpData.token }
          } else {
            result = { hasError: true, message: 'We could not refresh your credentials' }
          }
        })
        .catch((error) => {
          console.log('error :>> ', error)
          result = { hasError: true, message: 'We could not refresh your credentials' }
        })
    }

    if (result.hasError) {
      message.error(`Could not get a new token (${result.message}).`)
      logout()
      reject(result.message)
      return
    } else {
      apolloClient.writeData({
        data: {
          App: {
            loading: false,
            __typename: 'Application',
          },
        },
      })
      resolve(`Bearer ${result.token}`)
    }
  })
}

export const impersonate = async (jwt, isAdmin, key) => {
  return new Promise((resolve, reject) => {
    const urlencoded = new URLSearchParams()
    urlencoded.append('jwt', jwt)

    fetch(`${authServer}/wp-json/aam/v2/jwt/validate`, {
      method: 'POST',
      body: urlencoded,
    })
      .then((response) => response.json())
      .then(async (wpData) => {
        if (wpData.userId) {
          if (getCookie(COOKIE.USER_TOKEN) && wpData.email !== localStorage.getItem('USER_EMAIL')) {
            await logout(false)
          }

          let expiration = '24h'

          if (isAdmin) {
            expiration = '1h'
            localStorage.setItem('IMPERSONATE_USER', true)
          }

          setCookie(COOKIE.USER_TOKEN_EXPIRE_TIME, 'Refresh token on 24 hours ', { expires: expiration })
          setCookie(COOKIE.USER_TOKEN, `Bearer ${jwt}`, { expires: moment.unix(wpData.exp).toDate() })

          apolloClient
            .query({
              query: getUserData,
              variables: { id: `${wpData.contact_id}`, email: wpData.email, today },
            })
            .then(({ data }) => {
              if (data.user.length <= 0) {
                reject('User inactive')
                return
              }

              const roles = wpData['https://hasura.io/jwt/claims']['x-hasura-allowed-roles']
              const defaultRole = wpData['https://hasura.io/jwt/claims']['x-hasura-default-role']

              if (roles.includes('admin') && defaultRole === 'admin') {
                if (!getCookie(COOKIE.USER_TOKEN_EXPIRE_TIME)) {
                  setCookie(COOKIE.USER_TOKEN_EXPIRE_TIME, 'Refresh token on 24 hours ', { expires: '24h' })
                }

                apolloClient
                  .query({
                    query: login,
                    variables: {
                      id: key,
                      contactID: String(wpData.contact_id),
                    },
                    fetchPolicy: 'no-cache',
                  })
                  .then(({ data }) => {
                    const {
                      is_master,
                      is_meal_planner,
                      units,
                      user: { id, ex_user_id, first_name, last_name, picture },
                      assigned: [
                        {
                          program: { description, start_date, end_date, program_id, close_date, access_date },
                        },
                      ],
                    } = data.coach[0]
                    const { first_name: admin_name, last_name: admin_last_name, picture: admin_picture } = data.admin[0]
                    const admin = {
                      nickname: admin_name + ' ' + admin_last_name,
                      picture: admin_picture,
                    }

                    if (roles.indexOf('coach') === -1) roles.push('coach')
                    if (is_master && roles.indexOf('reviewer') === -1) roles.push('reviewer')
                    if (is_meal_planner && roles.indexOf('meal_planner') === -1) roles.push('meal_planner')

                    localStorage.setItem(LOCAL_STORAGE.USER_ID, id)
                    localStorage.setItem(LOCAL_STORAGE.EX_USER_ID, ex_user_id)
                    localStorage.setItem(LOCAL_STORAGE.COACH_ID, key)
                    localStorage.setItem(LOCAL_STORAGE.IS_MASTER, is_master)
                    localStorage.setItem(LOCAL_STORAGE.USER_NICKNAME, first_name + ' ' + last_name)
                    localStorage.setItem(LOCAL_STORAGE.USER_PICTURE, picture)
                    localStorage.setItem(LOCAL_STORAGE.USER_ROLES, roles)
                    localStorage.setItem(LOCAL_STORAGE.ADMIN_NICKNAME, admin.nickname)
                    localStorage.setItem(LOCAL_STORAGE.ADMIN_PICTURE, admin_picture)
                    localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_ID, program_id)
                    localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_START_DATE, start_date)
                    localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_ACCESS_DATE, access_date)
                    localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_END_DATE, end_date)
                    localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_CLOSE_DATE, close_date)
                    localStorage.setItem(LOCAL_STORAGE.BOOTCAMP_DESCRIPTION, description)
                    localStorage.setItem(LOCAL_STORAGE.IS_MEAL_PLANNER, is_meal_planner)
                    localStorage.setItem(LOCAL_STORAGE.AS_COACH, false)
                    localStorage.setItem(LOCAL_STORAGE.CURRENT_TAB, '1')
                    localStorage.setItem(LOCAL_STORAGE.COACH_UNITS, units)
                    resolve(`/${key}`)
                  })
              } else {
                reject('You do not have administrator permissions')
              }
            })
        } else {
          reject(wpData.reason ? wpData.reason : 'We could not validate your credentials, please try again.')
        }
      })
      .catch((error) => {
        console.log('error :>> ', error)
        reject('We could not validate your credentials')
      })
  })
}
