import { Button, Modal } from 'antd'
import styled from '@emotion/styled'
import completedBgUrl from 'images/polygon_green.svg'

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    &::after {
      content: ${`url(${completedBgUrl})`};
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .ant-modal-body {
    height: 441px;
    padding: 10px;
  }
  .ant-modal-footer {
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    z-index: 1;
    border-top: none;
    text-align: center;
  }
`

export const ModalContent = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: 70px;
  color: #ffffff;
  font-size: 29px;
  text-transform: uppercase;
  letter-spacing: 2px;
  span {
    display: block;
    font-size: 14px;
    letter-spacing: 1px;
  }
  svg {
    width: 50px;
    height: 50px;
    fill: #ffffff;
    margin-bottom: 30px;
  }
`
export const StyledButton = styled(Button)`
  &.ant-btn {
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
    padding-left: 0;
    padding-right: 0;
    width: 140px;
    color: #ffffff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid #ffffff;
    background-color: transparent;
    border-radius: 24px;
    &:hover,
    &:active {
      border-color: #ffffff;
    }
  }
`
