import React from 'react'
import ketogainsTm from 'images/ketogains-tm.svg'
import { AppVersion } from 'constants.js'
import { Root, Img, Version } from './Logo.styled'

class Logo extends React.Component {
  render() {
    return (
      <Root>
        {/* <StyledLink to="/"> */}
        <Img src={ketogainsTm} alt="" />
        {/* </StyledLink> */}
        {this.props.showversion ? <Version>{AppVersion}</Version> : ''}
      </Root>
    )
  }
}

export default Logo
