import React, { useState, useEffect } from 'react'
import { StyledButton } from '../UserDashboard/UserDashboard.styled'
import { getProteins, getCarbs, getFat, getTotal } from './Uses'
import { Card, Input } from 'antd'
import './index.scss'
export const AlternateTableMacro = ({ active, title, handleChange, coachIsMaster, macroAlternate }) => {
  const [activated, setActive] = useState(false)
  useEffect(
    () => {
      setActive(active)
    },
    [active],
  )

  const handleActivateAlternateMacros = async () => {
    setActive(!activated)
  }

  const AlternateMacrosButton = ({ handleActivateAlternateMacros }) => (
    <StyledButton custom="large" className="alternate-macros-button" onClick={handleActivateAlternateMacros}>
      Activate Alternate Macros
    </StyledButton>
  )

  const handleAlternateMacrosChange = (name, value, macroAlternate) => {
    let {
      protein_base_grams,
      protein_pre_workout_grams,
      protein_kcal_gram,
      carbs_base_grams,
      carbs_pre_workout_grams,
      carbs_kcal_gram,
      fat_base_grams,
      fat_pre_workout_grams,
      fat_kcal_gram,
      protein_daily_kcals,
      carbs_daily_kcals,
      fat_daily_kcals,
      total_daily_kcals,
    } = macroAlternate

    let total_grams = ''
    let total_grams_value = ''
    let daily_kcals = ''
    let daily_kcals_value = 0
    let total_daily = ''
    let total_daily_value = 0
    let protein_percent_calories = ''
    let protein_percent_calories_value = 0
    let carbs_percent_calories = ''
    let carbs_percent_calories_value = 0
    let fat_percent_calories = ''
    let fat_percent_calories_value = 0
    let total_daily_percent = ''
    let total_daily_percent_value = 0

    if (name.includes('protein')) {
      total_grams = 'protein_total_grams'
      total_grams_value =
        name === 'protein_base_grams' ? parseFloat(protein_pre_workout_grams) + parseFloat(value) : parseFloat(protein_base_grams) + parseFloat(value)
      daily_kcals = 'protein_daily_kcals'
      daily_kcals_value = total_grams_value * protein_kcal_gram
      total_daily = 'total_daily_kcals'
      total_daily_value = parseFloat(total_daily_kcals - protein_daily_kcals) + parseFloat(daily_kcals_value)
      protein_percent_calories = 'protein_percent_calories'
      protein_percent_calories_value = Math.round((daily_kcals_value / total_daily_value) * 100)
      carbs_percent_calories = 'carbs_percent_calories'
      carbs_percent_calories_value = Math.round((carbs_daily_kcals / total_daily_value) * 100)
      fat_percent_calories = 'fat_percent_calories'
      fat_percent_calories_value = Math.round((fat_daily_kcals / total_daily_value) * 100)
      total_daily_percent = 'total_percent_calories'
      total_daily_percent_value = protein_percent_calories_value + carbs_percent_calories_value + fat_percent_calories_value

      if (total_daily_percent_value !== 100) {
        if (total_daily_percent_value === 101) {
          protein_percent_calories_value -= 1
          total_daily_percent_value -= 1
        } else if (total_daily_percent_value === 99) {
          protein_percent_calories_value += 1
          total_daily_percent_value += 1
        }
      }
    } else if (name.includes('carbs')) {
      total_grams = 'carbs_total_grams'
      total_grams_value =
        name === 'carbs_base_grams' ? parseFloat(carbs_pre_workout_grams) + parseFloat(value) : parseFloat(carbs_base_grams) + parseFloat(value)
      daily_kcals = 'carbs_daily_kcals'
      daily_kcals_value = total_grams_value * carbs_kcal_gram
      total_daily = 'total_daily_kcals'
      total_daily_value = parseFloat(total_daily_kcals - carbs_daily_kcals) + parseFloat(daily_kcals_value)
      protein_percent_calories = 'protein_percent_calories'
      protein_percent_calories_value = Math.round((protein_daily_kcals / total_daily_value) * 100)
      carbs_percent_calories = 'carbs_percent_calories'
      carbs_percent_calories_value = Math.round((daily_kcals_value / total_daily_value) * 100)
      fat_percent_calories = 'fat_percent_calories'
      fat_percent_calories_value = Math.round((fat_daily_kcals / total_daily_value) * 100)
      total_daily_percent = 'total_percent_calories'
      total_daily_percent_value = protein_percent_calories_value + carbs_percent_calories_value + fat_percent_calories_value

      if (total_daily_percent_value !== 100) {
        if (total_daily_percent_value === 101) {
          protein_percent_calories_value -= 1
          total_daily_percent_value -= 1
        } else if (total_daily_percent_value === 99) {
          protein_percent_calories_value += 1
          total_daily_percent_value += 1
        }
      }
    } else if (name.includes('fat')) {
      total_grams = 'fat_total_grams'
      total_grams_value =
        name === 'fat_base_grams' ? parseFloat(fat_pre_workout_grams) + parseFloat(value) : parseFloat(fat_base_grams) + parseFloat(value)
      daily_kcals = 'fat_daily_kcals'
      daily_kcals_value = total_grams_value * fat_kcal_gram
      total_daily = 'total_daily_kcals'
      total_daily_value = parseFloat(total_daily_kcals - fat_daily_kcals) + parseFloat(daily_kcals_value)
      protein_percent_calories = 'protein_percent_calories'
      protein_percent_calories_value = Math.round((protein_daily_kcals / total_daily_value) * 100)
      carbs_percent_calories = 'carbs_percent_calories'
      carbs_percent_calories_value = Math.round((carbs_daily_kcals / total_daily_value) * 100)
      fat_percent_calories = 'fat_percent_calories'
      fat_percent_calories_value = Math.round((daily_kcals_value / total_daily_value) * 100)
      total_daily_percent = 'total_percent_calories'
      total_daily_percent_value = protein_percent_calories_value + carbs_percent_calories_value + fat_percent_calories_value

      if (total_daily_percent_value !== 100) {
        if (total_daily_percent_value === 101) {
          protein_percent_calories_value -= 1
          total_daily_percent_value -= 1
        } else if (total_daily_percent_value === 99) {
          protein_percent_calories_value += 1
          total_daily_percent_value += 1
        }
      }
    }

    const macros = {
      [name]: value,
      [total_grams]: total_grams_value,
      [daily_kcals]: daily_kcals_value,
      [total_daily]: total_daily_value,
      [protein_percent_calories]: protein_percent_calories_value,
      [carbs_percent_calories]: carbs_percent_calories_value,
      [fat_percent_calories]: fat_percent_calories_value,
      [total_daily_percent]: total_daily_percent_value,
    }

    handleChange(macros)
  }

  let proteins = getProteins(macroAlternate)
  let carbs = getCarbs(macroAlternate)
  let fat = getFat(macroAlternate)
  let total = getTotal(macroAlternate)
  let labels = {
    first: 'BASE MACRO G',
    second: 'TOTAL GRAMS',
    third: 'KCAL / G',
    fourth: 'DAILY KCALS',
    fifth: 'TOTAL KCAL %',
  }

  return (
    <Card
      title={title}
      className={activated ? 'alternate-macros-active macros-card card' : 'alternate-macros macros-card card'}
      extra={<AlternateMacrosButton handleActivateAlternateMacros={handleActivateAlternateMacros} />}
    >
      {coachIsMaster === 'true' ? (
        <div>
          <div className="macros-card-labels">
            <span className="macros-card-label">macros</span>
            <span className="macros-card-label">{labels.first}</span>
            <span className="macros-card-label">Pre-Workout G</span>
            <span className="macros-card-label">{labels.second}</span>
            <span className="macros-card-label">{labels.third}</span>
            <span className="macros-card-label">{labels.fourth}</span>
            <span className="macros-card-label">{labels.fifth}</span>
          </div>

          {activated ? (
            <div className="macros-card-meassurements macros-card-proteins">
              <span className="macros-card-data label-left">Protein</span>
              <Input
                className="macros-card-data"
                value={proteins.baseMacro}
                name="protein_base_grams"
                onChange={(e) => {
                  handleAlternateMacrosChange(e.target.name, e.target.value !== '' ? e.target.value : 0, macroAlternate)
                }}
                size="small"
              />
              <Input
                className="macros-card-data"
                value={proteins.preWorkout}
                name="protein_pre_workout_grams"
                onChange={(e) => {
                  handleAlternateMacrosChange(e.target.name, e.target.value !== '' ? e.target.value : 0, macroAlternate)
                }}
                size="small"
              />
              <span className="macros-card-data">{proteins.totalGrams}</span>
              <span className="macros-card-data">{4}</span>
              <span className="macros-card-data">{proteins.dailyKcals}</span>
              <span className="macros-card-data">{proteins.totalKcals}%</span>
            </div>
          ) : (
            <div className="macros-card-meassurements macros-card-proteins">
              <span className="macros-card-data label-left">Protein</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data proteins">{0}</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data">{0}%</span>
            </div>
          )}
          {activated ? (
            <div className="macros-card-meassurements macros-card-carbs">
              <span className="macros-card-data label-left">Carbs (NET)</span>
              <Input
                className="macros-card-data"
                value={carbs.baseMacro}
                name="carbs_base_grams"
                onChange={(e) => {
                  handleAlternateMacrosChange(e.target.name, e.target.value !== '' ? e.target.value : 0, macroAlternate)
                }}
                size="small"
              />
              <Input
                className="macros-card-data"
                value={carbs.preWorkout}
                name="carbs_pre_workout_grams"
                onChange={(e) => {
                  handleAlternateMacrosChange(e.target.name, e.target.value !== '' ? e.target.value : 0, macroAlternate)
                }}
                size="small"
              />
              <span className="macros-card-data">{carbs.totalGrams}</span>
              <span className="macros-card-data">{4}</span>
              <span className="macros-card-data">{carbs.dailyKcals}</span>
              <span className="macros-card-data">{carbs.totalKcals}%</span>
            </div>
          ) : (
            <div className="macros-card-meassurements macros-card-carbs">
              <span className="macros-card-data label-left">Carbs (NET)</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data proteins">{0}</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data">{0}%</span>
            </div>
          )}
          {activated ? (
            <div className="macros-card-meassurements macros-card-fat">
              <span className="macros-card-data label-left">Fat</span>
              <Input
                className="macros-card-data"
                value={fat.baseMacro}
                name="fat_base_grams"
                onChange={(e) => {
                  handleAlternateMacrosChange(e.target.name, e.target.value !== '' ? e.target.value : 0, macroAlternate)
                }}
                size="small"
              />
              <Input
                className="macros-card-data"
                value={fat.preWorkout}
                name="fat_pre_workout_grams"
                onChange={(e) => {
                  handleAlternateMacrosChange(e.target.name, e.target.value !== '' ? e.target.value : 0, macroAlternate)
                }}
                size="small"
              />
              <span className="macros-card-data">{fat.totalGrams}</span>
              <span className="macros-card-data">{9}</span>
              <span className="macros-card-data">{fat.dailyKcals}</span>
              <span className="macros-card-data">{fat.totalKcals}%</span>
            </div>
          ) : (
            <div className="macros-card-meassurements macros-card-fat">
              <span className="macros-card-data label-left">Fat</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data proteins">{0}</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data">{0}</span>
              <span className="macros-card-data">{0}%</span>
            </div>
          )}
          {activated ? (
            <div className="macros-card-totals">
              <span className="macros-card-total total-label">TOTAL DAILY</span>
              <span className="macros-card-total">{total.dailyKcals}</span>
              <span className="macros-card-total">{total.totalKcals}%</span>
            </div>
          ) : (
            <div className="macros-card-totals">
              <span className="macros-card-total total-label">TOTAL DAILY</span>
              <span className="macros-card-total">{0}</span>
              <span className="macros-card-total">{0}%</span>
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="macros-card-labels">
            <span className="macros-card-label">macros</span>
            <span className="macros-card-label">{labels.first}</span>
            <span className="macros-card-label">Pre-Workout G</span>
            <span className="macros-card-label">{labels.second}</span>
            <span className="macros-card-label">{labels.third}</span>
            <span className="macros-card-label">{labels.fourth}</span>
            <span className="macros-card-label">{labels.fifth}</span>
          </div>
          <div className="macros-card-meassurements macros-card-proteins">
            <span className="macros-card-data label-left">Protein</span>
            <span className="macros-card-data" onChange={() => handleAlternateMacrosChange(null, null, macroAlternate)}>
              {proteins.baseMacro}
            </span>
            <span className="macros-card-data">{proteins.preWorkout}</span>
            <span className="macros-card-data">{proteins.totalGrams}</span>
            <span className="macros-card-data">{proteins.kcalG}</span>
            <span className="macros-card-data">{proteins.dailyKcals}</span>
            <span className="macros-card-data">{proteins.totalKcals}%</span>
          </div>
          <div className="macros-card-meassurements macros-card-carbs">
            <span className="macros-card-data label-left">Carbs (NET)</span>
            <span className="macros-card-data">{carbs.baseMacro}</span>
            <span className="macros-card-data">{carbs.preWorkout}</span>
            <span className="macros-card-data">{carbs.totalGrams}</span>
            <span className="macros-card-data">{carbs.kcalG}</span>
            <span className="macros-card-data">{carbs.dailyKcals}</span>
            <span className="macros-card-data">{carbs.totalKcals}%</span>
          </div>
          <div className="macros-card-meassurements macros-card-fat">
            <span className="macros-card-data label-left">Fat</span>
            <span className="macros-card-data">{fat.baseMacro}</span>
            <span className="macros-card-data">{fat.preWorkout}</span>
            <span className="macros-card-data">{fat.totalGrams}</span>
            <span className="macros-card-data">{fat.kcalG}</span>
            <span className="macros-card-data">{fat.dailyKcals}</span>
            <span className="macros-card-data">{fat.totalKcals}%</span>
          </div>
          <div className="macros-card-totals">
            <span className="macros-card-total total-label">TOTAL DAILY</span>
            <span className="macros-card-total">{total.dailyKcals}</span>
            <span className="macros-card-total">{total.totalKcals}%</span>
          </div>
        </div>
      )}
    </Card>
  )
}
