import React from 'react'
import { Card, Input } from 'antd'
import { getProteins, getCarbs, getFat, getTotal } from './Uses'

export const TableMacro = ({ title, className, coachIsMaster, handleChange, macros }) => {
  let proteins = getProteins(macros)
  let carbs = getCarbs(macros)
  let fat = getFat(macros)
  let total = getTotal(macros)
  let labels = {
    first: 'BASE MACRO G',
    second: 'TOTAL GRAMS',
    third: 'KCAL / G',
    fourth: 'DAILY KCALS',
    fifth: 'TOTAL KCAL %',
  }

  return (
    <Card title={title} className={className ? `${className} macros-card card` : 'macros-card card'}>
      <div>
        <div className="macros-card-labels">
          <span className="macros-card-label">macros</span>
          <span className="macros-card-label">{labels.first}</span>
          {className !== 'rest-day-macros' && <span className="macros-card-label">Pre-Workout G</span>}
          <span className="macros-card-label">{labels.second}</span>
          <span className="macros-card-label">{labels.third}</span>
          <span className="macros-card-label">{labels.fourth}</span>
          <span className="macros-card-label">{labels.fifth}</span>
        </div>
        <div className="macros-card-meassurements macros-card-proteins">
          <span className="macros-card-data label-left">Protein</span>
          {coachIsMaster !== 'true' && (
            <span className="macros-card-data" onChange={handleChange}>
              {proteins.baseMacro}
            </span>
          )}

          {coachIsMaster !== 'true' && className !== 'rest-day-macros' && <span className="macros-card-data">{proteins.preWorkout}</span>}

          {coachIsMaster === 'true' && (
            <Input
              className="macros-card-data"
              value={proteins.baseMacro}
              name="protein_base_grams"
              onChange={(e) => {
                handleChange(e.target.name, e.target.value !== '' ? e.target.value : 0)
              }}
              size="small"
            />
          )}
          {coachIsMaster === 'true' && className !== 'rest-day-macros' && (
            <Input
              className="macros-card-data"
              value={proteins.preWorkout}
              name="protein_pre_workout_grams"
              onChange={(e) => {
                handleChange(e.target.name, e.target.value !== '' ? e.target.value : 0)
              }}
              size="small"
            />
          )}

          <span className="macros-card-data">{proteins.totalGrams}</span>
          <span className="macros-card-data">{proteins.kcalG}</span>
          <span className="macros-card-data">{proteins.dailyKcals}</span>
          <span className="macros-card-data">{proteins.totalKcals}%</span>
        </div>
        <div className="macros-card-meassurements macros-card-carbs">
          <span className="macros-card-data label-left">Carbs (NET)</span>
          {coachIsMaster !== 'true' && <span className="macros-card-data">{carbs.baseMacro}</span>}
          {coachIsMaster !== 'true' && className !== 'rest-day-macros' && <span className="macros-card-data">{carbs.preWorkout}</span>}

          {coachIsMaster === 'true' && (
            <Input
              className="macros-card-data"
              value={carbs.baseMacro}
              name="carbs_base_grams"
              onChange={(e) => {
                handleChange(e.target.name, e.target.value !== '' ? e.target.value : 0)
              }}
              size="small"
            />
          )}

          {coachIsMaster === 'true' && className !== 'rest-day-macros' && (
            <Input
              className="macros-card-data"
              value={carbs.preWorkout}
              name="carbs_pre_workout_grams"
              onChange={(e) => {
                handleChange(e.target.name, e.target.value !== '' ? e.target.value : 0)
              }}
              size="small"
            />
          )}
          <span className="macros-card-data">{carbs.totalGrams}</span>
          <span className="macros-card-data">{carbs.kcalG}</span>
          <span className="macros-card-data">{carbs.dailyKcals}</span>
          <span className="macros-card-data">{carbs.totalKcals}%</span>
        </div>
        <div className="macros-card-meassurements macros-card-fat">
          <span className="macros-card-data label-left">Fat</span>
          {coachIsMaster !== 'true' && <span className="macros-card-data">{fat.baseMacro}</span>}

          {coachIsMaster !== 'true' && className !== 'rest-day-macros' && <span className="macros-card-data">{fat.preWorkout}</span>}

          {coachIsMaster === 'true' && (
            <Input
              className="macros-card-data"
              value={fat.baseMacro}
              name="fat_base_grams"
              onChange={(e) => {
                handleChange(e.target.name, e.target.value !== '' ? e.target.value : 0)
              }}
              size="small"
            />
          )}

          {coachIsMaster === 'true' && className !== 'rest-day-macros' && (
            <Input
              className="macros-card-data"
              value={fat.preWorkout}
              name="fat_pre_workout_grams"
              onChange={(e) => {
                handleChange(e.target.name, e.target.value !== '' ? e.target.value : 0)
              }}
              size="small"
            />
          )}
          <span className="macros-card-data">{fat.totalGrams}</span>
          <span className="macros-card-data">{fat.kcalG}</span>
          <span className="macros-card-data">{fat.dailyKcals}</span>
          <span className="macros-card-data">{fat.totalKcals}%</span>
        </div>
        <div className="macros-card-totals">
          <span className="macros-card-total total-label">TOTAL DAILY</span>
          <span className="macros-card-total">{total.dailyKcals}</span>
          <span className="macros-card-total">{total.totalKcals}%</span>
        </div>
      </div>
    </Card>
  )
}
