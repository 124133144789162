import styled from '@emotion/styled'

export const WeeklyReportContainer = styled.div`
  overflow: auto;

  .more {
    border: 1px solid #ff6b00;
    background: #ff6b00;
    border-radius: 5px;
    color: #ffffff;
    font-size: 8px;
    padding: 1px 3px;
    cursor: pointer;
    margin-right: 5px;
    margin-left: 4px;
  }
  p.subtitle {
    color: #383838;
    font-size: 10px;
    font-weight: 600;
    white-space: nowrap;

    .anticon {
      color: #ff6b00;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #ff6b00;
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }

  .ant-empty {
    margin: 0;
  }

  .recovery-exertion {
    color: #383838;
    font-size: 8px;
    font-weight: 600;
  }
`

export const DayNote = styled.div`
  padding: 5px 0px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(176, 176, 176, 0.57);
  border-radius: 2px;
  text-align: center;
  cursor: pointer;

  p {
    font-size: 8px;
    color: #5c7ce2;
    font-weight: 600;
    margin-bottom: 0px;
  }
`

export const MacroTableTitle = styled.div`
  &.rest-day {
    background: #5c7ce2;
  }
  &.training-day {
    background: #ff6b00;
  }
  transform: rotate(180deg);
  font-size: 7px;
  text-align: center;
  margin: 5px;
  border-radius: 3px;
  font-weight: 600;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  padding: 5px 3px;
  color: #ffffff;
`

export const MacroTable = styled.table`
  width: 100%;
  thead {
    th {
      font-size: 7px;
      color: #959595;
      font-weight: 600;
      text-align: center;
    }
  }
  tbody {
    td {
      font-size: 10px;
      margin-bottom: 3px;
      border-bottom: 1px solid #ececec;
      text-align: center;

      .ant-badge {
        .ant-badge-status-text {
          font-size: 10px;
        }
      }

      .row-title {
        border-radius: 3px;
        color: #ffffff;
        font-size: 7px;
        font-weight: 600;
        padding: 3px 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        display: inline-block;
        vertical-align: middle;

        &.protein,
        &.prot {
          background-color: #fa682b;
        }

        &.carbs {
          background-color: #ffad00;
        }

        &.fat {
          background-color: #0095cc;
        }
      }
    }
  }
`
