import React, { Component } from 'react'
import { Row, Col, Radio } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import DashboardContext from 'context/DashboardContext'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts'
import { StyledRadioGroupButton } from 'styled'
import { BenchmarkCard, BootcampOverviewTable, BootcampOverviewGraph, BootcampOverviewGraphTooltip } from './BootcampOverview.styled'

const BodyFat = (units, gender, neck, belly_waist, hip, height, narrow_waist) => {
  let bodyFat

  if (units === 'kg/cm') {
    height = height / 2.54
    neck = neck / 2.54
    belly_waist = belly_waist / 2.54
    narrow_waist = narrow_waist / 2.54
    hip = hip / 2.54
  }
  const metricHeight = height
  const metricNeck = neck
  const metricBellyWaist = belly_waist
  const metricNarrowWaist = narrow_waist
  const metricHip = hip

  switch (gender) {
    case 'male':
      bodyFat = 86.01 * Math.log10(metricBellyWaist - metricNeck) - 70.041 * Math.log10(metricHeight) + 36.76
      break
    case 'female':
      bodyFat = 163.205 * Math.log10(metricNarrowWaist + metricHip - metricNeck) - 97.684 * Math.log10(metricHeight) - 78.387
      break
    default:
      return null
  }

  bodyFat = bodyFat.toFixed(0.01)

  if (bodyFat === '-Infinity' || bodyFat === 'NaN' || bodyFat === 'Infinity') {
    bodyFat = 0
  }

  return bodyFat
}

const GraphTooltip = ({ active, payload, label, weeklies, units, student }) => {
  const coachUnits = localStorage.getItem('COACH_UNITS')

  let weightUnit = ''
  let heightUnit = ''

  if (coachUnits !== 'lbs/in' && student.units !== coachUnits) {
    weightUnit = 'Kg'
    heightUnit = 'cm'
  } else if (coachUnits !== 'kg/cm' && student.units !== coachUnits) {
    weightUnit = 'Lbs'
    heightUnit = 'In'
  } else {
    weightUnit = student.units.split('/')[0]
    heightUnit = student.units.split('/')[1]
  }

  const weekly = weeklies.find((week) => week.name === label)
  const enrollment = weeklies.find((week) => week.name === 'Enrollment')

  let weeklyBodyFat = BodyFat(
    student.units !== coachUnits ? coachUnits : units,
    student.gender,
    weekly.neck,
    weekly.belly_waist,
    weekly.hip,
    enrollment.height,
    weekly.narrow_waist,
  )

  let enrollmentBodyFat = BodyFat(
    student.units !== coachUnits ? coachUnits : units,
    student.gender,
    enrollment.neck,
    enrollment.belly_waist,
    enrollment.hip,
    enrollment.height,
    enrollment.narrow_waist,
  )

  const row = (label, enrollment, reported, unitLabel, color, isMainRow) => {
    return (
      <tr className={isMainRow ? `main-row ${color}` : ''}>
        <td className="fist-on-row">
          <span className={`${color} left-line`} />
          <b>
            <span>{label}</span>
          </b>
        </td>
        <td className="data-td">{enrollment ? `${enrollment} ${unitLabel}` : '-'}</td>
        <td className="data-td">
          <b>{reported ? `${reported} ${unitLabel}` : '-'}</b>
        </td>
      </tr>
    )
  }
  if (active && !!weekly) {
    return (
      <BootcampOverviewGraphTooltip>
        <table>
          <tbody>
            {row('Total Measures', enrollment.bodyLength, weekly.bodyLength, heightUnit, 'orange', true)}
            {row('Neck', enrollment.neck, weekly.neck, heightUnit, 'orange')}
            {row('Chest', enrollment.chest, weekly.chest, heightUnit, 'orange')}
            {row('Shoulder', enrollment.shoulder, weekly.shoulder, heightUnit, 'orange')}
            {row('Arm', enrollment.arm, weekly.arm, heightUnit, 'orange')}
            {row('Forearm', enrollment.forearm, weekly.forearm, heightUnit, 'orange')}
            {row('Waist (Navel)', enrollment.belly_waist, weekly.belly_waist, heightUnit, 'orange')}
            {row('Waist (Narrowest)', enrollment.narrow_waist, weekly.narrow_waist, heightUnit, 'orange')}
            {row('Hip', enrollment.hip, weekly.hip, heightUnit, 'orange')}
            {row('Thigh', enrollment.thigh, weekly.thigh, heightUnit, 'orange')}
            {row('Calf', enrollment.calf, weekly.calf, heightUnit, 'orange')}
            {row('Weight', enrollment.weight, weekly.weight, weightUnit, 'blue', true)}
            {row('Body Fat', enrollmentBodyFat, weeklyBodyFat, '%', 'blue')}
          </tbody>
        </table>
      </BootcampOverviewGraphTooltip>
    )
  }

  return null
}

const Graph = (props) => {
  const { data, handleClick, student } = props
  const coachUnits = localStorage.getItem('COACH_UNITS')

  let weightUnit = ''
  let heightUnit = ''

  if (coachUnits !== 'lbs/in' && student.units !== coachUnits) {
    weightUnit = 'Kg'
    heightUnit = 'cm'
  } else if (coachUnits !== 'kg/cm' && student.units !== coachUnits) {
    weightUnit = 'Lbs'
    heightUnit = 'In'
  } else {
    weightUnit = student.units.split('/')[0]
    heightUnit = student.units.split('/')[1]
  }

  return (
    <BootcampOverviewGraph style={{ height: '245px', marginTop: '5px' }}>
      <ResponsiveContainer debounce="300">
        <LineChart data={data} onClick={(e) => handleClick(e)}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name">
            <Label value="Weeks" offset={-7} position="bottom" />
          </XAxis>
          <YAxis domain={['dataMin - 50', 'dataMax + 50']} unit={` ${heightUnit}`} yAxisId="1" />
          <YAxis domain={[0, 'dataMax + 50']} unit={` ${weightUnit}`} yAxisId="2" orientation="right" />
          <Tooltip active={false} contentStyle={{ visibility: 'hidden' }} />
          <Legend />
          <Line yAxisId="1" type="monotone" dataKey="bodyLength" name="Total Body Measurements" stroke="#FF6B00" />
          <Line yAxisId="2" type="monotone" dataKey="weight" name="Weight" stroke="#0FC6BF" />
        </LineChart>
      </ResponsiveContainer>
    </BootcampOverviewGraph>
  )
}

const Table = (props) => {
  let { data, units, enrollment, student } = props

  const coachUnits = localStorage.getItem('COACH_UNITS')

  let weightUnit = ''
  let heightUnit = ''

  enrollment = recalculateMeasurements(enrollment, coachUnits, student)

  if (coachUnits !== 'lbs/in' && student.units !== coachUnits) {
    weightUnit = 'Kg'
    heightUnit = 'cm'
  } else if (coachUnits !== 'kg/cm' && student.units !== coachUnits) {
    weightUnit = 'Lbs'
    heightUnit = 'In'
  } else {
    weightUnit = student.units.split('/')[0]
    heightUnit = student.units.split('/')[1]
  }

  const columns = [
    { title: '', key: 'name', dataIndex: 'name', fixed: 'left', className: 'row-title', width: 65 },
    { title: 'Weight', key: 'weight', dataIndex: 'weight', className: 'weight' },
    { title: 'Body Fat', key: 'bodyFat', dataIndex: 'bodyFat', className: 'weight-details' },
    { title: 'Total Body Measures', key: 'totalBodyLength', dataIndex: 'totalBodyLength', className: 'total-body-length' },
  ]

  const dataSource = data.map((row, index) => {
    let bodyfat
    const weekly = data.find((week) => week.name === row.name)
    let weeklyBodyFat = BodyFat(
      student.units !== coachUnits ? coachUnits : units,
      student.gender,
      weekly.neck,
      weekly.belly_waist,
      weekly.hip,
      enrollment.height,
      weekly.narrow_waist,
    )

    let enrollmentBodyFat = BodyFat(
      student.units !== coachUnits ? coachUnits : units,
      student.gender,
      enrollment.neck,
      enrollment.belly_waist,
      enrollment.hip,
      enrollment.height,
      enrollment.narrow_waist,
    )

    if (row.name === 'Enrollment') {
      bodyfat = enrollmentBodyFat
    } else {
      bodyfat = weeklyBodyFat
    }

    return {
      key: index,
      name: row.name,
      weight: row.weight ? row.weight + ` ${weightUnit}` : '-',
      bodyFat: bodyfat ? bodyfat + ' %' : '-',
      totalBodyLength: row.bodyLength ? row.bodyLength + ` ${heightUnit}` : '-',
      neck: row.neck ? row.neck + ` ${heightUnit}` : '-',
      chest: row.chest ? row.chest + ` ${heightUnit}` : '-',
      shoulder: row.shoulder ? row.shoulder + ` ${heightUnit}` : '-',
      arm: row.arm ? row.arm + ` ${heightUnit}` : '-',
      forearm: row.forearm ? row.forearm + ` ${heightUnit}` : '-',
      belly_waist: row.belly_waist ? row.belly_waist + ` ${heightUnit}` : '-',
      narrow_waist: row.narrow_waist ? row.narrow_waist + ` ${heightUnit}` : '-',
      hip: row.hip ? row.hip + ` ${heightUnit}` : '-',
      thigh: row.thigh ? row.thigh + ` ${heightUnit}` : '-',
      calf: row.calf ? row.calf + ` ${heightUnit}` : '-',
    }
  })

  return <BootcampOverviewTable columns={columns} dataSource={dataSource} pagination={false} scroll={{ x: 250, y: 220 }} />
}

const Benchmark = (props) => {
  const { type, title, data, student } = props

  const coachUnits = localStorage.getItem('COACH_UNITS')
  let weightUnit = ''
  let heightUnit = ''

  if (coachUnits !== 'lbs/in' && student.units !== coachUnits) {
    weightUnit = 'Kg'
    heightUnit = 'cm'
  } else if (coachUnits !== 'kg/cm' && student.units !== coachUnits) {
    weightUnit = 'Lbs'
    heightUnit = 'In'
  } else {
    weightUnit = student.units.split('/')[0]
    heightUnit = student.units.split('/')[1]
  }

  let bodyLengthAfter = data.bodyLength.after
  let weightAfter = data.weight.after
  let bodyLengthBefore = data.bodyLength.before
  let weightBefore = data.weight.before

  if (title === 'Historical Change') {
    if (bodyLengthAfter !== null && (student.units !== coachUnits && coachUnits !== 'lbs/in')) {
      bodyLengthAfter = (bodyLengthAfter * 2.54).toFixed(2)
    } else if (bodyLengthAfter !== null && (student.units !== coachUnits && coachUnits !== 'kg/cm')) {
      bodyLengthAfter = (bodyLengthAfter / 2.54).toFixed(2)
    }
    if (weightAfter !== null && (student.units !== coachUnits && coachUnits !== 'lbs/in')) {
      weightAfter = (weightAfter / 2.205).toFixed(2)
    } else if (weightAfter !== null && (student.units !== coachUnits && coachUnits !== 'kg/cm')) {
      weightAfter = (weightAfter * 2.205).toFixed(2)
    }
  }

  return (
    <BenchmarkCard className={type}>
      <p className="title">{title}</p>
      <div className="content">
        <div className="left">
          <div>
            {data.bodyLength.after ? (
              <span>
                <small className="measure">H</small> {bodyLengthAfter} <small>{heightUnit}</small>
              </span>
            ) : (
              '-'
            )}
          </div>
          <div>
            {data.weight.after ? (
              <span>
                <small className="measure">W</small> {weightAfter} <small>{weightUnit}</small>
              </span>
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className="center">
          <span>to</span>
        </div>
        <div className="right">
          <div>
            {data.bodyLength.before ? (
              <span>
                {bodyLengthBefore} <small>{heightUnit}</small>
              </span>
            ) : (
              '-'
            )}
          </div>
          <div>
            {data.weight.before ? (
              <span>
                {weightBefore} <small>{weightUnit}</small>
              </span>
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>
    </BenchmarkCard>
  )
}

const recalculateMeasurements = (metric, coachUnits, student) => {
  let metricAux = JSON.parse(JSON.stringify(metric))
  if (student.units !== coachUnits) {
    let neck = metricAux.neck
    let chest = metricAux.chest
    let shoulder = metricAux.shoulder
    let arm = metricAux.arm
    let forearm = metricAux.forearm
    let belly_waist = metricAux.belly_waist
    let narrow_waist = metricAux.narrow_waist
    let hip = metricAux.hip
    let thigh = metricAux.thigh
    let calf = metricAux.calf
    let weight = metricAux.weight
    let height = metricAux.height

    if (coachUnits === 'kg/cm') {
      neck = parseFloat((metricAux.neck * 2.54).toFixed(2))
      chest = parseFloat((metricAux.chest * 2.54).toFixed(2))
      shoulder = parseFloat((metricAux.shoulder * 2.54).toFixed(2))
      arm = parseFloat((metricAux.arm * 2.54).toFixed(2))
      forearm = parseFloat((metricAux.forearm * 2.54).toFixed(2))
      belly_waist = parseFloat((metricAux.belly_waist * 2.54).toFixed(2))
      narrow_waist = parseFloat((metricAux.narrow_waist * 2.54).toFixed(2))
      hip = parseFloat((metricAux.hip * 2.54).toFixed(2))
      thigh = parseFloat((metricAux.thigh * 2.54).toFixed(2))
      calf = parseFloat((metricAux.calf * 2.54).toFixed(2))
      weight = parseFloat((metricAux.weight / 2.205).toFixed(2))
      height = parseFloat((metricAux.height * 2.54).toFixed(2))
    } else if (coachUnits === 'lbs/in') {
      neck = parseFloat((metricAux.neck / 2.54).toFixed(2))
      chest = parseFloat((metricAux.chest / 2.54).toFixed(2))
      shoulder = parseFloat((metricAux.shoulder / 2.54).toFixed(2))
      arm = parseFloat((metricAux.arm / 2.54).toFixed(2))
      forearm = parseFloat((metricAux.forearm / 2.54).toFixed(2))
      belly_waist = parseFloat((metricAux.belly_waist / 2.54).toFixed(2))
      narrow_waist = parseFloat((metricAux.narrow_waist / 2.54).toFixed(2))
      hip = parseFloat((metricAux.hip / 2.54).toFixed(2))
      thigh = parseFloat((metricAux.thigh / 2.54).toFixed(2))
      calf = parseFloat((metricAux.calf / 2.54).toFixed(2))
      weight = parseFloat((metricAux.weight * 2.205).toFixed(2))
      height = parseFloat((metricAux.height / 2.54).toFixed(2))
    }

    metricAux.neck = neck
    metricAux.chest = chest
    metricAux.shoulder = shoulder
    metricAux.arm = arm
    metricAux.forearm = forearm
    metricAux.belly_waist = belly_waist
    metricAux.narrow_waist = narrow_waist
    metricAux.hip = hip
    metricAux.thigh = thigh
    metricAux.calf = calf
    metricAux.weight && (metricAux.weight = weight)
    metricAux.height = height
  }
  return metricAux
}

class BootcampOverview extends Component {
  state = {
    view: 'table',
    activeLabel: null,
    activeLabelCount: '',
  }

  defaultWeek = (weeks) => {
    const weekToReview = moment()
      .subtract(1, 'weeks')
      .startOf('isoWeek')
      .format('YYYYMMDD')
    let weekly = weeks.find((week) => week.for_date === weekToReview)
    return weekly ? weekly.name : 'Enrollment'
  }

  handleViewChange = (e) => {
    this.setState({ view: e.target.value })
  }

  handleActiveLabelChange = (e) => {
    let label
    let labelCount

    if (!!e && !!e.activeLabel === 'Enrollment') {
      label = e.activeLabel
      this.setState({ activeLabel: label })
    } else {
      if (!!e && e.activeLabel) {
        label = e.activeLabel.split(' ')[0]
        labelCount = e.activeLabel.split(' ')[1]
        this.setState({ activeLabel: label, activeLabelCount: labelCount })
      }
    }
  }

  calculateBodyLength = (measurements) => {
    return (
      parseFloat(
        (
          measurements.neck +
          measurements.chest +
          measurements.shoulder +
          measurements.arm +
          measurements.forearm +
          measurements.belly_waist +
          measurements.narrow_waist +
          measurements.hip +
          measurements.thigh +
          measurements.calf
        ).toFixed(2),
      ) || null
    )
  }

  getFormatData = (weekMap, weeklies, dailies, enrollment, student) => {
    let formatData = []
    let enrollmentWeight
    let enrollmentBodyLength
    let enrollmentBodyFat

    const coachUnits = localStorage.getItem('COACH_UNITS')
    if (enrollment) {
      enrollment = recalculateMeasurements(enrollment, coachUnits, student)
      enrollmentBodyLength = this.calculateBodyLength(enrollment)
      enrollmentWeight = enrollment.weight
      enrollmentBodyFat = enrollment.bf_pct
    }

    formatData.push({
      ...enrollment,
      name: 'Enrollment',
      weight: enrollmentWeight,
      bodyLength: enrollmentBodyLength,
      bodyFat: enrollmentBodyFat,
    })

    weekMap.forEach((week, index) => {
      let weeklyData = weeklies.find((w) => w.for_date === week.for_date)
      const endWeekDate = moment(week.for_date).add(6, 'day')
      let dailyData = dailies.filter((d) => moment(d.for_date).isSameOrAfter(moment(week.for_date)) && moment(d.for_date).isSameOrBefore(endWeekDate))
      let weight
      let bodyLength
      let bodyFat

      if (weeklyData) {
        weeklyData = recalculateMeasurements(weeklyData, coachUnits, student)
        bodyLength = this.calculateBodyLength(weeklyData)
      }

      if (dailyData && dailyData.length > 0) {
        const dailyDataWithHeight = dailyData.filter((daily) => !!daily.weight)
        const dailyDataWithBF = dailyData.filter((daily) => !!daily.bf_pct)

        weight = parseFloat((dailyDataWithHeight.reduce((total, next) => total + next.weight, 0) / dailyDataWithHeight.length).toFixed(2)) || null
        bodyFat = parseFloat((dailyDataWithBF.reduce((total, next) => total + next.bf_pct, 0) / dailyDataWithBF.length).toFixed(2))
      }

      if (student.units !== coachUnits && coachUnits !== 'lbs/in') {
        weight = parseFloat((weight / 2.205).toFixed(2))
      }
      if (student.units !== coachUnits && coachUnits !== 'kg/cm') {
        weight = parseFloat((weight * 2.205).toFixed(2))
      }

      formatData.push({
        name: `Week ${index + 1}`,
        weight: weight || null,
        bodyLength: bodyLength || null,
        bodyFat,
        ...weeklyData,
      })
    })

    return formatData
  }

  getCardData = (data, type, firstEnrollment, firstWeekly, firstDaily) => {
    let cardData = {
      bodyLength: { after: 0, before: 0 },
      weight: { after: 0, before: 0 },
    }

    const enrollment = data.find((d) => d.name === 'Enrollment')
    const lastWeek = _.takeRight(data, 1)[0]

    switch (type) {
      case 'historical':
      default:
        if (!!enrollment) {
          cardData.bodyLength.before = enrollment.bodyLength
          cardData.weight.before = enrollment.weight
        }
        if (!!firstEnrollment && !!firstDaily) {
          if (moment(firstEnrollment.created).isBefore(moment(firstDaily.for_date))) {
            cardData.weight.after = firstEnrollment.weight
          } else {
            cardData.weight.after = firstDaily.weight
          }
        } else if (!!firstDaily) {
          cardData.weight.after = firstDaily.weight
        } else if (!!firstEnrollment) {
          cardData.weight.after = firstEnrollment.weight
        }

        if (!!firstEnrollment && !!firstWeekly) {
          if (moment(firstEnrollment.created).isBefore(moment(firstWeekly.for_date))) {
            cardData.bodyLength.after = this.calculateBodyLength(firstEnrollment)
          } else {
            cardData.bodyLength.after = this.calculateBodyLength(firstWeekly)
          }
        } else if (!!firstWeekly) {
          cardData.bodyLength.after = this.calculateBodyLength(firstWeekly)
        } else if (!!firstEnrollment) {
          cardData.bodyLength.after = this.calculateBodyLength(firstEnrollment)
        }

        break
      case 'bootcamp':
        if (enrollment) {
          cardData.bodyLength.after = enrollment.bodyLength
          cardData.weight.after = enrollment.weight
        }
        if (lastWeek) {
          cardData.bodyLength.before = lastWeek.bodyLength
          cardData.weight.before = lastWeek.weight
        }
        break
      case 'week':
        if (lastWeek) {
          cardData.bodyLength.before = lastWeek.bodyLength
          cardData.weight.before = lastWeek.weight
          const penultimateWeek = _.takeRight(data.filter((w) => moment(w.for_date).isBefore(lastWeek.for_date)), 1)[0]
          if (penultimateWeek) {
            cardData.bodyLength.after = penultimateWeek.bodyLength
            cardData.weight.after = penultimateWeek.weight
          }
        }
        break
    }

    return cardData
  }

  render() {
    const { ...rest } = this.props
    return (
      <DashboardContext.Consumer>
        {({ weekMap, weeklies, dailies, enrollment, firstEnrollment, firstWeekly, student }) => {
          const formatData = this.getFormatData(weekMap, weeklies, dailies, enrollment, student)
          const historicalChange = this.getCardData(formatData, 'historical', firstEnrollment, firstWeekly)
          const historicalCampChange = this.getCardData(formatData, 'bootcamp')
          const historicalWeekChange = this.getCardData(formatData, 'week')
          let label = this.state.activeLabel
            ? this.state.activeLabelCount
              ? this.state.activeLabel.concat(' ', this.state.activeLabelCount)
              : this.state.activeLabel
            : this.defaultWeek(formatData)
          return (
            <div className="graph-table-container">
              <Row gutter={[4, 8]}>
                <Col md={16}>
                  <div className="keto-card" {...rest}>
                    <header>
                      <h3>Bootcamp Overview</h3>
                      <div className="actions">
                        <StyledRadioGroupButton defaultValue="table" onChange={this.handleViewChange}>
                          <Radio.Button value="table">Table</Radio.Button>
                          <Radio.Button value="graph">Graph</Radio.Button>
                        </StyledRadioGroupButton>
                      </div>
                    </header>
                    <main style={{ textAlign: 'center', overflow: 'hidden' }}>
                      <Row gutter={[4, 8]}>
                        <Col md={24}>
                          <Col md={8}>
                            <Benchmark title="Historical Change" type="orange" data={historicalChange} units={enrollment.metric} student={student} />
                          </Col>
                          <Col md={8}>
                            <Benchmark
                              title="Change in Current Camp"
                              type="purple"
                              data={historicalCampChange}
                              units={enrollment.metric}
                              student={student}
                            />
                          </Col>
                          <Col md={8}>
                            <Benchmark
                              title="Change in This Week"
                              type="blue"
                              data={historicalWeekChange}
                              units={enrollment.metric}
                              student={student}
                            />
                          </Col>
                          <Col md={24}>
                            {this.state.view === 'table' && (
                              <Table data={formatData} units={enrollment.metric} student={student} enrollment={enrollment} label={label} />
                            )}
                            {this.state.view === 'graph' && (
                              <Graph student={student} data={formatData} units={enrollment.metric} handleClick={this.handleActiveLabelChange} />
                            )}
                          </Col>
                        </Col>
                      </Row>
                    </main>
                  </div>
                </Col>
                <Col md={8}>
                  <div className="keto-card" {...rest}>
                    <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      {label === 'Enrollment' ? (
                        <h3 style={{ fontSize: '14px', width: '50%' }}>{label}</h3>
                      ) : (
                        <h3 style={{ fontSize: '14px', width: '50%' }}>
                          {label.split(' ')[0]}{' '}
                          <span style={{ background: '#5C7CE2', fontSize: '16px', color: '#ffffff', padding: '2px 5px' }}>{label.split(' ')[1]}</span>{' '}
                        </h3>
                      )}
                      <h5 style={{ width: '30%' }}>Enrollment</h5>
                      <h5 style={{ width: '20%' }}>Reported Weekly</h5>
                    </header>
                    <main>
                      <Row gutter={[4, 8]}>
                        <Col md={24}>
                          <GraphTooltip
                            {...{
                              active: true,
                              label: label,
                              weeklies: formatData,
                              units: enrollment.metric,
                              student,
                            }}
                          />
                        </Col>
                      </Row>
                    </main>
                  </div>
                </Col>
              </Row>
            </div>
          )
        }}
      </DashboardContext.Consumer>
    )
  }
}

export default BootcampOverview
