import { Link } from '@reach/router'
import styled from '@emotion/styled'
import { Select } from 'antd'
import iconGroup from 'images/icon-group.svg'

export const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  max-width: 1024px;
  min-height: 100vh;
  min-height: 1024px;
  margin: 0 auto;
`
export const UserBoard = styled.aside`
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  border-radius: 2px;
`
export const ShowToggler = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  @media only screen and (min-width: 1920px) {
    margin-right: 0;
  }
`
export const StyledSelect = styled(Select)`
  .ant-select-selection-selected-value {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .ant-select-selection {
    height: 30px;
    font-family: 'Montserrat', sans-serif;
    color: #5d5d5d;
    font-size: 11px;
    font-weight: 500;
  }
`
export const UserListWrap = styled.div`
  margin-top: 17px;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
`
export const Collapse = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: no-repeat left;
  background-image: url(${iconGroup});
  height: 30px;
  padding: 0 0 0 45px;
  margin: 10px 0;
`
export const StyledLink = styled(Link)`
  color: #999;
  font-size: 8px;
  &:hover {
    color: #000;
    cursor: not-allowed;
  }
  &:focus {
    text-decoration: none;
  }
`
