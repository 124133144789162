import styled from '@emotion/styled'
export const MealPlanUploaderContainer = styled.div`
  max-width: 600px;
  padding: 40px;
  .ant-row {
    align-items: center;
    margin-bottom: 30px;
    display: flex;
    p {
      margin: 0;
    }
  }
  .uploadcare--widget__button,
  .uploadcare--widget__text {
    display: none;
  }
`
