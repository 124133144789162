import React, { useRef } from 'react'
import { Widget } from '@uploadcare/react-widget'
import { UPLOADS } from '../../constants'
import { MealPlanUploaderContainer } from './MealPlan.styled'
import { Row, Col, Button, notification } from 'antd'
import apolloClient from 'apolloClient'
import { updateMealPlanUrl } from './MealPlanQuerys'
const { UPLOADCARE_PUBLIC_KEY_ASSETS } = UPLOADS

const MealPlanUploader = ({ mealPlan, traineeId, programId, refetch, macros }) => {
  const widgetRef = useRef()

  const onChange = async (file) => {
    const { cdnUrl } = file
    const { errors } = await apolloClient.mutate({
      mutation: updateMealPlanUrl,
      variables: {
        traineeId,
        programId,
        url: cdnUrl,
        status: 'MealPlanCompleted',
        approved_date: 'now()',
      },
      update: () => refetch(),
    })
    if (!errors) notification.success({ message: 'Updated successfully!' })
    else notification.error({ message: 'An error has occurred!' })
  }

  const openUploader = () => widgetRef.current.openDialog()

  return (
    <MealPlanUploaderContainer>
      {macros && macros.fat && macros.prot && macros.carbs && (
        <Row align="middle" justify="space-around">
          <Col span={6}>
            <p>
              <strong>Macros: </strong>
            </p>
          </Col>
          <Col span={6}>
            <p>
              <strong>Fat: </strong>
              {macros.fat}
            </p>
          </Col>
          <Col span={6}>
            <p>
              <strong>Carbs: </strong>
              {macros.carbs}
            </p>
          </Col>
          <Col span={6}>
            <p>
              <strong>Prot: </strong>
              {macros.prot}
            </p>
          </Col>
        </Row>
      )}
      <Row align="middle" justify="center">
        <Col span={12}>
          <p>{mealPlan.url ? 'This is the meal plan' : 'Upload the meal plan'}</p>
        </Col>
        <Col span={12}>
          <Widget ref={widgetRef} key={mealPlan.url} value={null} onChange={onChange} publicKey={UPLOADCARE_PUBLIC_KEY_ASSETS} tabs="file" />
          {!mealPlan.url ? (
            <Row>
              <Col span={12}>
                <Button onClick={openUploader}>UPLOAD</Button>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col span={12}>
                <Button type="link" href={mealPlan.url} target="_blank">
                  SEE FILE
                </Button>
              </Col>
              <Col span={12}>
                <Button onClick={openUploader}>REPLACE</Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </MealPlanUploaderContainer>
  )
}
export default MealPlanUploader
