import React from 'react'
import { Avatar } from 'antd'
import { StyledCard, StyledMeta } from './UserCard.styled'

const UserCard = (props) => {
  const { name, picture, nickname, onClick, active } = props
  return (
    <StyledCard style={{ marginBottom: 10 }} onClick={onClick} active={active}>
      <StyledMeta avatar={<Avatar size={30} src={picture} icon="user" alt={nickname} />} title={name} />
    </StyledCard>
  )
}

export default UserCard
