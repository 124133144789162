import { Radio } from 'antd'
import styled from '@emotion/styled'

export default styled(Radio.Group)`
  .ant-radio-button-wrapper {
    background-color: #5c7ce2;
    color: #ffffff;
    font-size: 8px;
    height: auto;
    line-height: initial;
    border: none;
    padding: 4px 7px;

    &.ant-radio-button-wrapper-checked {
      background-color: rgba(29, 33, 216);
      border: none;
    }
    &.ant-radio-button-wrapper-disabled {
      background-color: rgba(175, 175, 188);
      border: none;
    }
  }
`
