// Please set .env files to modify.
// For local testing it is required to set .env.development.local with 'http://localhost:3000'
// DO NOT COMMIT .local file to repository

//Macro Status Icons
import iconShowToReview from 'images/icon_show_to_review.png'
import iconShowInProgress from 'images/icon_show_in_progress.png'
import iconShowSentToApprove from 'images/icon_show_sent_to_approve.png'
import iconShowToApprove from 'images/icon_show_to_approve.png'
import iconShowApproved from 'images/icon_show_approved.png'

//Weekly Status Icons
import iconShownAssistance from 'images/icon-shown-assistance.svg'
import iconShownCompleted from 'images/icon-shown-completed.svg'
import iconShownMissing from 'images/icon-shown-missing.svg'
import iconShownPause from 'images/icon-shown-pause.svg'
import iconShownReady from 'images/icon-shown-ready.svg'

export const AppVersion = `v${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_BRANCH || ''}`
export const DB = process.env.REACT_APP_DB

export const LOCAL_STORAGE = {
  USER_ID: 'USER_ID',
  EX_USER_ID: 'EX_USER_ID',
  COACH_ID: 'COACH_ID',
  COACH_UNITS: 'COACH_UNITS',
  AS_COACH: 'AS_COACH',
  USER_NICKNAME: 'USER_NICKNAME',
  USER_PICTURE: 'USER_PICTURE',
  BOOTCAMP_ID: 'BOOTCAMP_ID',
  BOOTCAMP_START_DATE: 'BOOTCAMP_START_DATE',
  BOOTCAMP_END_DATE: 'BOOTCAMP_END_DATE',
  BOOTCAMP_CLOSE_DATE: 'BOOTCAMP_CLOSE_DATE',
  BOOTCAMP_DESCRIPTION: 'BOOTCAMP_DESCRIPTION',
  BOOTCAMP_TYPE: 'BOOTCAMP_TYPE',
  BOOTCAMP_ACCESS_DATE: 'BOOTCAMP_ACCESS_DATE',
  USER_ROLES: 'USER_ROLES',
  TRAINEE_ID: 'TRAINEE_ID',
  CURRENT_MESSAGE: 'CURRENT_MESSAGE',
  ADMIN_ID: 'ADMIN_ID',
  ADMIN_NICKNAME: 'ADMIN_NICKNAME',
  ADMIN_PICTURE: 'ADMIN_PICTURE',
  ADMIN_ROLES: 'ADMIN_ROLES',
  IS_MASTER: 'IS_MASTER',
  IS_MEAL_PLANNER: 'IS_MEAL_PLANNER',
  FILTER_CATEGORY: 'FILTER_CATEGORY',
  FILTER_VALUE: 'FILTER_VALUE',
  CURRENT_TAB: 'CURRENT_TAB',
  USER_EMAIL: 'USER_EMAIL',
}

export const COOKIE = {
  USER_TOKEN: 'USER_TOKEN',
  API_TOKEN: 'API_TOKEN',
  USER_TOKEN_EXPIRE_TIME: 'USER_TOKEN_EXPIRE_TIME',
}

export const UPLOADS = {
  UPLOADCARE_PUBLIC_KEY: 'b30ba35631dd3b1d4a32',
  UPLOADCARE_PUBLIC_KEY_ASSETS: '131f89f26e091050eb22',
  UPLOADCARE_PUBLIC_KEY_DEV: '4233ba0942ccd2344e3d',
  UPLOADCARE_TABS: 'file camera',
  UPLOADCARE_SHRINK: '1920x1920 100%',
  UPLOADCARE_IMAGES_ONLY: 'true',
  UPLOADCARE_PREVIEW: 'true',
  UPLOADCARE_CROP: 'free',
}

export const REPLACEMENT = '--'

export const GENERAL_STATUS_MAP = [{ id: 0, name: 'Alphabetical order', generalStatus: 'none' }]

export const WEEKLY_STATUS_MAP = [
  { id: 1, name: 'Weekly Review Completed', status: 'completed', icon: iconShownCompleted },
  { id: 2, name: 'Ready for Weekly Review', status: 'ready', icon: iconShownReady },
  { id: 3, name: 'Client on Pause', status: 'pause', icon: iconShownPause },
  { id: 4, name: 'Missing info', status: 'missing', icon: iconShownMissing },
  { id: 5, name: 'Assistance needed', status: 'assistance', icon: iconShownAssistance },
]

export const MACROS_STATUS_MAP = [
  { id: 6, name: 'To Review', macrosStatus: 'PendingToReview', icon: iconShowToReview },
  { id: 7, name: 'In Progress', macrosStatus: 'in_progress', icon: iconShowInProgress },
  { id: 8, name: 'Sent to Approve', macrosStatus: 'PendingToApprove', icon: iconShowSentToApprove },
  { id: 9, name: 'To Approve', macrosStatus: 'PendingToApprove', icon: iconShowToApprove },
  { id: 10, name: 'Approved', macrosStatus: 'Approved', icon: iconShowApproved },
  { id: 11, name: 'In Progress', macrosStatus: 'InProgress', icon: iconShowInProgress },
]

export const ALL_STATUS_MAP = [
  { id: 0, name: 'Alphabetical order', userStatus: 'none' },
  { id: 1, name: 'Weekly Review Completed', userStatus: 'completed' },
  { id: 2, name: 'Ready for Weekly Review', userStatus: 'ready' },
  { id: 3, name: 'Client on Pause', userStatus: 'pause' },
  { id: 4, name: 'Missing info', userStatus: 'missing' },
  { id: 5, name: 'Assistance needed', userStatus: 'assistance' },
]

export const MEAL_STATUS_MAP = [{ id: 11, name: 'Meal Plan Students', mealStatus: 'meal_plan' }]

export const MEASUREMENTS_LIST = ['neck', 'chest', 'shoulder', 'arm', 'forearm', 'belly_waist', 'narrow_waist', 'hip', 'thigh', 'calf']

// Mask style for all Modal Frame
export const MASK_STYLE = { backgroundColor: 'rgba(255, 255, 255, 0.9' }

export const LENGTH_UNIT_DISPLAY_MAP = {
  cm: 'Centimeters',
  in: 'Inches',
}

export const WEIGHT_UNIT_DISPLAY_MAP = {
  kg: 'Kilograms',
  lbs: 'Pounds',
}
