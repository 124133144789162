import React from 'react'
import { Query } from 'react-apollo'
import apolloClient from 'apolloClient.js'
import { Link } from '@reach/router'
import gql from 'graphql-tag'
import { Icon, List, Avatar, Skeleton, Button, Tooltip, Checkbox } from 'antd'
import { BadgeStyled, DrawerStyled, DrawerStyledFooter } from './Header.styled'
import moment from 'moment'

export const getTotalUnreadMessages = gql`
  query getTotalUnreadMessages($userId: uuid, $coachId: uuid, $programId: uuid) {
    message_unread_aggregate(
      where: { _and: { read_coach: { _neq: true }, sender_id: { _neq: $userId }, coach_id: { _eq: $coachId }, program_id: { _eq: $programId } } }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const getUnreadMessages = gql`
  query getUnreadMessages($userId: uuid, $coachId: uuid, $programId: uuid) {
    message_unread(
      where: { _and: { read_coach: { _neq: true }, sender_id: { _neq: $userId }, coach_id: { _eq: $coachId }, program_id: { _eq: $programId } } }
    ) {
      id
      thread_id
      timestamp
      body
      first_name
      last_name
      picture
      trainee_id
      program
      sender_id
    }
  }
`

export const MarkMessagesAsRead = gql`
  mutation MarkMessagesAsRead($threadId: uuid!, $senderId: uuid!, $timeStamp: timestamptz) {
    update_message(
      where: { thread_id: { _eq: $threadId }, sender_id: { _eq: $senderId }, timestamp: { _lte: $timeStamp } }
      _set: { read_coach: true }
    ) {
      returning {
        id
      }
    }
  }
`

class HeaderNotification extends React.Component {
  constructor(props) {
    super(props)
    this.refetchUnreadMessages = () => {}
    this.refetchTotalUnreadMessages = () => {}
  }

  state = {
    panelVisible: false,
    messagesForRead: [],
  }

  MessageItem = (message, loading, markMessageAsRead, refetchUnreadMessages) => {
    const { coachId } = this.props
    return (
      <Skeleton loading={loading} active avatar>
        <List.Item
          key={message.id}
          actions={[
            <Tooltip title="Mark as read">
              <Checkbox onChange={(e) => this.setListForReaded(message, e.target.checked)} />
            </Tooltip>,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar style={{ background: '#FF6B00' }} icon="user" src={message.picture} />}
            title={
              <Link onClick={() => this.closePanel()} to={`/${coachId}/${message.trainee_id}`}>{`${message.first_name} ${message.last_name}`}</Link>
            }
            description={this.MessageDetailItem(message)}
          />
        </List.Item>
      </Skeleton>
    )
  }

  MessageDetailItem = (message) => {
    const body = message.body.indexOf('<img') === -1 ? message.body.substring(0, 100) : 'GIF'
    return (
      <div>
        <p>{body}</p>
        <p>
          <small>
            {moment(message.timestamp)
              .local()
              .format('MMM DD - hh:mm a')}
          </small>
        </p>
      </div>
    )
  }

  markAllAsRead = (refetchUnreadMessages) => {
    let messagesForRead = this.state.messagesForRead
    messagesForRead.forEach((message) => {
      let threadId = message.thread_id
      let senderId = message.sender_id
      let timeStamp = message.timestamp
      apolloClient.mutate({
        mutation: MarkMessagesAsRead,
        variables: {
          threadId,
          senderId,
          timeStamp,
        },
      })
    })
    refetchUnreadMessages()
  }

  openPanel = () => {
    this.refetchUnreadMessages()
    this.setState({
      panelVisible: true,
    })
  }

  closePanel = () => {
    this.refetchTotalUnreadMessages()
    this.setState({
      panelVisible: false,
    })
  }

  setListForReaded = (message, checked) => {
    let messagesForRead = this.state.messagesForRead

    if (checked === true) {
      this.setState({
        messagesForRead: [...messagesForRead, message],
      })
    } else if (checked === false) {
      let index = messagesForRead.findIndex((msg) => msg.id === message.id)
      messagesForRead.splice(index, 1)
      this.setState({
        messagesForRead,
      })
    }
  }

  render() {
    const { panelVisible } = this.state
    const { coachId, userId, programId } = this.props
    if (!!!coachId && !!!userId && !!!programId) return null
    return (
      <div style={{ display: 'inline' }}>
        <Query query={getTotalUnreadMessages} variables={{ coachId, userId, programId }} fetchPolicy="network-only">
          {({ loading, error, data, refetch }) => {
            if (error || loading) return null
            this.refetchTotalUnreadMessages = refetch
            let totalMessagesUnread = 0
            if (!!data.message_unread_aggregate) {
              totalMessagesUnread = data.message_unread_aggregate.aggregate.count
            }
            return (
              <BadgeStyled onClick={this.openPanel} count={totalMessagesUnread}>
                <Icon type="bell" theme="filled" />
              </BadgeStyled>
            )
          }}
        </Query>

        <DrawerStyled
          visible={panelVisible}
          onClose={this.closePanel}
          width={389}
          title={
            <div>
              <Icon type="bell" theme="filled" />
              New Messages
            </div>
          }
        >
          <Query query={getUnreadMessages} variables={{ coachId, userId, programId }} fetchPolicy="network-only">
            {({ loading, error, data, refetch }) => {
              if (error) return null
              this.refetchUnreadMessages = refetch
              const messages = loading ? [{ body: '' }] : data.message_unread
              return (
                <>
                  <List dataSource={messages} renderItem={(message) => this.MessageItem(message, loading, this.markMessageAsRead, refetch)} />
                  <DrawerStyledFooter>
                    <Button type="primary" onClick={() => this.markAllAsRead(refetch)}>
                      Mark as read
                    </Button>
                  </DrawerStyledFooter>
                </>
              )
            }}
          </Query>
        </DrawerStyled>
      </div>
    )
  }
}

export default HeaderNotification
