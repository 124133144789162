import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Content = styled.div`
  padding: 20px;
`
export const Title = styled.h2`
  color: #ff4613;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
`
export const UploaderWrap = styled.div`
  width: 210px;
  height: 210px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 18px;
  .uploadcare--widget {
    text-align: center;
  }
  .uploadcare--widget__button {
    background: #ff4613;
    border: 0;
    border-radius: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    height: auto;
    margin: 0 auto;
    outline: none;
    text-transform: uppercase;
    background: transparent;
    border: thin solid #000;
    border-radius: 20px;
    margin: 0 auto 9px;
    padding: 5px 10px;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }
  .uploadcare--widget__button_type_open,
  .uploadcare--widget__button_type_cancel {
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    color: #000;
  }
  .uploadcare--widget__text {
    display: none;
  }
  .uploadcare--progress_type_canvas {
    color: #ff4613;
    margin-top: 8px;
  }
`
export const UploadedPicture = styled.img`
  max-width: 100%;
  height: 140px;
  display: block;
  margin: auto;
  border-radius: 2px;
  ${({ isFileUploaded }) =>
    isFileUploaded &&
    css`
      width: 210px;
      height: 210px;
      object-fit: cover;
    `}
`
