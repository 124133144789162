import React, { Component, useState } from 'react'
import './index.scss'
import apolloClient from 'apolloClient.js'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import moment from 'moment'
import { StyledRadioGroupButton } from 'styled'
import MedicalReport from 'components/UserDashboard/MedicalReport'
import { StartingGoalCard } from './GoalCard'
import { DataInfoCard } from './DataInfoCard'
import { TableMacro } from './TableMacro'
import { AlternateTableMacro } from './AlternateTableMacro'
import { ModalChangesNoteCard } from './ModalChangesNoteCard'
import { MacroFooter } from './MacroFooter'
import { appendNote, updateMacros, getMacroNotes, updateMacrosStatus, updateMacrosWAlternate, approveCancelStudentData } from 'queries/macros'
import { Row, Col, Card, Avatar, Button, Icon, Modal, Input, notification, Popconfirm, message, Radio, Spin, Result, Checkbox } from 'antd'
import { setStudentContactTag, changeStudentLevel } from 'queries'
import { getWeekRange } from 'services'
import { apiServices } from 'services/api'
import { StyledButton } from '../UserDashboard/UserDashboard.styled'
import iconBodyFront from 'images/icon-body-front.png'
import iconBodyBack from 'images/icon-body-back.png'
import iconBodySide from 'images/icon-body-side.png'
import * as Sentry from '@sentry/browser'
import DashboardContext from 'context/DashboardContext'
import { Scroll } from './MacrosNote.styled'
import { getCookie } from 'tiny-cookie'
import { COOKIE } from 'constants.js'

const { Meta } = Card
const { TextArea } = Input
let thread_id = ''

const appendMessage = gql`
  mutation appendMessage($data: message_insert_input!, $lastMessageDate: timestamptz!, $userId: uuid!, $thread_id: uuid!) {
    insert_message(objects: [$data]) {
      returning {
        timestamp
        body
      }
    }
    update_message(
      where: {
        _and: { timestamp: { _lte: $lastMessageDate }, thread_id: { _eq: $thread_id }, sender_id: { _neq: $userId }, read_coach: { _eq: false } }
      }
      _set: { read_coach: true }
    ) {
      affected_rows
    }
  }
`

const ModalNoteCard = ({
  visible,
  handleOk,
  handleCancel,
  handleChange,
  coachID,
  programId,
  currentTrainee,
  refetch,
  note,
  handleSendNote,
  check,
}) => {
  let change_details = null

  return (
    <Modal
      title="Macros Notes"
      className="modal-note-card"
      visible={visible}
      onOk={() => handleOk(note, coachID, programId, currentTrainee, refetch)}
      onCancel={handleCancel}
      footer={[
        <Button key="back" className="modal-cancel-button" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          className="modal-save-note-button"
          type="primary"
          onClick={() => handleOk(note, coachID, programId, currentTrainee, change_details, refetch, check)}
        >
          Save Note
        </Button>,
      ]}
    >
      <TextArea name="noteText" value={note} rows={4} onChange={handleChange} />
      <Checkbox onChange={handleSendNote}>Send note to user</Checkbox>
    </Modal>
  )
}

const NotesCard = ({ className, name, date, text, changeDetails, picture, programDescription }) => {
  let day = moment(date).format('DD')
  let month = moment(date).format('MMM')
  let year = moment(date).format('YYYY')

  const [visibility, setVisibility] = useState(false)

  const toggleVisibility = () => {
    setVisibility(!visibility)
  }

  return (
    <Card className={className ? `${className}` : 'macros-note-card'} bordered={false}>
      <div className="note-card-body-container">
        <div className="notes-card-header">
          <div className="notes-user-container">
            <Meta avatar={<Avatar src={picture} />} />
            <span className="notes-user-container-name">{name}</span>
          </div>
          <span className="notes-card-header-date">{`${day}/${month}/${year}`}</span>
        </div>
        <div className="notes-card-header">
          <span className="notes-card-header-date">{`${programDescription}`}</span>
        </div>
        {changeDetails ? (
          <div className="note-card-changes-message">
            <p onClick={() => toggleVisibility()}>
              (<u>Changes note, click it to see all the details</u>)
            </p>
            <ModalChangesNoteCard
              readOnly={true}
              visible={visibility}
              changeDetails={changeDetails}
              handleCancel={() => toggleVisibility()}
              textNote={text}
              name={name}
              date={date}
            />
          </div>
        ) : null}
        <div className="notes-card-body">{text}</div>
      </div>
    </Card>
  )
}

const PictureModal = ({ url, date, visible, handleClose, index }) => {
  const [rotateDegrees, setrotateDegrees] = useState(0)
  const [zoomScale, setZoomScale] = useState(1)
  const [loading, setLoading] = useState(false)
  let rotateSfx = `-/preview/-/rotate/${rotateDegrees}/`
  let sizeSfx = rotateDegrees === 0 || rotateDegrees === 180 ? '-/resize/x560/' : '-/resize/560x/'
  const rotateImg = async (direction) => {
    await setLoading(true)

    direction === 'left'
      ? await setrotateDegrees(rotateDegrees + 90 === 360 ? 0 : rotateDegrees + 90)
      : await setrotateDegrees(rotateDegrees - 90 < 0 ? 360 - 90 : rotateDegrees - 90)

    await setLoading(false)
  }

  const handleZoom = (zoom) => {
    zoom === 'zoom-in' ? setZoomScale(zoomScale + 0.25) : setZoomScale(zoomScale - 0.25)
  }

  const closeModal = () => {
    setrotateDegrees(0)
    setZoomScale(1)
  }

  let sfx = rotateDegrees === 0 ? '-/autorotate/yes/' : rotateSfx

  return (
    <Modal
      className="modal-picture-zoomable"
      title={
        <div>
          <span className="month-date">{moment(date).format('MMMM YYYY')}</span>
          <span className="week-date">
            <Icon type="calendar" />
            <span>{getWeekRange(date)}</span>
          </span>
        </div>
      }
      visible={visible}
      centered={true}
      width={560}
      onCancel={handleClose}
      onOk={handleClose}
      afterClose={() => closeModal()}
      footer={[
        <Button className="button-zoom-in" key={'zoom-in'} disabled={zoomScale === 1.5 ? true : false} onClick={() => handleZoom('zoom-in')}>
          <Icon type="zoom-in" />
        </Button>,
        <Button
          className="button-zoom-out button-left-bordered"
          key={'zoom-out'}
          disabled={zoomScale === 0.5 ? true : false}
          onClick={() => handleZoom('zoom-out')}
        >
          <Icon type="zoom-out" />
        </Button>,
        <Button className="button-rotate-left button-left-bordered" key={'rotate-left'} onClick={() => rotateImg('left')}>
          <Icon type="undo" rotate={90} />
        </Button>,
        <Button className="button-rotate-right button-left-bordered" key={'rotate-right'} onClick={() => rotateImg('right')}>
          <Icon type="redo" rotate={-90} />
        </Button>,
      ]}
    >
      <div
        className="img-container"
        style={{
          maxHeight: rotateDegrees === 0 || rotateDegrees === 180 ? '560px' : '420px',
          maxWidth: rotateDegrees === 0 || rotateDegrees === 180 ? '420px' : '560px',
        }}
      >
        {loading ? (
          <Icon type="loading" />
        ) : (
          <img
            src={url + sfx + sizeSfx}
            alt=""
            style={{
              transform: `scale(${zoomScale})`,
            }}
          />
        )}
      </div>
    </Modal>
  )
}

const PictureCard = ({ url, rawUrl, index, date, zoomable }) => {
  const [visibility, setVisibility] = useState(false)

  const toggleVisibility = () => {
    setVisibility(!visibility)
  }

  return (
    <div>
      <div className="img-container" onClick={() => toggleVisibility()}>
        <img src={url} alt="" />
      </div>
      {zoomable ? (
        <div className="img-modal-container">
          <PictureModal url={rawUrl} date={date} index={index} visible={visibility} handleClose={() => toggleVisibility()} />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

const StartingPicturesCard = ({ userPictures }) => {
  let sfx = '-/autorotate/yes/-/preview/96x96/'
  if (!userPictures || userPictures.length <= 0) {
    userPictures = []
  }

  return (
    <Card title="Starting Pictures" className="card starting-pictures-card" bordered={false}>
      <Row gutter={[8, 8]}>
        {userPictures.map((picture, index) =>
          picture && picture.zoomable ? (
            <Col xs={8} key={index}>
              <span>
                <PictureCard url={picture.url + sfx} rawUrl={picture.url} date={picture.date} index={index} zoomable={picture.zoomable} />
              </span>
            </Col>
          ) : (
            <Col xs={8} key={index}>
              <PictureCard url={picture} />
            </Col>
          ),
        )}
      </Row>
    </Card>
  )
}

const UpdateStudentData = ({ handleUpdate }) => {
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const showPopconfirm = () => {
    setVisible(true)
  }

  const handleOk = async () => {
    setConfirmLoading(true)
    await handleUpdate({ calculate: true })
    setVisible(false)
    setConfirmLoading(false)
  }

  const handleCancel = () => {
    handleUpdate({ calculate: false })
    setVisible(false)
  }

  return (
    <div className="update-student-data">
      <Popconfirm
        overlayClassName={'Popconfirm-center-button'}
        title={
          <div>
            <p className="uppertext">Are you sure you want to replace the current student data?</p>
            <small>
              <p className="bottomtext">The modifications applied to the macros will need to be overview before approval.</p>
            </small>
          </div>
        }
        visible={visible}
        okButtonProps={{ loading: confirmLoading }}
        onConfirm={handleOk}
        onCancel={handleCancel}
        okText="Update"
        cancelText="Reject"
      >
        <Button onClick={showPopconfirm}>
          <Icon type="sync" />
          <span>Update Student Data</span>
        </Button>
      </Popconfirm>
    </div>
  )
}

class MacrosBody extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      noteText: '',
      changesNoteText: '',
      details: {},
      macros: {},
      macroBaseDay: {},
      macroTrainingDay: {},
      macroAlternate: {},
      changed: false,
      programId: '',
      currentTrainee: '',
      coachID: '',
      updateDataVisible: false,
      modalChangesVisible: false,
      changeDetails: [],
      callUpdateLevelFunction: false,
      macrosSelected: '',
      macrosStatus: '',
      sendNote: false,
    }
  }

  handleFetch = async () => {
    let { details, macros, currentTrainee, programId } = this.state

    let variables = {
      traineeId: currentTrainee,
      programId: programId,
      goal: details.current_goal,
      level: details.level,
      activityLevel: details.activity_level,
      useLeanGains: macros.use_lean_gains ? true : false,
      proteinIntake: macros.protein_intake_override && macros.protein_intake_override !== '' ? parseFloat(macros.protein_intake_override) : null,
      deficit: macros.deficit_override || (macros.deficit_override === 0 && macros.deficit_override !== '') ? `${macros.deficit_override}` : null,
    }

    fetch(`${process.env.REACT_APP_API_URL}macro-calculator-edit`, {
      method: 'POST',
      headers: {
        Authorization: getCookie(COOKIE.USER_TOKEN),
      },
      body: JSON.stringify(variables),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.hasError) {
          let errorMessage = response.message ? response.message : 'Error on server.'
          if (response.data && response.data.message) {
            errorMessage += ' ' + response.data.message
          }
          throw new Error(errorMessage)
        } else {
          const {
            body_fat_percent,
            lean_body_mass,
            calorie_intake_pre_health,
            recommended_deficit,
            use_lean_gains,
            protein_intake_override,
            deficit_override,
          } = response.macro

          this.setState((prevState) => ({
            details: {
              ...prevState.details,
              need_coach_approval: false,
            },
            macros: {
              ...prevState.macros,
              body_fat_percent,
              lean_body_mass,
              calorie_intake_pre_health,
              recommended_deficit,
              use_lean_gains,
              protein_intake_override,
              deficit_override,
            },
            macroBaseDay: response.macroBaseTable,
            macroTrainingDay: response.macroTrainingTable,
            changed: true,
          }))
        }
      })
      .catch((error) => {
        Sentry.addBreadcrumb({ message: 'Data sent', data: variables })
        Sentry.addBreadcrumb({ message: `${error}` })
        Sentry.captureMessage('Something failed on recalculate the macros (edition)')
        notification.warning({ message: 'Error on recalculating the macros', description: `${error}` })
      })
  }

  needCoachAproval = async (id_trainee_details, status_calculate) => {
    let id = id_trainee_details
    let calculate = status_calculate

    await apolloClient
      .mutate({
        mutation: approveCancelStudentData,
        variables: {
          id: id,
          value: false,
        },
      })
      .then(function(data) {
        !!calculate ? message.success('Macros updated') : message.success('Changes rejected')
      })
      .catch((error) => {
        notification.error({
          message: 'We can not update the macros.',
          description: `${error}`,
        })
      })

    this.setState((prevState) => ({
      details: {
        ...prevState.details,
        need_coach_approval: false,
      },
    }))
  }

  handleUpdateStudentData = async ({ calculate }) => {
    let { id } = this.state.details
    if (!!calculate) {
      await this.handleFetch()
    }
    if (!calculate) {
      this.needCoachAproval(id, calculate)
    }
  }

  handleUpdateDataVisibility = () => {
    this.setState((prevState) => ({
      updateDataVisible: !prevState.updateDataVisible,
    }))
  }

  handleDetailsDropdownChange = async (name, value) => {
    await this.setState((prevState) => ({
      details: {
        ...prevState.details,
        [name]: value,
      },
    }))

    this.handleFetch()
  }

  handleMacrosDropdownChange = async (name, value) => {
    await this.setState((prevState) => ({
      macros: {
        ...prevState.macros,
        [name]: value,
      },
    }))

    this.handleFetch()
  }

  handleMacrosSelectedChange = async (e) => {
    let { lastBootcampMacros, is_last_bootcamp_copy } = this.state
    let altMacros = {
      protein_base_grams: 0,
      protein_pre_workout_grams: 0,
      protein_kcal_gram: 4,
      carbs_base_grams: 0,
      carbs_pre_workout_grams: 0,
      carbs_kcal_gram: 4,
      fat_base_grams: 0,
      fat_pre_workout_grams: 0,
      fat_kcal_gram: 9,
      protein_daily_kcals: 0,
      carbs_daily_kcals: 0,
      fat_daily_kcals: 0,
      total_daily_kcals: 0,
    }
    let alternateWOData = {
      ...altMacros,
      protein_percent_calories: 0,
      carbs_percent_calories: 0,
      fat_percent_calories: 0,
      total_percent_calories: 0,
    }
    let baseMacros = lastBootcampMacros.macro_tables.find((t) => t.type === 'BaseDay') || {}
    let trainingMacros = lastBootcampMacros.macro_tables.find((t) => t.type === 'TrainingDay') || {}
    let alternateMacros = lastBootcampMacros.macro_tables.find((t) => t.type === 'Alternate') || altMacros

    this.handleDiscardChanges()
    if (e.target.value === 'current') {
      if (is_last_bootcamp_copy) {
        this.handleFetch()
        this.setState({
          macroAlternate: alternateWOData,
        })
      }
    } else {
      this.setState({
        macroBaseDay: baseMacros,
        macroTrainingDay: trainingMacros,
        macroAlternate: alternateMacros,
      })
    }

    this.setState({
      macrosSelected: e.target.value,
      changed: true,
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSendNote = (e) => {
    this.setState({
      sendNote: e.target.checked,
    })
  }

  onSendMessage = (note, thread_id, lastMessageDate, userID, callback) => {
    apolloClient
      .mutate({
        mutation: appendMessage,
        variables: {
          data: { thread_id, body: note, sender_id: userID },
          lastMessageDate,
          userId: userID,
          thread_id,
        },
        update: () => {
          if (typeof callback === 'function') {
            callback()
          }
        },
      })
      .catch((error) => {
        notification.error({ message: 'Your message could not be sent', description: `${error}` })
      })
  }

  showModal = () => {
    const impersonate = localStorage.getItem('IMPERSONATE_USER')
    if (impersonate === 'true' || impersonate === true) {
      this.setState({
        visible: false,
      })
    } else {
      this.setState({
        visible: true,
      })
    }
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    })
  }

  handleDiscardChanges = () => {
    this.setState({
      macros: this.state.prevMacros,
      macroBaseDay: this.state.prevMacroBaseDay,
      macroTrainingDay: this.state.prevMacroTrainingDay,
      macroAlternate: this.state.prevMacroAlternate,
      changed: false,
      changesNoteText: '',
    })
  }

  handleAlternateMacrosChange = async (macros) => {
    this.setState((prevState) => ({
      changed: true,
      macroAlternate: {
        ...prevState.macroAlternate,
        ...macros,
      },
    }))
  }

  handleTrainingMacrosChange = async (name, value) => {
    let { macroTrainingDay } = this.state

    let {
      protein_base_grams,
      protein_pre_workout_grams,
      protein_kcal_gram,
      carbs_base_grams,
      carbs_pre_workout_grams,
      carbs_kcal_gram,
      fat_base_grams,
      fat_pre_workout_grams,
      fat_kcal_gram,
      protein_daily_kcals,
      carbs_daily_kcals,
      fat_daily_kcals,
      total_daily_kcals,
    } = macroTrainingDay

    let total_grams = ''
    let total_grams_value = ''
    let daily_kcals = ''
    let daily_kcals_value = 0
    let total_daily = ''
    let total_daily_value = 0
    let protein_percent_calories = ''
    let protein_percent_calories_value = 0
    let carbs_percent_calories = ''
    let carbs_percent_calories_value = 0
    let fat_percent_calories = ''
    let fat_percent_calories_value = 0
    let total_daily_percent = ''
    let total_daily_percent_value = 0

    if (name.includes('protein')) {
      total_grams = await 'protein_total_grams'
      total_grams_value =
        (await name) === 'protein_base_grams'
          ? parseFloat(protein_pre_workout_grams) + parseFloat(value)
          : parseFloat(protein_base_grams) + parseFloat(value)
      daily_kcals = await 'protein_daily_kcals'
      daily_kcals_value = await (total_grams_value * protein_kcal_gram)
      total_daily = await 'total_daily_kcals'
      total_daily_value = await (parseFloat(total_daily_kcals - protein_daily_kcals) + parseFloat(daily_kcals_value))
      protein_percent_calories = await 'protein_percent_calories'
      protein_percent_calories_value = await Math.round((daily_kcals_value / total_daily_value) * 100)
      carbs_percent_calories = await 'carbs_percent_calories'
      carbs_percent_calories_value = await Math.round((carbs_daily_kcals / total_daily_value) * 100)
      fat_percent_calories = await 'fat_percent_calories'
      fat_percent_calories_value = await Math.round((fat_daily_kcals / total_daily_value) * 100)
      total_daily_percent = await 'total_percent_calories'
      total_daily_percent_value = protein_percent_calories_value + carbs_percent_calories_value + fat_percent_calories_value

      if (total_daily_percent_value !== 100) {
        if (total_daily_percent_value === 101) {
          protein_percent_calories_value -= await 1
          total_daily_percent_value -= await 1
        } else if (total_daily_percent_value === 99) {
          protein_percent_calories_value += await 1
          total_daily_percent_value += await 1
        }
      }
    } else if (name.includes('carbs')) {
      total_grams = await 'carbs_total_grams'
      total_grams_value =
        (await name) === 'carbs_base_grams'
          ? parseFloat(carbs_pre_workout_grams) + parseFloat(value)
          : parseFloat(carbs_base_grams) + parseFloat(value)
      daily_kcals = await 'carbs_daily_kcals'
      daily_kcals_value = await (total_grams_value * carbs_kcal_gram)
      total_daily = await 'total_daily_kcals'
      total_daily_value = await (parseFloat(total_daily_kcals - carbs_daily_kcals) + parseFloat(daily_kcals_value))
      protein_percent_calories = await 'protein_percent_calories'
      protein_percent_calories_value = await Math.round((protein_daily_kcals / total_daily_value) * 100)
      carbs_percent_calories = await 'carbs_percent_calories'
      carbs_percent_calories_value = await Math.round((daily_kcals_value / total_daily_value) * 100)
      fat_percent_calories = await 'fat_percent_calories'
      fat_percent_calories_value = await Math.round((fat_daily_kcals / total_daily_value) * 100)
      total_daily_percent = await 'total_percent_calories'
      total_daily_percent_value = protein_percent_calories_value + carbs_percent_calories_value + fat_percent_calories_value

      if (total_daily_percent_value !== 100) {
        if (total_daily_percent_value === 101) {
          protein_percent_calories_value -= await 1
          total_daily_percent_value -= await 1
        } else if (total_daily_percent_value === 99) {
          protein_percent_calories_value += await 1
          total_daily_percent_value += await 1
        }
      }
    } else if (name.includes('fat')) {
      total_grams = await 'fat_total_grams'
      total_grams_value =
        (await name) === 'fat_base_grams' ? parseFloat(fat_pre_workout_grams) + parseFloat(value) : parseFloat(fat_base_grams) + parseFloat(value)
      daily_kcals = await 'fat_daily_kcals'
      daily_kcals_value = await (total_grams_value * fat_kcal_gram)
      total_daily = await 'total_daily_kcals'
      total_daily_value = await (parseFloat(total_daily_kcals - fat_daily_kcals) + parseFloat(daily_kcals_value))
      protein_percent_calories = await 'protein_percent_calories'
      protein_percent_calories_value = await Math.round((protein_daily_kcals / total_daily_value) * 100)
      carbs_percent_calories = await 'carbs_percent_calories'
      carbs_percent_calories_value = await Math.round((carbs_daily_kcals / total_daily_value) * 100)
      fat_percent_calories = await 'fat_percent_calories'
      fat_percent_calories_value = await Math.round((daily_kcals_value / total_daily_value) * 100)
      total_daily_percent = await 'total_percent_calories'
      total_daily_percent_value = protein_percent_calories_value + carbs_percent_calories_value + fat_percent_calories_value

      if (total_daily_percent_value !== 100) {
        if (total_daily_percent_value === 101) {
          protein_percent_calories_value -= await 1
          total_daily_percent_value -= await 1
        } else if (total_daily_percent_value === 99) {
          protein_percent_calories_value += await 1
          total_daily_percent_value += await 1
        }
      }
    }

    await this.setState((prevState) => ({
      changed: true,
      macroTrainingDay: {
        ...prevState.macroTrainingDay,
        [name]: value,
        [total_grams]: total_grams_value,
        [daily_kcals]: daily_kcals_value,
        [total_daily]: total_daily_value,
        [protein_percent_calories]: protein_percent_calories_value,
        [carbs_percent_calories]: carbs_percent_calories_value,
        [fat_percent_calories]: fat_percent_calories_value,
        [total_daily_percent]: total_daily_percent_value,
      },
    }))
  }

  handleBaseMacrosChange = async (name, value) => {
    let { macroBaseDay } = this.state

    let {
      protein_kcal_gram,
      carbs_kcal_gram,
      fat_kcal_gram,
      protein_daily_kcals,
      carbs_daily_kcals,
      fat_daily_kcals,
      total_daily_kcals,
    } = macroBaseDay

    let total_grams = ''
    let daily_kcals = ''
    let daily_kcals_value = 0
    let total_daily = ''
    let total_daily_value = 0
    let protein_percent_calories = ''
    let protein_percent_calories_value = 0
    let carbs_percent_calories = ''
    let carbs_percent_calories_value = 0
    let fat_percent_calories = ''
    let fat_percent_calories_value = 0
    let total_daily_percent = ''
    let total_daily_percent_value = 0

    switch (name) {
      case 'protein_base_grams':
        total_grams = await 'protein_total_grams'
        daily_kcals = await 'protein_daily_kcals'
        daily_kcals_value = await (parseFloat(value) * protein_kcal_gram)
        total_daily = await 'total_daily_kcals'
        total_daily_value = await (parseFloat(total_daily_kcals - protein_daily_kcals) + parseFloat(daily_kcals_value))
        protein_percent_calories = await 'protein_percent_calories'
        protein_percent_calories_value = await Math.round((daily_kcals_value / total_daily_value) * 100)
        carbs_percent_calories = await 'carbs_percent_calories'
        carbs_percent_calories_value = await Math.round((carbs_daily_kcals / total_daily_value) * 100)
        fat_percent_calories = await 'fat_percent_calories'
        fat_percent_calories_value = await Math.round((fat_daily_kcals / total_daily_value) * 100)
        total_daily_percent = await 'total_percent_calories'
        total_daily_percent_value = protein_percent_calories_value + carbs_percent_calories_value + fat_percent_calories_value

        if (total_daily_percent_value !== 100) {
          if (total_daily_percent_value === 101) {
            protein_percent_calories_value -= await 1
            total_daily_percent_value -= await 1
          } else if (total_daily_percent_value === 99) {
            protein_percent_calories_value += await 1
            total_daily_percent_value += await 1
          }
        }
        break
      case 'carbs_base_grams':
        total_grams = await 'carbs_total_grams'
        daily_kcals = await 'carbs_daily_kcals'
        daily_kcals_value = await (parseFloat(value) * carbs_kcal_gram)
        total_daily = await 'total_daily_kcals'
        total_daily_value = await (parseFloat(total_daily_kcals - carbs_daily_kcals) + parseFloat(daily_kcals_value))
        protein_percent_calories = await 'protein_percent_calories'
        protein_percent_calories_value = await Math.round((protein_daily_kcals / total_daily_value) * 100)
        carbs_percent_calories = await 'carbs_percent_calories'
        carbs_percent_calories_value = await Math.round((daily_kcals_value / total_daily_value) * 100)
        fat_percent_calories = await 'fat_percent_calories'
        fat_percent_calories_value = await Math.round((fat_daily_kcals / total_daily_value) * 100)
        total_daily_percent = await 'total_percent_calories'
        total_daily_percent_value = protein_percent_calories_value + carbs_percent_calories_value + fat_percent_calories_value

        if (total_daily_percent_value !== 100) {
          if (total_daily_percent_value === 101) {
            protein_percent_calories_value -= await 1
            total_daily_percent_value -= await 1
          } else if (total_daily_percent_value === 99) {
            protein_percent_calories_value += await 1
            total_daily_percent_value += await 1
          }
        }
        break
      case 'fat_base_grams':
        total_grams = await 'fat_total_grams'
        daily_kcals = await 'fat_daily_kcals'
        daily_kcals_value = await (parseFloat(value) * fat_kcal_gram)
        total_daily = await 'total_daily_kcals'
        total_daily_value = await (parseFloat(total_daily_kcals - fat_daily_kcals) + parseFloat(daily_kcals_value))
        protein_percent_calories = await 'protein_percent_calories'
        protein_percent_calories_value = await Math.round((protein_daily_kcals / total_daily_value) * 100)
        carbs_percent_calories = await 'carbs_percent_calories'
        carbs_percent_calories_value = await Math.round((carbs_daily_kcals / total_daily_value) * 100)
        fat_percent_calories = await 'fat_percent_calories'
        fat_percent_calories_value = await Math.round((daily_kcals_value / total_daily_value) * 100)
        total_daily_percent = await 'total_percent_calories'
        total_daily_percent_value = protein_percent_calories_value + carbs_percent_calories_value + fat_percent_calories_value

        if (total_daily_percent_value !== 100) {
          if (total_daily_percent_value === 101) {
            protein_percent_calories_value -= await 1
            total_daily_percent_value -= await 1
          } else if (total_daily_percent_value === 99) {
            protein_percent_calories_value += await 1
            total_daily_percent_value += await 1
          }
        }
        break
      default:
        break
    }

    await this.handleTrainingMacrosChange(name, value)

    await this.setState((prevState) => ({
      changed: true,
      macroBaseDay: {
        ...prevState.macroBaseDay,
        [name]: value,
        [total_grams]: value,
        [daily_kcals]: daily_kcals_value,
        [total_daily]: total_daily_value,
        [protein_percent_calories]: protein_percent_calories_value,
        [carbs_percent_calories]: carbs_percent_calories_value,
        [fat_percent_calories]: fat_percent_calories_value,
        [total_daily_percent]: total_daily_percent_value,
      },
    }))
  }

  handleMacrosStatus = (isFromModal = false) => {
    let { macrosStatus, programId, currentTrainee } = this.state
    let newStatus = ''
    const handleSave = this.handleSaveChanges

    const variables = {
      program_id: programId,
      trainee_id: currentTrainee,
      status: '',
    }

    switch (macrosStatus) {
      case 'PendingToReview':
        if (isFromModal) {
          variables.status = 'InProgress'
        } else {
          variables.status = 'Approved'
          variables.approved_date = 'now()'
        }
        break
      case 'InProgress':
      case 'PendingToApprove':
        variables.status = 'Approved'
        variables.approved_date = 'now()'
        break
      default:
        variables.status = ''
    }

    if (macrosStatus !== 'Approved') {
      apolloClient
        .mutate({
          mutation: updateMacrosStatus,
          variables,
        })
        .then(async (data) => {
          newStatus = variables.status
          if (newStatus === 'Approved') {
            setStudentContactTag(currentTrainee, programId, ['MacrosSent']).catch((error) =>
              notification.warning({ message: 'InfusionSoft Warning!', description: error }),
            )
            await handleSave(newStatus)
          }
          notification.success({
            message: 'Status updated correctly!',
            description: 'The macros status has been updated correctly',
            style: {
              width: 600,
              marginLeft: 335 - 600,
            },
          })
        })
        .catch((error) => {
          Sentry.addBreadcrumb({
            message: 'Data sent',
            data: {
              program_id: programId,
              trainee_id: currentTrainee,
              status: newStatus,
            },
          })
          Sentry.captureMessage('Could not change the status of the macro')
          notification.warning({ message: 'Could not change the status of the macro', description: `${error}` })
        })
    }
  }

  handleSaveChanges = async (newStatus = false) => {
    const {
      details,
      macros,
      programId,
      currentTrainee,
      macroBaseDay,
      macroTrainingDay,
      macroAlternate,
      macrosStatus,
      callUpdateLevelFunction,
      macrosSelected,
      lastBootcampMacros,
    } = this.state
    const { refetch } = this.props

    const is_copy = macrosSelected === 'last'
    const copy_from = macrosSelected === 'last' ? lastBootcampMacros.program_id : null

    if (macrosStatus === 'PendingToReview' && newStatus !== 'Approved') {
      await this.handleMacrosStatus(true)
      await refetch()
    }

    if (macroAlternate.protein_base_grams !== 0 && macroAlternate.carbs_base_grams !== 0 && macroAlternate.fat_base_grams !== 0) {
      await apolloClient
        .mutate({
          mutation: updateMacrosWAlternate,
          variables: {
            status: this.props.macroMacros.status,
            programId: programId,
            currentTrainee: currentTrainee,
            subscription_id: macros.subscription_id,
            activity_level: details.activity_level,
            level: details.level,
            current_goal: details.current_goal,
            body_fat_percent: macros.body_fat_percent,
            lean_body_mass: macros.lean_body_mass,
            use_lean_gains: macros.use_lean_gains,
            protein_intake_override: macros.protein_intake_override,
            recommended_deficit: macros.recommended_deficit,
            calorie_intake_pre_health: macros.calorie_intake_pre_health,
            deficit_override: macros.deficit_override,
            base_protein_base_grams: macroBaseDay.protein_base_grams,
            base_protein_total_grams: macroBaseDay.protein_total_grams,
            base_protein_kcal_gram: macroBaseDay.protein_kcal_gram,
            base_protein_daily_kcals: macroBaseDay.protein_daily_kcals,
            base_protein_percent_calories: macroBaseDay.protein_percent_calories,
            base_carbs_base_grams: macroBaseDay.carbs_base_grams,
            base_carbs_total_grams: macroBaseDay.carbs_total_grams,
            base_carbs_kcal_gram: macroBaseDay.carbs_kcal_gram,
            base_carbs_daily_kcals: macroBaseDay.carbs_daily_kcals,
            base_carbs_percent_calories: macroBaseDay.carbs_percent_calories,
            base_fat_base_grams: macroBaseDay.fat_base_grams,
            base_fat_total_grams: macroBaseDay.fat_total_grams,
            base_fat_kcal_gram: macroBaseDay.fat_kcal_gram,
            base_fat_daily_kcals: macroBaseDay.fat_daily_kcals,
            base_fat_percent_calories: macroBaseDay.fat_percent_calories,
            base_total_daily_kcals: macroBaseDay.total_daily_kcals,
            base_total_percent_calories: macroBaseDay.total_percent_calories,
            training_protein_base_grams: macroTrainingDay.protein_base_grams,
            training_protein_pre_workout_grams: macroTrainingDay.protein_pre_workout_grams,
            training_protein_total_grams: macroTrainingDay.protein_total_grams,
            training_protein_kcal_gram: macroTrainingDay.protein_kcal_gram,
            training_protein_daily_kcals: macroTrainingDay.protein_daily_kcals,
            training_protein_percent_calories: macroTrainingDay.protein_percent_calories,
            training_carbs_base_grams: macroTrainingDay.carbs_base_grams,
            training_carbs_pre_workout_grams: macroTrainingDay.carbs_pre_workout_grams,
            training_carbs_total_grams: macroTrainingDay.carbs_total_grams,
            training_carbs_kcal_gram: macroTrainingDay.carbs_kcal_gram,
            training_carbs_daily_kcals: macroTrainingDay.carbs_daily_kcals,
            training_carbs_percent_calories: macroTrainingDay.carbs_percent_calories,
            training_fat_base_grams: macroTrainingDay.fat_base_grams,
            training_fat_pre_workout_grams: macroTrainingDay.fat_pre_workout_grams,
            training_fat_total_grams: macroTrainingDay.fat_total_grams,
            training_fat_kcal_gram: macroTrainingDay.fat_kcal_gram,
            training_fat_daily_kcals: macroTrainingDay.fat_daily_kcals,
            training_fat_percent_calories: macroTrainingDay.fat_percent_calories,
            training_total_daily_kcals: macroTrainingDay.total_daily_kcals,
            training_total_percent_calories: macroTrainingDay.total_percent_calories,
            alternate_protein_base_grams: macroAlternate.protein_base_grams,
            alternate_protein_pre_workout_grams: macroAlternate.protein_pre_workout_grams,
            alternate_protein_total_grams: macroAlternate.protein_total_grams,
            alternate_protein_kcal_gram: macroAlternate.protein_kcal_gram,
            alternate_protein_daily_kcals: macroAlternate.protein_daily_kcals,
            alternate_protein_percent_calories: macroAlternate.protein_percent_calories,
            alternate_carbs_base_grams: macroAlternate.carbs_base_grams,
            alternate_carbs_pre_workout_grams: macroAlternate.carbs_pre_workout_grams,
            alternate_carbs_total_grams: macroAlternate.carbs_total_grams,
            alternate_carbs_kcal_gram: macroAlternate.carbs_kcal_gram,
            alternate_carbs_daily_kcals: macroAlternate.carbs_daily_kcals,
            alternate_carbs_percent_calories: macroAlternate.carbs_percent_calories,
            alternate_fat_base_grams: macroAlternate.fat_base_grams,
            alternate_fat_pre_workout_grams: macroAlternate.fat_pre_workout_grams,
            alternate_fat_total_grams: macroAlternate.fat_total_grams,
            alternate_fat_kcal_gram: macroAlternate.fat_kcal_gram,
            alternate_fat_daily_kcals: macroAlternate.fat_daily_kcals,
            alternate_fat_percent_calories: macroAlternate.fat_percent_calories,
            alternate_total_daily_kcals: macroAlternate.total_daily_kcals,
            alternate_total_percent_calories: macroAlternate.total_percent_calories,
            is_copy,
            copy_from,
            macro_id: macros.id,
          },
        })
        .then(async (data) => {
          setStudentContactTag(currentTrainee, programId, ['MacrosInProcess']).catch((error) =>
            notification.warning({ title: 'InfusionSoft Warning!', message: error }),
          )
          if (await callUpdateLevelFunction) {
            await changeStudentLevel(currentTrainee, programId)

            apiServices('POST', `infusion-soft/update-coach-and-level`, { traineeId: currentTrainee, programId: programId })
              .then((response) => {
                return response.json()
              })
              .then((resp) => {
                if (resp.code !== 204) {
                  notification.warning({
                    message: "Sorry we couldn't sync your reassignment this time, we will try it again later.",
                  })
                }
              })
              .catch((error) => {
                console.log(error)
              })
              .finally(async () => {
                refetch()
              })
          }
          await notification.success({
            message: 'Changes saved correctly!',
            description: 'Your changes have been saved and updated correctly',
            style: {
              width: 600,
              marginLeft: 335 - 600,
            },
          })
        })
        .catch((error) => {
          Sentry.addBreadcrumb({
            message: 'Data sent with Alternate',
            data: {
              program_id: programId,
              trainee_id: currentTrainee,
              status: this.props.macroMacros.status,
            },
          })
          Sentry.captureMessage('Could not save the macros')
          notification.error({
            message: 'We can not update the macros.',
            description: `${error}`,
          })
        })
    } else {
      await apolloClient
        .mutate({
          mutation: updateMacros,
          variables: {
            status: this.props.macroMacros.status,
            programId: programId,
            currentTrainee: currentTrainee,
            subscription_id: macros.subscription_id,
            activity_level: details.activity_level,
            level: details.level,
            current_goal: details.current_goal,
            body_fat_percent: macros.body_fat_percent,
            lean_body_mass: macros.lean_body_mass,
            use_lean_gains: macros.use_lean_gains,
            protein_intake_override: macros.protein_intake_override,
            recommended_deficit: macros.recommended_deficit,
            calorie_intake_pre_health: macros.calorie_intake_pre_health,
            deficit_override: macros.deficit_override,
            base_protein_base_grams: macroBaseDay.protein_base_grams,
            base_protein_total_grams: macroBaseDay.protein_total_grams,
            base_protein_kcal_gram: macroBaseDay.protein_kcal_gram,
            base_protein_daily_kcals: macroBaseDay.protein_daily_kcals,
            base_protein_percent_calories: macroBaseDay.protein_percent_calories,
            base_carbs_base_grams: macroBaseDay.carbs_base_grams,
            base_carbs_total_grams: macroBaseDay.carbs_total_grams,
            base_carbs_kcal_gram: macroBaseDay.carbs_kcal_gram,
            base_carbs_daily_kcals: macroBaseDay.carbs_daily_kcals,
            base_carbs_percent_calories: macroBaseDay.carbs_percent_calories,
            base_fat_base_grams: macroBaseDay.fat_base_grams,
            base_fat_total_grams: macroBaseDay.fat_total_grams,
            base_fat_kcal_gram: macroBaseDay.fat_kcal_gram,
            base_fat_daily_kcals: macroBaseDay.fat_daily_kcals,
            base_fat_percent_calories: macroBaseDay.fat_percent_calories,
            base_total_daily_kcals: macroBaseDay.total_daily_kcals,
            base_total_percent_calories: macroBaseDay.total_percent_calories,
            training_protein_base_grams: macroTrainingDay.protein_base_grams,
            training_protein_pre_workout_grams: macroTrainingDay.protein_pre_workout_grams,
            training_protein_total_grams: macroTrainingDay.protein_total_grams,
            training_protein_kcal_gram: macroTrainingDay.protein_kcal_gram,
            training_protein_daily_kcals: macroTrainingDay.protein_daily_kcals,
            training_protein_percent_calories: macroTrainingDay.protein_percent_calories,
            training_carbs_base_grams: macroTrainingDay.carbs_base_grams,
            training_carbs_pre_workout_grams: macroTrainingDay.carbs_pre_workout_grams,
            training_carbs_total_grams: macroTrainingDay.carbs_total_grams,
            training_carbs_kcal_gram: macroTrainingDay.carbs_kcal_gram,
            training_carbs_daily_kcals: macroTrainingDay.carbs_daily_kcals,
            training_carbs_percent_calories: macroTrainingDay.carbs_percent_calories,
            training_fat_base_grams: macroTrainingDay.fat_base_grams,
            training_fat_pre_workout_grams: macroTrainingDay.fat_pre_workout_grams,
            training_fat_total_grams: macroTrainingDay.fat_total_grams,
            training_fat_kcal_gram: macroTrainingDay.fat_kcal_gram,
            training_fat_daily_kcals: macroTrainingDay.fat_daily_kcals,
            training_fat_percent_calories: macroTrainingDay.fat_percent_calories,
            training_total_daily_kcals: macroTrainingDay.total_daily_kcals,
            training_total_percent_calories: macroTrainingDay.total_percent_calories,
            is_copy,
            copy_from,
            macro_id: macros.id,
          },
        })
        .then(async (data) => {
          setStudentContactTag(currentTrainee, programId, ['MacrosInProcess']).catch((error) =>
            notification.warning({ title: 'InfusionSoft Warning!', message: error }),
          )
          if (await callUpdateLevelFunction) {
            await changeStudentLevel(currentTrainee, programId)
            apiServices('POST', `infusion-soft/update-coach-and-level`, { traineeId: currentTrainee, programId: programId })
              .then((response) => {
                return response.json()
              })
              .then((resp) => {
                if (resp.code !== 204) {
                  notification.warning({
                    message: "Sorry we couldn't sync your reassignment this time, we will try it again later.",
                  })
                }
              })
              .catch((error) => {
                console.log(error)
              })
              .finally(async () => {
                refetch()
              })
          }
          if (details.need_coach_approval === true) {
            this.needCoachAproval(details.id, false)
          }
          await notification.success({
            message: 'Changes saved correctly!',
            description: 'Your changes have been saved and updated correctly',
            style: {
              width: 600,
              marginLeft: 335 - 600,
            },
          })
        })
        .catch((error) => {
          Sentry.addBreadcrumb({
            message: 'Data sent without Alternate',
            data: {
              program_id: programId,
              trainee_id: currentTrainee,
              status: this.props.macroMacros.status,
            },
          })
          Sentry.captureMessage('Could not save the macros')
          notification.error({
            message: 'We can not update the macros.',
            description: `${error}`,
          })
        })
    }
    await refetch()
    this.setState({
      changesNoteText: '',
      changed: false,
    })
    if (!!newStatus) {
      this.setState({
        macrosStatus: newStatus,
      })
    }
  }

  handleNoteCreation = (note, coachID, programId, currentTrainee, change_details, refetch, checkSendNoteUser) => {
    let changeDetails = change_details ? change_details : []

    changeDetails.push({
      name: 'Check',
      Value: checkSendNoteUser,
    })

    const userID = localStorage.getItem('USER_ID')
    const lastMessageDate = moment().local()
    if (changeDetails !== null) {
      if (changeDetails.find((item) => item.name === 'need_coach_approval')) {
        note = '[Automatic] Coach accepted the enrollment changes asked by student.[/Automatic] ' + note
      }
    }
    apolloClient
      .mutate({
        mutation: appendNote,
        variables: {
          data: {
            trainee_id: currentTrainee,
            program_id: programId,
            coach_id: coachID,
            note: note,
            change_details: changeDetails,
            macro_id: this.state.macros.id,
          },
        },
      })
      .then((data) => {
        notification.success({ message: 'Your note was added successfully' })
        refetch()
      })
    this.setState({
      noteText: '',
      changesNoteText: '',
      visible: false,
    })
    if (checkSendNoteUser) {
      note = note.replace('[Automatic] Coach accepted the enrollment changes asked by student.[/Automatic] ', '')
      if (note === '') {
        note = 'Your macros were edited by your coach, review them in the Macros section ' + note
      }
      this.onSendMessage(note, thread_id, lastMessageDate, userID)
    }
  }

  handleShowChanges = async (note, coachID, programId, currentTrainee, refetch) => {
    let {
      details,
      prevDetails,
      prevMacros,
      macros,
      prevMacroBaseDay,
      macroBaseDay,
      prevMacroTrainingDay,
      macroTrainingDay,
      prevMacroAlternate,
      macroAlternate,
      prevMacrosSelected,
      macrosSelected,
    } = this.state

    let prevMacrosLength = Object.keys(prevMacros).length
    let prevMacroBaseDayLength = Object.keys(prevMacroBaseDay).length
    let prevMacroTrainingDayLength = Object.keys(prevMacroTrainingDay).length
    let prevMacroAlternateLength = Object.keys(prevMacroAlternate).length

    let change_details = []

    let sectionPersonal = []

    let sectionProteinIntake = []
    let sectionDietaryDeficit = []
    let sectionBaseMacros = []
    let sectionTrainingMacros = []
    let sectionAlternateMacros = []

    if (prevMacrosSelected !== macrosSelected) {
      change_details.push({
        section: 'Macros Selected',
        name: 'macros',
        oldValue: `${prevMacrosSelected} Bootcamp Macros`,
        newValue: `${macrosSelected} Bootcamp Macros`,
      })
    }

    Object.keys(prevDetails).forEach((item, index) => {
      if (prevDetails[item] !== details[item]) {
        change_details.push({
          section: 'Starting Goal, Level & Lifestyle',
          name: item,
          oldValue: prevDetails[item],
          newValue: `${details[item]}`,
        })
      }
    })

    Object.keys(prevMacros).forEach((item, index) => {
      if (index < prevMacrosLength - 1) {
        if (item !== 'status' && item !== 'coach_approver') {
          if (prevMacros[item] !== macros[item]) {
            let section = null
            switch (item) {
              case 'body_fat_percent':
                section = 'Personal Info'
                break
              case 'lean_body_mass':
                section = 'Protein Intake'
                break
              case 'calorie_intake_pre_health':
                section = 'Dietary Deficit'
                break
              case 'recommended_deficit':
                section = 'Dietary Deficit'
                break
              case 'use_lean_gains':
                section = 'Protein Intake'
                break
              case 'protein_intake_override':
                section = 'Protein Intake'
                break
              case 'deficit_override':
                section = 'Dietary Deficit'
                break
              default:
                break
            }
            if (section === 'Personal Info') {
              sectionPersonal.push({ section: section, name: item, oldValue: prevMacros[item], newValue: macros[item] })
            } else if (section === 'Protein Intake') {
              sectionProteinIntake.push({ section: section, name: item, oldValue: prevMacros[item], newValue: macros[item] })
            } else if (section === 'Dietary Deficit') {
              sectionDietaryDeficit.push({ section: section, name: item, oldValue: prevMacros[item], newValue: macros[item] })
            }
            change_details.push({ section: section, name: item, oldValue: prevMacros[item], newValue: macros[item] })
          }
        }
      }
    })

    Object.keys(prevMacroBaseDay).forEach((item, index) => {
      if (index < prevMacroBaseDayLength - 1) {
        if (item !== 'type' && item !== 'carbs_pre_workout_grams' && item !== 'fat_pre_workout_grams' && item !== 'protein_pre_workout_grams') {
          if (prevMacroBaseDay[item] !== macroBaseDay[item]) {
            sectionBaseMacros.push({ section: 'Base Macros', name: item, oldValue: prevMacroBaseDay[item], newValue: macroBaseDay[item] })
            change_details.push({ section: 'Base Macros', name: item, oldValue: prevMacroBaseDay[item], newValue: macroBaseDay[item] })
          }
        }
      }
    })

    Object.keys(prevMacroTrainingDay).forEach((item, index) => {
      if (index < prevMacroTrainingDayLength - 1) {
        if (item !== 'type') {
          if (prevMacroTrainingDay[item] !== macroTrainingDay[item]) {
            sectionTrainingMacros.push({
              section: 'Training Macros',
              name: item,
              oldValue: prevMacroTrainingDay[item],
              newValue: macroTrainingDay[item],
            })
            change_details.push({ section: 'Training Macros', name: item, oldValue: prevMacroTrainingDay[item], newValue: macroTrainingDay[item] })
          }
        }
      }
    })

    Object.keys(prevMacroAlternate).forEach((item, index) => {
      if (index < prevMacroAlternateLength - 1) {
        if (item !== 'type') {
          if (prevMacroAlternate[item] !== macroAlternate[item]) {
            sectionAlternateMacros.push({
              section: 'Alternate Macros',
              name: item,
              oldValue: prevMacroAlternate[item],
              newValue: macroAlternate[item],
            })
            change_details.push({ section: 'Alternate Macros', name: item, oldValue: prevMacroAlternate[item], newValue: macroAlternate[item] })
          }
        }
      }
    })

    if (prevMacroAlternate.protein_percent_calories === undefined && macroAlternate.protein_percent_calories !== undefined) {
      prevMacroAlternate.protein_percent_calories = 0
      sectionAlternateMacros.push({
        section: 'Alternate Macros',
        name: 'protein_percent_calories',
        oldValue: prevMacroAlternate.protein_percent_calories,
        newValue: macroAlternate.protein_percent_calories,
      })
      change_details.push({
        section: 'Alternate Macros',
        name: 'protein_percent_calories',
        oldValue: prevMacroAlternate.protein_percent_calories,
        newValue: macroAlternate.protein_percent_calories,
      })
    }
    if (prevMacroAlternate.fat_percent_calories === undefined && macroAlternate.fat_percent_calories !== undefined) {
      prevMacroAlternate.fat_percent_calories = 0
      sectionAlternateMacros.push({
        section: 'Alternate Macros',
        name: 'fat_percent_calories',
        oldValue: prevMacroAlternate.fat_percent_calories,
        newValue: macroAlternate.fat_percent_calories,
      })
      change_details.push({
        section: 'Alternate Macros',
        name: 'fat_percent_calories',
        oldValue: prevMacroAlternate.fat_percent_calories,
        newValue: macroAlternate.fat_percent_calories,
      })
    }
    if (prevMacroAlternate.carbs_percent_calories === undefined && macroAlternate.carbs_percent_calories !== undefined) {
      prevMacroAlternate.carbs_percent_calories = 0
      sectionAlternateMacros.push({
        section: 'Alternate Macros',
        name: 'carbs_percent_calories',
        oldValue: prevMacroAlternate.carbs_percent_calories,
        newValue: macroAlternate.carbs_percent_calories,
      })
      change_details.push({
        section: 'Alternate Macros',
        name: 'carbs_percent_calories',
        oldValue: prevMacroAlternate.carbs_percent_calories,
        newValue: macroAlternate.carbs_percent_calories,
      })
    }

    if (prevMacroAlternate.protein_total_grams === undefined && macroAlternate.protein_total_grams !== undefined) {
      prevMacroAlternate.protein_total_grams = 0
      sectionAlternateMacros.push({
        section: 'Alternate Macros',
        name: 'protein_total_grams',
        oldValue: prevMacroAlternate.protein_total_grams,
        newValue: macroAlternate.protein_total_grams,
      })
      change_details.push({
        section: 'Alternate Macros',
        name: 'protein_total_grams',
        oldValue: prevMacroAlternate.protein_total_grams,
        newValue: macroAlternate.protein_total_grams,
      })
    }
    if (prevMacroAlternate.carbs_total_grams === undefined && macroAlternate.carbs_total_grams !== undefined) {
      prevMacroAlternate.carbs_total_grams = 0
      sectionAlternateMacros.push({
        section: 'Alternate Macros',
        name: 'carbs_total_grams',
        oldValue: prevMacroAlternate.carbs_total_grams,
        newValue: macroAlternate.carbs_total_grams,
      })
      change_details.push({
        section: 'Alternate Macros',
        name: 'carbs_total_grams',
        oldValue: prevMacroAlternate.carbs_total_grams,
        newValue: macroAlternate.carbs_total_grams,
      })
    }
    if (prevMacroAlternate.fat_total_grams === undefined && macroAlternate.fat_total_grams !== undefined) {
      prevMacroAlternate.fat_total_grams = 0
      sectionAlternateMacros.push({
        section: 'Alternate Macros',
        name: 'fat_total_grams',
        oldValue: prevMacroAlternate.fat_total_grams,
        newValue: macroAlternate.fat_total_grams,
      })
      change_details.push({
        section: 'Alternate Macros',
        name: 'fat_total_grams',
        oldValue: prevMacroAlternate.fat_total_grams,
        newValue: macroAlternate.fat_total_grams,
      })
    }

    this.setState({
      changeDetails: change_details,
      callUpdateLevelFunction: !!change_details.find((c) => c.name === 'level'),
    })
  }

  setInitialState() {
    this.setState({
      details: this.props.macroDetails,
      prevDetails: this.props.macroDetails,
      macros: this.props.macroMacros,
      lastBootcampMacros: this.props.lastBootcampMacros,
      prevMacros: this.props.macroMacros,
      macroBaseDay: this.props.macroBaseDay,
      prevMacroBaseDay: this.props.macroBaseDay,
      macroTrainingDay: this.props.macroTrainingDay,
      prevMacroTrainingDay: this.props.macroTrainingDay,
      macroAlternate: this.props.macroAlternate,
      prevMacroAlternate: this.props.macroAlternate,
      programId: this.props.programId,
      currentTrainee: this.props.currentTrainee,
      coachID: this.props.coachID,
      macrosStatus: this.props.macroMacros ? this.props.macroMacros.status : '',
      coachIsMaster: localStorage.getItem('IS_MASTER'),
      pictures: this.props.pictures ? this.props.pictures[0] : [],
      is_last_bootcamp_copy: this.props.macroMacros.is_last_bootcamp_copy,
      is_copy_from: this.props.macroMacros.copy_from_bootcamp,
      macrosSelected: this.props.macroMacros.is_last_bootcamp_copy ? 'last' : 'current',
      prevMacrosSelected: this.props.macroMacros.is_last_bootcamp_copy ? 'last' : 'current',
    })
  }

  componentDidMount() {
    this.setInitialState()
  }

  getBodyFatMethodAbbreviation(bodyFatMethod, bodyFatMethodsCatalog) {
    let abbreviation = ''
    let bodyFatMethodItem = bodyFatMethodsCatalog.find((bfc) => bfc.id === bodyFatMethod)
    if (bodyFatMethodItem) {
      abbreviation = !!bodyFatMethodItem.short_name ? bodyFatMethodItem.short_name : bodyFatMethodItem.label
    } else {
      switch (bodyFatMethod) {
        case 'Yes, by using DEXA':
          abbreviation = 'DEXA'
          break
        case 'Yes, by using Navy Bodyfat':
          abbreviation = 'NAVY'
          break
        case 'Yes, by using Skulpt':
          abbreviation = 'SKULPT'
          break
        case 'Yes, by using Calipers':
          abbreviation = 'CALIPERS'
          break
        case 'Yes, by using Scale at gym or home':
          abbreviation = 'SCALE'
          break
        case 'Yes, by using the tape measurements method':
          abbreviation = 'TAPE'
          break
        case 'Yes, by using BodPod':
          abbreviation = 'BODPOD'
          break
        case 'Yes, by using another method':
        default:
          abbreviation = 'OTHER'
          break
      }
    }

    return abbreviation
  }

  getUserPictures(enrollment, pictures, enrollmentStartDate) {
    let userPictures = []

    if (!!enrollment.photo_front_url) {
      userPictures.push({
        url: enrollment.photo_front_url,
        zoomable: true,
        date: enrollmentStartDate,
      })
    } else if (pictures && !!pictures.front_url) {
      userPictures.push({
        url: pictures.front_url,
        zoomable: true,
        date: pictures.for_date,
      })
    } else userPictures.push(iconBodyFront)

    if (!!enrollment.photo_back_url) {
      userPictures.push({
        url: enrollment.photo_back_url,
        zoomable: true,
        date: enrollmentStartDate,
      })
    } else if (pictures && !!pictures.back_url) {
      userPictures.push({
        url: pictures.back_url,
        zoomable: true,
        date: pictures.for_date,
      })
    } else userPictures.push(iconBodyBack)

    if (!!enrollment.photo_side_url) {
      userPictures.push({
        url: enrollment.photo_side_url,
        zoomable: true,
        date: enrollmentStartDate,
      })
    } else if (pictures && !!pictures.side_url) {
      userPictures.push({
        url: pictures.side_url,
        zoomable: true,
        date: pictures.for_date,
      })
    } else userPictures.push(iconBodySide)

    return userPictures
  }

  getInfoCards(details, macros, bodyFatMethods, lbmFormat, macrosSelected) {
    return [
      {
        title: 'Personal Info',
        infos: [
          {
            upperText: 'Estimated Body Fat',
            bottomText: macros && macros.body_fat_percent ? `${(macros.body_fat_percent * 100).toFixed(1)}%` : 0,
          },
          {
            upperText: 'Method used',
            bottomText: details && details.bf_method ? `${this.getBodyFatMethodAbbreviation(details.bf_method, bodyFatMethods)}` : '',
          },
          {
            upperText: 'Supplied Body Fat',
            bottomText: details && details.bf_pct ? `${details.bf_pct.toFixed(1)}%` : 0,
          },
        ],
      },
      {
        title: 'Client Reported Macros',
        infos: [
          {
            upperText: 'Protein',
            bottomText: details && details.prot ? details.prot : 0,
          },
          {
            upperText: 'Carbohydrate',
            bottomText: details && details.carbs ? details.carbs : 0,
          },
          {
            upperText: 'Fat',
            bottomText: details && details.fat ? details.fat : 0,
          },
          {
            upperText: '<b>Total Kcal</b>',
            bottomText: (
              (details && details.prot ? details.prot : 0) * 4 +
              (details && details.carbs ? details.carbs : 0) * 4 +
              (details && details.fat ? details.fat : 0) * 9
            ).toFixed(2),
          },
        ],
      },
      {
        title: 'Protein Intake',
        infos: [
          {
            upperText: 'LBM in Pounds',
            bottomText: lbmFormat,
          },
          {
            upperText: 'Use LeanGains',
            dropdown: true,
            dropdownItem: {
              innerText: macros && macros.use_lean_gains ? 'Yes' : 'No',
              name: 'use_lean_gains',
            },
            macrosSelected: macrosSelected,
            options: ['Yes', 'No'],
          },
          {
            upperText: 'Protein Intake Select',
            dropdown: true,
            dropdownItem: {
              innerText: macros && macros.protein_intake_override ? macros.protein_intake_override : '',
              outterText: 'g/lb LBM',
              name: 'protein_intake_override',
            },
            macrosSelected: macrosSelected,
            options: ['', '0.7', '0.75', '0.8', '0.85', '0.9', '0.95', '1', '1.05', '1.1', '1.15', '1.2', '1.25', '1.3', '1.35', '1.4', '1.5'],
          },
        ],
      },
      {
        title: 'Dietary Deficit',
        infos: [
          {
            upperText: 'Recommended Deficit',
            bottomText: macros && macros.recommended_deficit ? `${(macros.recommended_deficit * 100).toFixed(1)}%` : 0,
          },
          {
            upperText: 'Recommended Cal/Day',
            bottomText: macros && macros.calorie_intake_pre_health ? macros.calorie_intake_pre_health.toFixed(2) : 0,
          },
          {
            upperText: 'Coach Override',
            dropdown: true,
            dropdownItem: {
              innerText: macros && macros.deficit_override !== null ? `${macros.deficit_override * 100}%` : '',
              name: 'deficit_override',
            },
            macrosSelected: macrosSelected,
            options: [
              '30%',
              '25%',
              '20%',
              '15%',
              '10%',
              '5%',
              '0%',
              '',
              '-0.5%',
              '-5%',
              '-10%',
              '-15%',
              '-20%',
              '-25%',
              '-30%',
              '-35%',
              '-40%',
              '-45%',
            ],
          },
        ],
      },
    ]
  }

  render() {
    const { programId, currentTrainee, coachID, selected } = this.props
    const currentProgramProps = this.props.currentProgram
    return (
      <DashboardContext.Consumer>
        {({ enrollment, bodyFatMethods, currentProgram }) => {
          const enrollmentEndDate = moment(currentProgramProps.start_date).add(-1, 'day')
          const enrollmentStartDate = moment(enrollmentEndDate).startOf('isoWeek')
          const endDate = currentProgramProps.end_date
          const accessDate = localStorage.getItem('BOOTCAMP_ACCESS_DATE')

          let ApprovedSendNote = false
          const today = moment().format('YYYY-MM-DD')

          if (today >= accessDate && today <= endDate) {
            ApprovedSendNote = true
          }

          thread_id = currentProgram.thread_id

          return (
            <Query query={getMacroNotes} fetchPolicy="cache-and-network" variables={{ currentTrainee, coachID, endDate }}>
              {({ loading, error, data, refetch }) => {
                if (error) {
                  console.error(error)
                  return null
                }

                let userPictures = []
                let lbmFormat = 0
                let notes = loading ? [] : data.macro_notes
                let {
                  details,
                  macros,
                  macroBaseDay,
                  macroTrainingDay,
                  macroAlternate,
                  noteText,
                  pictures,
                  modalChangesVisible,
                  changeDetails,
                  macrosSelected,
                  lastBootcampMacros,
                  macrosStatus,
                  visible,
                } = this.state

                userPictures = this.getUserPictures(enrollment, pictures, enrollmentStartDate)
                if (!!macros) {
                  if (!!macros.lean_body_mass) {
                    lbmFormat = details.metric === 'kg/cm' ? (macros.lean_body_mass * 2.2).toFixed(2) : macros.lean_body_mass.toFixed(2)
                  }
                }
                let arrayInfoCard = this.getInfoCards(details, macros, bodyFatMethods, lbmFormat, macrosSelected)

                return (
                  <Row gutter={[8, 8]}>
                    {details && details.need_coach_approval && <UpdateStudentData handleUpdate={this.handleUpdateStudentData} />}
                    <Col lg={14}>
                      <Row gutter={[8, 8]}>
                        <Col lg={24}>
                          <StartingGoalCard
                            isBootcamp={this.props.isBootcamp}
                            handleChange={this.handleDetailsDropdownChange}
                            current_goal={details && details.current_goal ? details.current_goal : 'Not defined'}
                            level={details && details.level ? details.level : 'Not defined'}
                            sublevel={details && details.sub_level ? details.sub_level : '?'}
                            activity_level={details && details.activity_level ? details.activity_level : 'Not defined'}
                            macrosSelected={macrosSelected}
                          />
                        </Col>
                        <Col md={12}>
                          <Row gutter={[8, 8]}>
                            <Col lg={24}>
                              <StartingPicturesCard {...{ userPictures }} />
                            </Col>
                            <Col lg={24}>
                              {arrayInfoCard.map((infoCard, i) => {
                                const { title, infos } = infoCard
                                return (
                                  <div style={{ marginBottom: '10px' }}>
                                    <Card key={i} className="info-card card" title={title}>
                                      <Row gutter={[8, 8]}>
                                        {infos.map((info, index) => (
                                          <Col sm={24 / infos.length} key={index}>
                                            <DataInfoCard
                                              key={index}
                                              macrosSelected={info.macrosSelected}
                                              handleChange={this.handleMacrosDropdownChange}
                                              upperText={info.upperText}
                                              bottomText={info.bottomText}
                                              dropdown={info.dropdown}
                                              dropdownItem={info.dropdownItem}
                                              options={info.options}
                                            />
                                          </Col>
                                        ))}
                                      </Row>
                                    </Card>
                                  </div>
                                )
                              })}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={12}>
                          <Row gutter={[8, 8]}>
                            <Col lg={24}>
                              <MedicalReport ketoCard={true} />
                            </Col>
                            <Col lg={24}>
                              <Card
                                title="Macros Notes and Changes"
                                className="notes-card card"
                                extra={
                                  <>
                                    {ApprovedSendNote === true && (
                                      <StyledButton custom="large" className="notes-card-button" onClick={this.showModal}>
                                        <span>Add Note</span>
                                      </StyledButton>
                                    )}
                                  </>
                                }
                              >
                                <Scroll>
                                  {notes && notes.length > 0 ? (
                                    notes.map((note, index) => (
                                      <NotesCard
                                        key={index}
                                        name={`${note.coach.user.first_name} ${note.coach.user.last_name}`}
                                        date={note.created}
                                        text={note.note}
                                        programDescription={note.program.description}
                                        changeDetails={note.change_details}
                                        picture={note.coach.user.picture}
                                      />
                                    ))
                                  ) : (
                                    <DataInfoCard
                                      upperText="There's no notes to show!"
                                      bottomText="You can create one by clicking on the 'Add Note' button above"
                                    />
                                  )}
                                </Scroll>
                                <ModalNoteCard
                                  note={noteText}
                                  visible={visible}
                                  refetch={refetch}
                                  coachID={coachID}
                                  programId={programId}
                                  showModal={this.showModal}
                                  check={this.state.sendNote}
                                  currentTrainee={currentTrainee}
                                  handleChange={this.handleChange}
                                  handleCancel={this.handleCancel}
                                  handleOk={this.handleNoteCreation}
                                  handleShowChanges={this.handleShowChanges}
                                  handleSendNote={this.handleSendNote}
                                />
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={10}>
                      {!!!macros && !!!macrosSelected && (
                        <div className="keto-card">
                          <header>
                            <h3>Student Macros</h3>
                          </header>
                          <main style={{ textAlign: 'left' }}>
                            <Row gutter={[8, 8]}>
                              <Result icon={<Spin />} title="Loading" />
                            </Row>
                          </main>
                        </div>
                      )}
                      {!!macros && !!macrosSelected && (
                        <div className="keto-card">
                          {this.props.isBootcamp && (
                            <header>
                              <h3>Student Macros</h3>
                              <div className="actions">
                                <StyledRadioGroupButton defaultValue={macrosSelected} onChange={this.handleMacrosSelectedChange}>
                                  <Radio.Button disabled={macrosStatus === 'Approved' && macrosSelected === 'last' ? true : false} value="current">
                                    Current Macros
                                  </Radio.Button>
                                  <Radio.Button
                                    disabled={!!!lastBootcampMacros || (macrosStatus === 'Approved' && macrosSelected === 'current') ? true : false}
                                    value="last"
                                  >
                                    Last Bootcamp Macros
                                  </Radio.Button>
                                </StyledRadioGroupButton>
                              </div>
                            </header>
                          )}
                          <main style={{ textAlign: 'left' }}>
                            <Row gutter={[8, 8]}>
                              <Col lg={24}>
                                <div className="macroTableMessage">
                                  <h5>{macrosSelected} Bootcamp Macros</h5>
                                  <p>
                                    {macrosSelected === 'last'
                                      ? `This macros were used by the student during his/her ${lastBootcampMacros.program.description}`
                                      : `Macros calculated using the student info entered during the enrollment process of the ${
                                          currentProgramProps.description
                                        }`}
                                  </p>
                                </div>
                              </Col>
                              <Col lg={24}>
                                <TableMacro
                                  className="rest-day-macros"
                                  title="Rest Day (Base) Macros"
                                  handleChange={this.handleBaseMacrosChange}
                                  coachIsMaster={this.state.coachIsMaster}
                                  macros={macroBaseDay}
                                />
                              </Col>
                              <Col lg={24}>
                                <TableMacro
                                  className="training-day-macros"
                                  title="Training Day Macros"
                                  handleChange={this.handleTrainingMacrosChange}
                                  coachIsMaster={this.state.coachIsMaster}
                                  macros={macroTrainingDay}
                                />
                              </Col>
                              <Col lg={24}>
                                <AlternateTableMacro
                                  active={!!macroAlternate.type ? true : false}
                                  title="Alternate Macros (Only to be used if instructed)"
                                  coachIsMaster={this.state.coachIsMaster}
                                  handleChange={this.handleAlternateMacrosChange}
                                  macros={macroAlternate}
                                  macroAlternate={macroAlternate}
                                />
                              </Col>
                            </Row>
                          </main>
                        </div>
                      )}

                      <Row gutter={[8, 8]}>
                        <Col lg={24}>
                          <MacroFooter
                            onSave={this.handleSaveChanges}
                            threadId={currentProgram.thread_id}
                            changed={this.state.changed}
                            changeDetails={changeDetails}
                            textNote={this.state.changesNoteText}
                            autoModalVisible={modalChangesVisible}
                            handleCancel={this.handleChangesCancel}
                            handleDiscardChanges={this.handleDiscardChanges}
                            handleChange={this.handleChange}
                            handleShowChanges={this.handleShowChanges}
                            handleNoteCreation={this.handleNoteCreation}
                            onSendMessage={this.onSendMessage}
                            ApprovedSaveMacros={ApprovedSendNote}
                            macrosStatus={this.state.macrosStatus}
                            coachIsMaster={this.state.coachIsMaster}
                            handleMacrosStatus={this.handleMacrosStatus}
                            assignedCoachId={selected && selected[0].coach && selected[0].coach.id ? selected[0].coach.id : ''}
                            refetch={this.props.refetch}
                            {...{
                              coachID,
                              programId,
                              currentTrainee,
                              macrosSelected,
                              lastBootcampMacros,
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              }}
            </Query>
          )
        }}
      </DashboardContext.Consumer>
    )
  }
}
export default MacrosBody
