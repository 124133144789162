import React, { Component } from 'react'
import { Row, Col, Tag, Empty } from 'antd'
import { MedicalReportContainer } from './MedicalReport.styled'
import DashboardContext from 'context/DashboardContext'

const MedicalTag = (props) => {
  const { data } = props
  if (!!!data || data.length <= 0) return <Tag>None</Tag>

  return (
    <div>
      {data.map((medicalCondition, index) => {
        return (
          <Tag key={index} className={medicalCondition.need_macro_approve ? 'danger' : 'caution'}>
            {medicalCondition.label}
          </Tag>
        )
      })}
    </div>
  )
}

class MedicalReport extends Component {
  getMedicalConditions = (enrollment, type, catalog) => {
    let medicalConditions = []
    let medicalConditionsMapped = []
    if (enrollment.medical_conditions && enrollment.medical_conditions.length > 0) {
      medicalConditionsMapped = enrollment.medical_conditions.map((mc) => {
        const tag = catalog.find((c) => c.id === mc.medical_condition_id)
        if (tag) return tag
        return { id: mc.medical_condition_id, label: mc.medical_condition_id, category: '' }
      })
    }

    switch (type) {
      case 'reproductive':
        if (enrollment.reprod_health && enrollment.reprod_health.length > 0) {
          medicalConditions = enrollment.reprod_health.map((rh) => {
            return { id: rh, label: rh }
          })
        } else if (medicalConditionsMapped && medicalConditionsMapped.length > 0) {
          medicalConditions = medicalConditionsMapped.filter((mcm) => mcm.category === 'Reproductive Health')
        }
        break
      case 'disorder_eating':
        if (enrollment.desord_eating && enrollment.desord_eating.length > 0) {
          medicalConditions = enrollment.desord_eating.map((rh) => {
            return { id: rh, label: rh }
          })
        } else if (medicalConditionsMapped && medicalConditionsMapped.length > 0) {
          medicalConditions = medicalConditionsMapped.filter((mcm) => mcm.category === 'Eating Disorders')
        }
        break
      default:
      case 'general':
        if (enrollment.med_cond && enrollment.med_cond.length > 0) {
          medicalConditions = enrollment.med_cond.map((rh) => {
            return { id: rh, label: rh }
          })
        } else if (medicalConditionsMapped && medicalConditionsMapped.length > 0) {
          medicalConditions = medicalConditionsMapped.filter((mcm) => mcm.category === 'General')
        }
        break
    }
    return medicalConditions
  }

  render() {
    const { enrollment, medicalConditions, ketoCard, ...rest } = this.props
    return (
      <DashboardContext.Consumer>
        {({ enrollment, medicalConditions, typeOfDiets }) => {
          let typeOfDiet = 'Not declared'
          if (enrollment.diet) typeOfDiet = enrollment.diet
          else if (enrollment.type_of_diet > 0) {
            let typeOfDietItem = typeOfDiets.find((tod) => tod.id === enrollment.type_of_diet)
            if (typeOfDietItem) typeOfDiet = typeOfDietItem.label
          }
          return (
            <div className={!!ketoCard ? 'keto-card' : ''} {...rest}>
              {!!ketoCard && (
                <header>
                  <h3>Medical Report</h3>
                </header>
              )}
              <MedicalReportContainer>
                {!!!enrollment && <Empty />}
                {!!enrollment && (
                  <>
                    <p className="label">Reproductive Health</p>
                    <MedicalTag data={this.getMedicalConditions(enrollment, 'reproductive', medicalConditions)} />
                    <hr />
                    <p className="label">Medical Conditions</p>
                    <MedicalTag data={this.getMedicalConditions(enrollment, 'general', medicalConditions)} />
                    <hr />
                    <p className="label">Disorder Eating</p>
                    <MedicalTag data={this.getMedicalConditions(enrollment, 'disorder_eating', medicalConditions)} />
                    <hr />
                    <p className="label">Prescription Medications</p>
                    <p>{enrollment.prescription_med ? enrollment.prescription_med : 'Not declared'}</p>
                    <hr />
                    <Row gutter={[2, 2]}>
                      <Col md={12}>
                        <p className="label">Supplements</p>
                        <p>{enrollment.diet_supliments ? enrollment.diet_supliments : 'Not declared'}</p>
                      </Col>
                      <Col md={12}>
                        <p className="label">Current Diet</p>
                        <p>{typeOfDiet}</p>
                      </Col>
                    </Row>
                  </>
                )}
              </MedicalReportContainer>
            </div>
          )
        }}
      </DashboardContext.Consumer>
    )
  }
}

export default MedicalReport
