import React from 'react'
import iconLevel1 from 'images/icon_level_1.png'
import iconLevel2 from 'images/icon_level_2.png'
import iconLevel3 from 'images/icon_level_3.png'
import iconLoseBodyFat from 'images/icon_lose_body_fat.png'
import iconGainMuscle from 'images/icon_gain_muscle.png'
import iconBodyRecomposition from 'images/icon_body_recomposition.png'
import DashboardContext from 'context/DashboardContext'
import iconSedentaryActivity from 'images/icon_sedentary.png'
import iconLigthlyActivity from 'images/icon_ligthly.png'
import iconModerateActivity from 'images/icon_moderately.png'
import iconVigorousActivity from 'images/icon_vigorously.png'
import { Card, Row, Col, Popconfirm, Avatar, Dropdown, Button, Icon, Menu, Radio } from 'antd'
const { Meta } = Card

const DropDownCard = ({ className, upperText, bottomText, menu, macrosSelected, activityLevels }) => {
  let img

  switch (bottomText) {
    case 'GAIN_MUSCLE':
      img = iconGainMuscle
      break
    case 'BODY_RECOMPOSITION':
      img = iconBodyRecomposition
      break
    case 1:
      img = iconSedentaryActivity
      bottomText = activityLevels.find((a) => a.id === parseInt(bottomText)).label
      break
    case 2:
      img = iconLigthlyActivity
      bottomText = activityLevels.find((a) => a.id === parseInt(bottomText)).label
      break
    case 3:
      img = iconModerateActivity
      bottomText = activityLevels.find((a) => a.id === parseInt(bottomText)).label
      break
    case 4:
      img = iconVigorousActivity
      bottomText = activityLevels.find((a) => a.id === parseInt(bottomText)).label
      break
    default:
      bottomText = 'Lose Body Fat'
      img = iconLoseBodyFat
  }

  return (
    <Card className={className ? `${className} card` : 'card dropdown-card'} bordered={false}>
      <Meta avatar={<Avatar src={img} />} />
      <Dropdown disabled={macrosSelected === 'last' ? true : false} overlay={menu} trigger={['click']}>
        <Button className="dropdown-button">
          <div className="dropdown-text-container">
            <div className="dropdown-upperText">{upperText}</div>
            <div className="dropdown-bottomText">{bottomText} </div>
          </div>
          <Icon type="down" />
        </Button>
      </Dropdown>
    </Card>
  )
}

export const StartingGoalCard = ({ current_goal, level, sublevel, activity_level, handleChange, macrosSelected, isBootcamp }) => {
  let iconLevel
  let infoLevel
  switch (level) {
    case 1:
    default:
      iconLevel = iconLevel1
      infoLevel = 'A starting point for many, and a launching point for all!'
      break
    case 2:
      iconLevel = iconLevel2
      infoLevel = 'You have mastered the basics, now let`s learn new things!'
      break
    case 3:
      iconLevel = iconLevel3
      infoLevel = 'You are ready to test your mind and body at the next level!'
      break
  }
  const goalMenu = (
    <DashboardContext.Consumer>
      {({ goals }) => {
        return (
          <Menu
            onClick={(item) => {
              handleChange('current_goal', item.item.props.value)
            }}
          >
            {goals.map((g) => {
              return (
                <Menu.Item key={g.id} value={g.id}>
                  {g.label}
                </Menu.Item>
              )
            })}
          </Menu>
        )
      }}
    </DashboardContext.Consumer>
  )

  const levelOnConfirm = (
    <div>
      <p>Are you sure you want to re-assign level?</p>
      <p>
        <small>By doing this action, this probably removes you as the student's coach.</small>
      </p>
      <Radio.Group size="small" buttonStyle="solid" defaultValue={level} onChange={(e) => localStorage.setItem('newLevel', e.target.value)}>
        <Radio.Button value={1}>Level 1</Radio.Button>
        <Radio.Button value={2}>Level 2</Radio.Button>
        <Radio.Button value={3}>Level 3</Radio.Button>
        <Radio.Button value={4}>Level 4</Radio.Button>
      </Radio.Group>
    </div>
  )

  const activityMenu = (
    <DashboardContext.Consumer>
      {({ activityLevels }) => {
        return (
          <Menu
            onClick={(item) => {
              handleChange('activity_level', item.item.props.value)
            }}
          >
            {activityLevels.map((item) => {
              return (
                <Menu.Item key={item.id} value={item.id}>
                  {item.label}
                </Menu.Item>
              )
            })}
          </Menu>
        )
      }}
    </DashboardContext.Consumer>
  )

  const setNewLevel = () => {
    if (!isNaN(localStorage.getItem('newLevel'))) handleChange('level', parseInt(localStorage.getItem('newLevel')))
    localStorage.removeItem('level')
  }

  return (
    <Card className="card starting-card" title="Starting Goal, Level & Lifestyle" bordered={false}>
      <Row gutter={[8, 8]}>
        <Col sm={8}>
          <DropDownCard name="initialGoal" upperText="Initial Goal" bottomText={current_goal} menu={goalMenu} macrosSelected={macrosSelected} />
        </Col>
        {isBootcamp && (
          <Col sm={8}>
            <Card className="upsideItems card" bordered={false}>
              <Meta avatar={<Avatar src={iconLevel} />} />
              <Popconfirm
                disabled={macrosSelected === 'last' ? true : false}
                title={levelOnConfirm}
                onConfirm={() => setNewLevel()}
                onCancel={() => localStorage.removeItem('newLevel')}
              >
                <Button disabled={macrosSelected === 'last' ? true : false} className="dropdown-button">
                  <div className="dropdown-text-container">
                    <div className="dropdown-upperText">{`Level ${level}-${sublevel}`}</div>
                    <div className="dropdown-bottomText">{infoLevel}</div>
                  </div>
                </Button>
              </Popconfirm>
            </Card>
          </Col>
        )}
        <Col sm={8}>
          <DashboardContext.Consumer>
            {({ activityLevels }) => {
              return (
                <DropDownCard
                  name="activityLevel"
                  upperText="Activity"
                  bottomText={activity_level}
                  menu={activityMenu}
                  macrosSelected={macrosSelected}
                  activityLevels={activityLevels}
                />
              )
            }}
          </DashboardContext.Consumer>
        </Col>
      </Row>
    </Card>
  )
}
