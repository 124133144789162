import React, { Component } from 'react'
import UserDashboard from 'components/UserDashboard'
import backgroundImage from 'images/bg-coach-home.png'
import UserListQuery from 'components/UserList'
import KetogainsLayout from '../Layout'
import { Layout } from 'antd'
import BootcampBar from '../Homepage/BootcampBar'
import GlobalContext from 'context/GlobalContext'
import StudentMeeting from './StudentMeeting'
import StudentMessage from './StudentMessage'

const { Content, Sider } = Layout

class Homepage extends Component {
  state = {
    asCoach: false,
    coachID: this.props.coachID,
    reload: null,
  }

  shouldComponentUpdate(nextProps) {
    const { traineeID } = this.props
    if (!!nextProps.traineeID && nextProps.traineeID === traineeID) {
      return false
    }
    return true
  }

  render() {
    let { traineeID } = this.props
    const { coachID } = this.state
    return (
      <GlobalContext.Consumer>
        {({ showMeeting, displayMeeting, showMessage, displayMessage }) => {
          return (
            <KetogainsLayout>
              <BootcampBar coachID={coachID} />
              <Layout>
                <Sider className="student-list-container" breakpoint="xl" collapsedWidth="0" theme="light">
                  <UserListQuery
                    ref={(instance) => {
                      this.userList = instance
                    }}
                    {...{
                      currentTrainee: traineeID,
                      coachID,
                      getRefetch: this.getRefetch,
                    }}
                  />
                </Sider>
                <Content className="main-content">
                  {!!traineeID && (
                    <UserDashboard
                      ref={(instance) => {
                        this.userDashboard = instance
                      }}
                      {...{
                        traineeID,
                        coachID,
                        userListRefetch: () => {
                          this.userList.refreshList()
                        },
                      }}
                    />
                  )}
                  {!!!traineeID && (
                    <div className="empty-dashboard" style={{ backgroundImage: `url(${backgroundImage})` }}>
                      <h2>
                        Motivation fails, <span>discipline does not</span>
                      </h2>
                    </div>
                  )}
                  {showMeeting && <StudentMeeting {...{ displayMeeting, showMeeting }} />}
                  {showMessage && <StudentMessage {...{ displayMessage, showMessage }} />}
                </Content>
              </Layout>
            </KetogainsLayout>
          )
        }}
      </GlobalContext.Consumer>
    )
  }
}

export default Homepage
