import { message } from 'antd'
import { navigate } from '@reach/router'

export const validateData = (...arg) => {
  const valid = arg.every((value) => !!value)
  if (!valid) {
    message.error(`You don't have some data`)
    navigate('/null')
  }
  return valid
}
