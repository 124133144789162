import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import { ApolloProvider } from 'react-apollo'
import apolloClient from './apolloClient.js'
import ErrorBoundary from 'components/ErrorBoundary'
import Routes from './routes.js'
import 'antd/dist/antd.css'

const sentryVersion = `${process.env.REACT_APP_VERSION}`.substr(0, 3)

Sentry.init({
  dsn: 'https://398e0444bf0d49aeaccd7821fec1e501@sentry.io/1420946',
  environment: process.env.REACT_APP_BRANCH || 'production',
  release: 'coach@' + sentryVersion,
  beforeSend(event, hint) {
    if (process.env.REACT_APP_BRANCH === 'local') {
      return null
    }

    const { message } = !!hint && !!hint.originalException ? hint.originalException : { message: 'Exception not received' }

    if (message && (message.match('{"error":"login_required","error_description":"Login required"}') || message.match('login_required'))) {
      return null
    }

    if (message && message.match(/Network error: Response not successful: Received status code 400/i)) {
      event.fingerprint = ['hasura token expired']
    } else if (message && message.match(/Error on Apollo Operation/i)) {
      event.fingerprint = ['Apollo Error']
    } else if (event.exception) {
      // Check if it is an exception, if so, show the report dialog
      Sentry.showReportDialog()
    }

    event.user = {
      id: localStorage.getItem('USER_ID'),
      username: localStorage.getItem('USER_NICKNAME'),
    }

    return event
  },
  ignoreErrors: ['top.GLOBALS', 'ResizeObserver loop limit exceeded'],
})

const Root = () => (
  <ErrorBoundary>
    <ApolloProvider client={apolloClient}>
      <Routes />
    </ApolloProvider>
  </ErrorBoundary>
)

ReactDOM.render(<Root />, document.getElementById('root'))

serviceWorker.unregister()
