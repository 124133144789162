import React, { Component } from 'react'
import ketogainsTm from 'images/ketogains-tm.svg'
import _ from 'lodash'
import { Query } from 'react-apollo'
import { getAdminData } from 'queries/user'
import { Root, StyledAvatar } from './Header.styled'
import { AdminInfo, AdminTitle } from './AdminIndicator.styled'

class AdminIndicator extends Component {
  render() {
    return (
      <Query query={getAdminData} fetchPolicy="cache-only">
        {({ data }) => {
          const user = _.get(data, 'User') || {}
          const admin = _.get(data, 'Admin') || {}
          const { nickname, picture } = user
          const { nickname: nicknameAdmin, picture: pictureAdmin } = admin
          return (
            <Root custom="admin">
              <img src={ketogainsTm} alt="Ketogains Coach" />
              <AdminInfo>
                <AdminTitle>
                  You are in <span>{nickname}</span> portal
                </AdminTitle>
                <StyledAvatar
                  {...{
                    src: picture,
                    alt: nickname,
                    size: 36,
                    custom: 'coach',
                  }}
                />
                <StyledAvatar
                  {...{
                    src: pictureAdmin,
                    alt: nicknameAdmin,
                    size: 48,
                    custom: 'admin',
                  }}
                />
              </AdminInfo>
            </Root>
          )
        }}
      </Query>
    )
  }
}

export default AdminIndicator
