import React, { Component } from 'react'
import { MASK_STYLE } from 'constants.js'
import { ReactComponent as CheckmarkIcon } from 'images/icon-checkmark-circle.svg'
import { StyledModal, ModalContent, StyledButton } from './CompletedModal.styled'

class CompletedModal extends Component {
  render() {
    const { handleClose, visible } = this.props

    return (
      <StyledModal
        {...{
          maskStyle: MASK_STYLE,
          centered: true,
          title: '',
          wrapClassName: 'notification-modal',
          width: '421px',
          closable: false,
          visible: visible,
          onCancel: handleClose,
          footer: <StyledButton onClick={handleClose}>OK</StyledButton>,
        }}
      >
        <ModalContent>
          <CheckmarkIcon />
          <span>Client week</span>
          Completed
        </ModalContent>
      </StyledModal>
    )
  }
}

export default CompletedModal
